import { LayoutDto } from 'core/dtos';

export type UpdateNodeResponse = {
  isSuccessful: boolean;
  payload: LayoutDto[] | null;
  error: UpdateNodeError | null;
};

export interface UpdateNodeError {
  type: UpdateNodeErrorType;
  tourConfigurationNames: string[];
}

export enum UpdateNodeErrorType {
  None = 0,
  RetrackingPointMandatory = 10,
}
