import { VehicleDto } from 'core/dtos';
import {
  DefaultHardwareVersion,
  HardwareVersion,
  HardwareVersionVehicleType,
  VehicleType,
} from 'core/models';
import { Forklift4Am15tMapItem } from './items/forklift-4am15t.graphic';
import { Forklift4Am20tMapItem } from './items/forklift-4am20t.graphic';
import { ForkliftAgiloxOCFMapItem } from './items/forklift-agilox-ocf.graphic';
import { ForkliftAgiloxOneMapItem } from './items/forklift-agilox-one.graphic';
import { ForkliftManualMapItem } from './items/forklift-manual.graphic';
import { TuggerTrain4AmMapItem } from './items/tuggertrain-4am.graphic';
import { TuggerTrainDSMapItem } from './items/tuggertrain-ds.graphic';
import { TuggerTrainManualMapItem } from './items/tuggertrain-manual.graphic';
import { UnderrideDSMapItem } from './items/underride-ds-uagv.graphic';
import { GenericMapItem } from './items/underride-generic.graphic';
import { STRMapItem } from './items/underride-str.graphic';
import { ForkliftMapItem } from './vehicle-types/forklift.graphic';
import { TuggerTrainMapItem } from './vehicle-types/tuggertrain.graphic';
import { UnderrideMapItem } from './vehicle-types/underride.graphic';

export type VehicleMapItemType = UnderrideMapItem | TuggerTrainMapItem | ForkliftMapItem;

export type VehicleMapItemTypes =
  | typeof GenericMapItem
  | typeof STRMapItem
  | typeof UnderrideDSMapItem
  | typeof TuggerTrain4AmMapItem
  | typeof TuggerTrainDSMapItem
  | typeof TuggerTrainManualMapItem
  | typeof Forklift4Am15tMapItem
  | typeof Forklift4Am20tMapItem
  | typeof ForkliftManualMapItem
  | typeof ForkliftAgiloxOneMapItem
  | typeof ForkliftAgiloxOCFMapItem;

export const VehicleMapItems: Record<HardwareVersion, VehicleMapItemTypes> = {
  [HardwareVersion.Unknown]: GenericMapItem,
  [HardwareVersion.StrVersion2]: STRMapItem,
  [HardwareVersion.StrVersion3]: STRMapItem,
  [HardwareVersion.StrVersion4]: STRMapItem,
  [HardwareVersion.DSType1]: UnderrideDSMapItem,
  [HardwareVersion.DSType2]: UnderrideDSMapItem,
  [HardwareVersion.DSType3]: UnderrideDSMapItem,
  [HardwareVersion.DSType4]: UnderrideDSMapItem,
  [HardwareVersion.DSType5]: UnderrideDSMapItem,
  [HardwareVersion.TuggerTrainFourAmV2]: TuggerTrain4AmMapItem,
  [HardwareVersion.TuggerTrainDsV1]: TuggerTrainDSMapItem,
  [HardwareVersion.TuggerTrainManual]: TuggerTrainManualMapItem,
  [HardwareVersion.ForkliftFourAm_15t]: Forklift4Am15tMapItem,
  [HardwareVersion.ForkliftFourAm_20t]: Forklift4Am20tMapItem,
  [HardwareVersion.AgiloxOCF]: ForkliftAgiloxOCFMapItem,
  [HardwareVersion.AgiloxONE]: ForkliftAgiloxOneMapItem,
  [HardwareVersion.DsAmadeus]: GenericMapItem,
  [HardwareVersion.ForkliftManual]: ForkliftManualMapItem,
};

export class MapVehicleMapItemHelper {
  static getDefaultItemHardwareVersion(vehicle: VehicleDto): HardwareVersion {
    if (
      vehicle.hardwareVersion !== HardwareVersion.Unknown &&
      HardwareVersionVehicleType[vehicle.hardwareVersion] !== vehicle.vehicleType
    ) {
      console.warn(`Vehicle ${vehicle.name} has incorrectly configured Hardware Version`);
      return DefaultHardwareVersion[vehicle.vehicleType];
    }

    if (vehicle.hardwareVersion === HardwareVersion.Unknown) {
      console.warn(`Vehicle ${vehicle.name} has unknown Hardware Version`);
    }

    return vehicle.hardwareVersion;
  }

  static getDefaultItemVehicleType(version: HardwareVersion): VehicleType {
    if (version === HardwareVersion.Unknown) return VehicleType.UnitLoad;

    return HardwareVersionVehicleType[version];
  }

  static getDefaultItemType(vehicle: VehicleDto): VehicleMapItemTypes {
    const version = MapVehicleMapItemHelper.getDefaultItemHardwareVersion(vehicle);

    return MapVehicleMapItemHelper.getVehicleMapItemType(version);
  }

  static getVehicleMapItemType(version: HardwareVersion | undefined): VehicleMapItemTypes {
    if (!version) return GenericMapItem;

    return VehicleMapItems[version];
  }
}
