import { ReducedVehicle } from 'core/models';
import {
  BatteryLevelStatus,
  HardwareVersion,
  LoadType,
  SoftwareUpdateStatus,
  VehicleAvailability,
  VehicleMode,
  VehicleStatus,
  VehicleType,
} from '../vehicles/vehicle.enum';

export const initReducedVehicle: ReducedVehicle = {
  id: '',
  internalIdentifier: '',
  name: '',
  vehicleType: VehicleType.UnitLoad,
  mode: VehicleMode.Autonomous,
  availability: VehicleAvailability.Available,
  status: VehicleStatus.Idle,
  maintenanceModeEnabled: false,
  isErrorForwardingEnabled: true,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  isRetired: false,
  isConnected: false,
  isSwitchedOff: false,
  isActiveZoneSet: false,
  brakeTestRequired: false,
  batteryLevel: 0,
  batteryLevelStatus: BatteryLevelStatus.Red,
  softwareDetails: {
    softwareVersion: '',
    iotHubSdkVersion: '4.5',
    atsInterfaceVersion: '1.1',
  },
  softwareVersionChangedDateUtc: undefined,
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  initializationDateTime: '',
  lastStateMessageProcessedUtc: '',
  ipAddress: '',
  pose2D: { x: 0, y: 0, orientation: 0 },
  fleetId: '',
  workingAreaId: '',
  vehicleKey: '',
  zoneSetId: '',
  desiredZoneSetId: '',
  trailers: null,
  trailerCount: 0,
  hardwareVersion: HardwareVersion.Unknown,
  supportedLoadTypes: [],
  loadType: LoadType.Unknown,
  loadOrientation: 0,
};
