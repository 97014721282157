/* eslint-disable max-lines */
import { Dictionary } from '@ngrx/entity';
import { MapMockData } from 'core/api-services/mocks';
import {
  PoiBookingStatusChangeDto,
  PoiDeviceOccupancyStatusChangeDto,
  PoiDto,
  PoiGroupDto,
  PoiGroupSingleDto,
  PoiOccupancyStatusChangeDto,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import {
  LoadType,
  OccupancyStatus,
  Poi,
  PoiDeviceOccupancy,
  PoiGroup,
  PoiGroupStrategy,
  PoiType,
} from 'core/models';
import { arrayToDictionary } from 'shared/helpers/entities.helper';

const poiGroup1Id = 'poi-group-id-1';
const poiGroup2Id = 'poi-group-id-2';
const mapId2 = '03840cd1-a25a-4c33-a7ba-1fc05e3f0212';

const map = MapMockData.getMap();

const poi1 = {
  id: 'poi-id-1',
  poiGroupId: poiGroup1Id,
  map,
  mapId: map.id,
  name: 'Poi_1',
  type: PoiType.WayPoint,
  mainPose: {
    x: 35.42,
    y: 58.42,
    orientation: 0,
  },
  entryVectorDistance: 1,
  exitVectorDistance: 1,
  occupancy: { occupancyStatus: OccupancyStatus.Free },
  isCloseToWall: false,
  poseAccuracy: 2,
  occupancyDevice: null,
  deviceOccupancy: 1,
  booked: false,
  loadType: LoadType.Unknown,
};

const poiDto: PoiDto = {
  id: 'poi-id-1',
  poiGroupId: poiGroup1Id,
  mapId: map.id,
  name: 'Poi_1',
  type: PoiType.WayPoint,
  mainPose: {
    x: 35.42,
    y: 58.42,
    orientation: 0,
  },
  entryVectorDistance: 1,
  exitVectorDistance: 1,
  occupancy: { occupancyStatus: OccupancyStatus.Free },
  isCloseToWall: false,
  poseAccuracy: 2,
  occupancyDevice: null,
  deviceOccupancy: PoiDeviceOccupancy.Unknown,
  booked: false,
  loadType: LoadType.Unknown,
};

const poi2 = { ...poi1, name: 'Poi_2', id: 'poi-id-2' };
const poi3 = {
  ...poi1,
  name: 'Poi_3',
  id: 'poi-id-3',
  poiGroupId: poiGroup2Id,
  type: PoiType.DollyPlace,
};
const poi4 = {
  ...poi1,
  name: 'Poi_4',
  id: 'poi-id-4',
  poiGroupId: poiGroup2Id,
  type: PoiType.ChargingStation,
};
const poi5 = { ...poi1, name: 'Poi_5', id: 'poi-id-5', poiGroupId: undefined };
const poi6 = { ...poi1, name: 'Poi_6', id: 'poi-id-6', poiGroupId: undefined };
const poi7 = {
  ...poi1,
  name: 'Poi_7',
  id: 'poi-id-7',
  poiGroupId: undefined,
  type: PoiType.TurnTableLarge,
};
const poi8 = {
  ...poi1,
  name: 'Poi_8',
  id: 'poi-id-8',
  poiGroupId: undefined,
  type: PoiType.ParkingSpace,
};
const poi9 = {
  ...poi1,
  id: 'poi-id-9',
  poiGroupId: undefined,
  type: PoiType.AutomaticConveyorLoading,
};
const poi10 = {
  ...poi1,
  id: 'poi-id-10',
  name: 'Poi_10',
  poiGroupId: undefined,
  mapId: mapId2,
};
const poi11 = {
  ...poi1,
  id: 'poi-id-11',
  name: 'Poi_11',
  poiGroupId: undefined,
  type: PoiType.DollyPlace,
  mapId: mapId2,
};
const poi12 = {
  ...poi1,
  id: 'poi-id-12',
  name: 'Poi_12',
  poiGroupId: undefined,
  type: PoiType.TurnTableSmall,
};
const poi13 = {
  ...poi1,
  id: 'poi-id-13',
  name: 'Poi_13',
  poiGroupId: undefined,
  type: PoiType.Handover,
};
const poi14 = {
  ...poi1,
  id: 'poi-id-14',
  name: 'Poi_14',
  poiGroupId: undefined,
  type: PoiType.ParkingSpaceLarge,
};
const poi15 = {
  ...poi1,
  id: 'poi-id-15',
  name: 'Poi_15',
  poiGroupId: undefined,
  type: PoiType.AssignableWaypoint,
};
const poi16 = {
  ...poi1,
  id: 'poi-id-16',
  name: 'Poi_16',
  poiGroupId: undefined,
  type: PoiType.WayPoint,
};
const poi17 = {
  ...poi1,
  id: 'poi-id-17',
  name: 'Poi_17',
  poiGroupId: undefined,
  type: PoiType.TripleTurnTable,
};
const poi18 = {
  ...poi1,
  name: 'Poi_18',
  id: 'poi-id-18',
  poiGroupId: 'poiMonitoringGroup1',
  type: PoiType.DollyPlace,
};
const poiGroup: PoiGroup = {
  id: poiGroup1Id,
  name: 'poi-group-name',
  map,
  mapId: map.id,
  strategy: PoiGroupStrategy.Alternating,
  pointsOfInterest: [poi1, poi2],
  nextPointOfInterestIdToUse: poi1.id,
};

const poiGroup2: PoiGroup = {
  ...poiGroup,
  id: poiGroup2Id,
  pointsOfInterest: [poi3, poi4],
  nextPointOfInterestIdToUse: poi3.id,
};

const poiGroupDto: PoiGroupDto = {
  id: 'poiGroup1',
  name: 'Poi Group 1',
  mapId: 'map 1',
  pointsOfInterest: [poi1, poi2],
  strategy: PoiGroupStrategy.Alternating,
};

const poiGroupSingleDto: PoiGroupSingleDto = {
  id: 'poiSingle',
  name: 'Poi Single 1',
  mapId: map.id,
  pointsOfInterest: [{ ...poi5, id: 'poiSingle' }],
  strategy: PoiGroupStrategy.Single,
};

const sensorMonitoredDolly = {
  id: 'sensor-poi-id-1',
  poiGroupId: 'poiMonitoringGroup1',
  map,
  mapId: map.id,
  name: 'sensor-poi-id-1',
  type: PoiType.DollyPlace,
  mainPose: {
    x: 35.42,
    y: 58.42,
    orientation: 0,
  },
  entryVectorDistance: 1,
  exitVectorDistance: 1,
  occupancy: { occupancyStatus: OccupancyStatus.Free },
  isCloseToWall: false,
  poseAccuracy: 2,
  deviceOccupancy: PoiDeviceOccupancy.Loaded,
  occupancyDevice: {
    device: 'OpcuaDevice1',
    node: 'Node1',
    value: 'Value1',
  },
  booked: false,
  loadType: LoadType.GltStrStandard,
};

const poiMonitoringGroupDto: PoiGroup = {
  id: 'poiMonitoringGroup1',
  name: 'Poi Monitoring Group 1',
  mapId: map.id,
  pointsOfInterest: [sensorMonitoredDolly, poi18],
  strategy: PoiGroupStrategy.PoiMonitoring,
  bufferPlaces: [poi18],
};

export class PoiGroupTestHelper {
  static getPoiGroup(): PoiGroup {
    return objectHelper.cloneDeep(poiGroup);
  }

  static getPoiGroup2(): PoiGroup {
    return objectHelper.cloneDeep(poiGroup2);
  }

  static getPoiDto(): PoiDto {
    return objectHelper.cloneDeep(poiDto);
  }

  static getThreePoisWithDifferentTypes(): Poi[] {
    return [poi1, poi3, poi4];
  }

  static getPoiOccupancyStatusChangeSignalRDto(): PoiOccupancyStatusChangeDto {
    return {
      poiId: poiDto.id,
      status: OccupancyStatus.Occupied,
      vehicleId: 'vehicleId',
      statusUpdatedOnUtc: Date().toString(),
      workAreaId: map.workAreaId,
    };
  }

  static getPoiDeviceOccupancyStatusSignalRDto(): PoiDeviceOccupancyStatusChangeDto {
    return { poiId: poiDto.id, loadedState: PoiDeviceOccupancy.Loaded, workAreaId: map.workAreaId };
  }

  static getPoiBookingStatusSignalRDto(): PoiBookingStatusChangeDto {
    return {
      poiId: poiDto.id,
      booked: true,
      bookedDateTime: Date().toString(),
      workAreaId: map.workAreaId,
      vehicleId: '1',
    };
  }

  static getSensorMonitoredDolly(): Poi {
    return sensorMonitoredDolly;
  }

  static getPoiMonitoringGroupDto(): PoiGroup {
    const pgClone = objectHelper.cloneDeep(poiMonitoringGroupDto);
    return {
      ...pgClone,
      map,
    };
  }

  static getPoiMonitoringGroupWithBufferPlacesDto(): PoiGroup {
    const pgClone = objectHelper.cloneDeep(poiMonitoringGroupDto);
    return {
      ...pgClone,
      map,
      bufferPlaces: [poi18],
    };
  }

  static getExpectedViewModel(pg: PoiGroup): object {
    const pgClone = objectHelper.cloneDeep(pg);
    return {
      ...pgClone,
      mapId: pg.map && pg.map.id,
      poisDropdown: [poi5, poi6, poi1, poi2],
      nameValidationMessage: '',
      poisValidationMessage: '',
    };
  }

  static getPoiGroupsList(): PoiGroup[] {
    return [objectHelper.cloneDeep(poiGroup), objectHelper.cloneDeep(poiGroup2)];
  }

  static getPoiGroupsListWithNull(): any[] {
    return [objectHelper.cloneDeep(poiGroup), objectHelper.cloneDeep(poiGroup2), null];
  }

  static getPoisGroupsEntities(): Dictionary<PoiGroup> {
    return arrayToDictionary(PoiGroupTestHelper.getPoiGroupsList());
  }

  static getPoiList1(): Poi[] {
    return [objectHelper.cloneDeep(poi1), objectHelper.cloneDeep(poi2)];
  }

  static getPoiList2(): Poi[] {
    return [poi1, poi2, poi3, poi4];
  }

  static getPoiList3(): Poi[] {
    return [poi1, poi2, poi3, poi4, poi5, poi6, poi7, poi8, poi9, poi10, poi11, poi12, poi13];
  }

  static getPoiList4(): Poi[] {
    return [
      poi1,
      poi2,
      poi3,
      poi4,
      poi5,
      poi6,
      poi7,
      poi8,
      poi9,
      poi10,
      poi11,
      poi12,
      poi13,
      poi15,
    ];
  }

  static getPoiSingleList(): Poi[] {
    return [poi5, poi6];
  }

  static getPoi1(): Poi {
    return objectHelper.cloneDeep(poi1);
  }

  static getPoi2(): Poi {
    return objectHelper.cloneDeep(poi2);
  }

  static getPoi3(): Poi {
    return objectHelper.cloneDeep(poi3);
  }

  static getPoi4(): Poi {
    return objectHelper.cloneDeep(poi4);
  }

  static getPoi6(): Poi {
    return objectHelper.cloneDeep(poi6);
  }

  static getPoi16(): Poi {
    return objectHelper.cloneDeep(poi16);
  }

  static getPoi17(): Poi {
    return objectHelper.cloneDeep(poi17);
  }

  static getPoi18(): Poi {
    return objectHelper.cloneDeep(poi18);
  }

  static getPoiGroupDto(): PoiGroupDto {
    return objectHelper.cloneDeep(poiGroupDto);
  }

  static getPoiGroupSingleDto(): PoiGroupSingleDto {
    return objectHelper.cloneDeep(poiGroupSingleDto);
  }

  static getLargeParkingSpace(): Poi {
    return objectHelper.cloneDeep(poi14);
  }
}
