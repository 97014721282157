import { MapLabelColors } from 'library/styles';

export enum MapElements {
  Rotation = 'rotationIcon',
  ViewPort = 'ViewPort',
  Stage = 'Stage',
}

export const PathStyle = {
  ArrowPoints: 3,
  ArrowSize: 0.3,
};

export const LabelStyle = {
  Color: MapLabelColors.Background,
  Alpha: 0.2,
  Padding: 0.02,
  Corner: 0.05,
};

export const ItemLabelStyle = {
  Opacity: 0.7,
  Padding: 4,
  Scale: 0.7,
  Radius: 3,
};

export const MapScale = {
  MeterToCm: 0.01,
};

export const MapIconAnchor = {
  Left: 0,
  Middle: 0.5,
  RightOrBottom: 1,
};

export const MapPosition = {
  Down: 1,
  Up: -1,
  Left: -1,
  Right: 1,
};
