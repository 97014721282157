import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ISizeInput } from '@bmw-ds/components/ds-interfaces/size.interface';
import { VehicleType } from 'core/models';
import { ExpandCollapseButtonsService } from 'library/components/expand-collapse-buttons/service/expand-collapse-buttons.service';

import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-vehicle-type-filter',
  templateUrl: './vehicle-type-filter.component.html',
  styleUrls: ['./vehicle-type-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTypeFilterComponent implements AfterViewChecked, OnChanges, OnInit {
  @Input() size: ISizeInput = 'sm';
  @Input() vehicleTypes: number[] = [];
  @Input() filterVehicleTypesFlag = false;
  @Input() selection: VehicleType[] = [];

  @Output() readonly selectedVehicleTypesChanged: EventEmitter<VehicleType[]> = new EventEmitter<
    VehicleType[]
  >();
  showResetButton = true;

  showCollapseAndExpandButtons$: Observable<boolean> = of();

  constructor(
    private readonly cdRef: ChangeDetectorRef,
    private readonly expandCollapseButtonsService: ExpandCollapseButtonsService
  ) {
    this.selectedVehicleTypesChanged.emit(this.selection);
  }

  get VehicleType(): typeof VehicleType {
    return VehicleType;
  }

  get VehicleTypeEnums(): number[] {
    let filteredEnums = Object.keys(VehicleType)
      .filter(k => Number.isInteger(Number.parseInt(k)))
      .map(n => Number.parseInt(n));

    if (this.filterVehicleTypesFlag && this.vehicleTypes.length > 0) {
      filteredEnums = filteredEnums.filter(n => this.vehicleTypes.includes(n));
    }

    if (this.filterVehicleTypesFlag && this.vehicleTypes.length === 0) {
      return [];
    }

    if (filteredEnums.length === 1) {
      this.showResetButton = false;
      return [];
    }

    return filteredEnums;
  }

  ngOnInit(): void {
    this.showCollapseAndExpandButtons$ =
      this.expandCollapseButtonsService.getShowExpandCollapseAllButtons();
  }

  ngOnChanges({ selection }: TypedChanges<VehicleTypeFilterComponent>): void {
    if (selection?.currentValue) {
      this.selection = selection.currentValue;
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.markForCheck();
  }

  resetSelection(): void {
    this.selection = [];
    this.cdRef.markForCheck();
    this.selectedVehicleTypesChanged.emit(this.selection);
  }

  onCardClick(vehicleType: VehicleType): void {
    if (this.selection.includes(vehicleType)) {
      this.selection = [...this.selection.filter(vt => vt !== vehicleType)];
    } else {
      this.selection = [...this.selection, vehicleType];
    }
    this.cdRef.markForCheck();
    this.selectedVehicleTypesChanged.emit(this.selection);
  }
}
