import { Injectable } from '@angular/core';
import { HardwareVersion, VehicleGroup } from 'core/models';

export enum CapabilityEnum {
  UploadLif = 'UploadLif',
  SoftwareUpdateOTA = 'SoftwareUpdateOTA',
  DirectAccess = 'DirectAccess',
  SendMap = 'SendMap',
  SendZoneSet = 'SendZoneSet',
  ManageMapData = 'ManageMapData',
  FuelingAllowed = 'FuelingAllowed',
}

type ConfiguredHardwareVersions = Exclude<HardwareVersion, HardwareVersion.Unknown>;

export const HardwareVersionCapabilities: Record<ConfiguredHardwareVersions, CapabilityEnum[]> = {
  [HardwareVersion.StrVersion2]: [
    CapabilityEnum.DirectAccess,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.StrVersion3]: [
    CapabilityEnum.DirectAccess,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.StrVersion4]: [
    CapabilityEnum.SoftwareUpdateOTA,
    CapabilityEnum.DirectAccess,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.TuggerTrainDsV1]: [
    CapabilityEnum.SoftwareUpdateOTA,
    CapabilityEnum.DirectAccess,
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
  [HardwareVersion.TuggerTrainFourAmV2]: [
    CapabilityEnum.SoftwareUpdateOTA,
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.ForkliftFourAm_15t]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.ForkliftFourAm_20t]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.TuggerTrainManual]: [CapabilityEnum.SoftwareUpdateOTA, CapabilityEnum.UploadLif],
  [HardwareVersion.ForkliftManual]: [CapabilityEnum.UploadLif],
  [HardwareVersion.DsAmadeus]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [HardwareVersion.DSType2]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
  [HardwareVersion.DSType3]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
  [HardwareVersion.DSType4]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
  [HardwareVersion.DSType5]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
  [HardwareVersion.DSType1]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
  [HardwareVersion.AgiloxOCF]: [CapabilityEnum.SendZoneSet],
  [HardwareVersion.AgiloxONE]: [CapabilityEnum.SendZoneSet],
};

export const VehicleGroupCapabilities: Record<
  Exclude<VehicleGroup, VehicleGroup.Unknown>,
  CapabilityEnum[]
> = {
  [VehicleGroup.Str]: [
    CapabilityEnum.DirectAccess,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [VehicleGroup.TuggerTrainFourAmV2]: [
    CapabilityEnum.SoftwareUpdateOTA,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [VehicleGroup.TuggerTrainDs]: [
    CapabilityEnum.SoftwareUpdateOTA,
    CapabilityEnum.DirectAccess,
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
    CapabilityEnum.FuelingAllowed,
  ],
  [VehicleGroup.USTR]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [VehicleGroup.Forklift]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendMap,
    CapabilityEnum.SendZoneSet,
  ],
  [VehicleGroup.Manual]: [CapabilityEnum.SendMap, CapabilityEnum.SendZoneSet],
  [VehicleGroup.U_AGV]: [
    CapabilityEnum.UploadLif,
    CapabilityEnum.SendZoneSet,
    CapabilityEnum.ManageMapData,
  ],
};

@Injectable()
export class CapabilitiesService {
  isCapabilitySupported(capability: CapabilityEnum, hardwareVersion: HardwareVersion): boolean {
    return (HardwareVersionCapabilities[hardwareVersion] ?? []).includes(capability);
  }

  isCapabilitySupportedByVehicleGroup(
    capability: CapabilityEnum,
    vehicleGroup: VehicleGroup
  ): boolean {
    if (!vehicleGroup) return false;
    return (VehicleGroupCapabilities[vehicleGroup] ?? []).includes(capability);
  }
}
