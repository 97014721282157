import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import {
  ONE_MINUTE,
  SIXTY_MINUTES,
  THIRTY_DAYS,
  TWELVE_MONTHS,
} from 'core/constants/standard-date-time.constant';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  format,
  isToday,
  isTomorrow,
  isYesterday,
} from 'date-fns';
import { parseDateTime } from 'library/helpers/standard-date-formatter.helper';
import { AbstractTranslatePipe } from './abstract-translate.pipe';

@Pipe({
  name: 'standardTimeFrame',
  pure: false, // required, translation is unpure
})
export class StandardTimeFramePipe
  extends AbstractTranslatePipe<string>
  implements PipeTransform, OnDestroy
{
  transform(
    date: Date | string | null | undefined,
    currentDateTime: Date | string = new Date()
  ): string {
    if (!date) {
      return '';
    }
    this.ensureLangChangeReaction();
    return this.formatTimeFrame(date, currentDateTime);
  }

  private formatTimeFrame(dateValue: Date | string, currentDateTime: Date | string): string {
    const parsedDate = typeof dateValue === 'string' ? parseDateTime(dateValue) : dateValue;

    const now =
      typeof currentDateTime === 'string' ? parseDateTime(currentDateTime) : currentDateTime;

    if (isToday(parsedDate)) {
      return this.handleToday(parsedDate, now);
    } else if (isYesterday(parsedDate)) {
      return this.getTranslation('yesterday');
    } else if (isTomorrow(parsedDate)) {
      return this.getTranslation('tomorrow');
    } else {
      return this.handleOtherDates(parsedDate, now);
    }
  }

  private handleToday(parsedDate: Date, now: Date): string {
    const minutesDiff = differenceInMinutes(now, parsedDate);
    const hoursDiff = differenceInHours(now, parsedDate);
    if (minutesDiff < 0) {
      const dateFormat = this.getTranslation('specificDate');
      return format(parsedDate, dateFormat);
    } else if (minutesDiff < ONE_MINUTE) {
      return this.getTranslation('justNow');
    } else if (minutesDiff < SIXTY_MINUTES) {
      return this.getTranslation('minutesAgo', minutesDiff);
    } else {
      return this.getTranslation('hoursAgo', hoursDiff);
    }
  }

  private getDaysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  private handleOtherDates(parsedDate: Date, now: Date): string {
    const daysDiff = differenceInDays(now, parsedDate);
    const monthsDiff = differenceInMonths(now, parsedDate);
    if (
      (monthsDiff === 1 && daysDiff < THIRTY_DAYS) ||
      (monthsDiff === 0 && now.getFullYear() === parsedDate.getFullYear())
    ) {
      const daysInPreviousMonth = this.getDaysInMonth(
        parsedDate.getFullYear(),
        parsedDate.getMonth()
      );
      if (daysDiff < daysInPreviousMonth) {
        return this.getTranslation('daysAgo', daysDiff);
      }
    }

    if (monthsDiff === 0) {
      return this.getTranslation('daysAgo', daysDiff);
    } else if (monthsDiff >= 1 && monthsDiff < TWELVE_MONTHS) {
      return this.getTranslation('monthsAgo', monthsDiff);
    }

    const dateFormat = this.getTranslation('specificDate');
    return format(parsedDate, dateFormat);
  }

  private getTranslation(key: string, value?: number): string {
    let translationString = this.atsTranslationService.get(
      `shared.dateFormats.timeFrameTranslations.${key}`
    );

    if (value !== undefined) {
      translationString = translationString.replace('{0}', Math.floor(value).toString());

      if (value === 1) {
        translationString = translationString
          .replace('minutes', 'minute')
          .replace('hours', 'hour')
          .replace('days', 'day')
          .replace('months', 'month')
          .replace('Minuten', 'Minute')
          .replace('Stunden', 'Stunde')
          .replace('Tagen', 'Tag')
          .replace('Monaten', 'Monat');
      }
    }

    return translationString;
  }
}
