import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  AmaSettingsDto,
  AssignmentTriggerDto,
  BrakeTestDto,
  EndOfShiftModeDto,
  JobManagerFeatures,
  MatrixOptimizationDto,
  MissionErrorHandlingDefaultsRequestModel,
  MissionErrorHandlingDefaultsResponseModel,
  ParkingAndChargingDto,
  ServiceZonesDto,
  WaitingQueueSettingsDto,
  WaitOnPoiDto,
} from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class JobSettingsService extends TenantHttpClient {
  protected apiUrl = environment.Services.JobManager;
  private readonly servicePath = API_SERVICES.Features;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getJobManagerFeaturesV2(): Observable<JobManagerFeatures> {
    return this.get<JobManagerFeatures>(`${this.servicePath}/v2`);
  }

  updateEndOfShiftMode(value: boolean): Observable<EndOfShiftModeDto> {
    return this.put<EndOfShiftModeDto>(`${this.servicePath}/EndOfShiftMode`, {
      isToggledOn: value,
    });
  }

  updateParkingAndCharging(value: boolean): Observable<ParkingAndChargingDto> {
    return this.put<ParkingAndChargingDto>(`${this.servicePath}/parkingAndCharging`, {
      isToggledOn: value,
    });
  }

  updateWaitOnPoi(value: boolean): Observable<WaitOnPoiDto> {
    return this.put<WaitOnPoiDto>(`${this.servicePath}/waitOnPoi`, {
      isToggledOn: value,
    });
  }

  updateServiceZones(value: boolean): Observable<ServiceZonesDto> {
    return this.put<ServiceZonesDto>(`${this.servicePath}/serviceZones`, {
      isToggledOn: value,
    });
  }

  updateAssignmentTrigger(value: boolean): Observable<AssignmentTriggerDto> {
    return this.put<AssignmentTriggerDto>(`${this.servicePath}/assignmentTrigger`, {
      isToggledOn: value,
    });
  }

  updateAmaSettings(value: boolean): Observable<AmaSettingsDto> {
    return this.put<AmaSettingsDto>(`${this.servicePath}/amaSetting`, {
      isToggledOn: value,
    });
  }

  updateWaitingQueueSettings(value: boolean): Observable<WaitingQueueSettingsDto> {
    return this.put<WaitingQueueSettingsDto>(`${this.servicePath}/waitingQueue`, {
      isToggledOn: value,
    });
  }

  updateMatrixOptimization(value: boolean): Observable<MatrixOptimizationDto> {
    return this.put<MatrixOptimizationDto>(`${this.servicePath}/MatrixOptimization`, {
      isToggledOn: value,
    });
  }

  updateBrakeTest(value: boolean): Observable<BrakeTestDto> {
    return this.put<BrakeTestDto>(`${this.servicePath}/brakeTest`, {
      isToggledOn: value,
    });
  }

  updateMissionErrorHandlingSettings(
    settings: MissionErrorHandlingDefaultsRequestModel
  ): Observable<MissionErrorHandlingDefaultsResponseModel> {
    return this.put<MissionErrorHandlingDefaultsResponseModel>(
      `${this.servicePath}/missionErrorHandlingDefaults`,
      settings
    );
  }
}
