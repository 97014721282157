export enum MapDataUploadStatus {
  Pending,
  Completed,
}

export enum VehicleOptionStatus {
  Offline = 'vehicleOffline',
  CurrentActiveMap = 'currentActiveMap',
  EnabledMap = 'enabled',
  MapNotAvailableOnVehicle = 'notAvailable',
  Busy = 'busy',
  Available = 'available',
  AlreadySentMap = 'alreadySent',
}

export enum VehicleMapDataDialogState {
  UploadVehicleMapData,
  Success,
  Failed,
  PartialSuccess,
  DownloadSuccess,
  EnableMap,
}

export enum VehicleMapDataStatus {
  Validated = 1,
  InProcessing = 2,
  ValidationFailed = 3,
  NotValidated = 4,
}
