<app-setting-box translationKey="settings.functions.collectivePause">
  <app-last-changed-date
    title
    dateUpdated="{{ collectivePauseToggle | latestDate : 'dateUpdated' }}"></app-last-changed-date>
  <div main>
    <div class="col-9">
      <span>{{ description | translate }}</span>
    </div>
    <div [formGroup]="collectivePauseForm" *ngIf="isLoaded; else error" class="mt-4x">
      <label class="vehicle-text-label">{{
        'settings.featureToggles.collectivePauseSection.vehicles' | translate
      }}</label>
      <ds-switch formControlName="pauseStr">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.str' | translate
      }}</ds-switch>
      <ds-switch formControlName="pauseTuggertrain">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.arz' | translate
      }}</ds-switch>
      <ds-switch formControlName="pauseForklift">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.asz' | translate
      }}</ds-switch>
      <ds-switch formControlName="pauseUAGV">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.uagv' | translate
      }}</ds-switch>
    </div>
  </div>
</app-setting-box>

<app-functions-modal
  (confirm)="onModalAction($event)"
  [modalContentTemplate]="modalContentTemplate"
  [isModalOpen]="isModalOpen"
  [modalInputData]="isActivatingCollectivePause ? activateWarning : deactivateWarning">
</app-functions-modal>

<ng-template #modalContentTemplate>
  <ng-container *ngIf="isActivatingCollectivePause; else deactivateCollectivePauseMessage">
    <p>
      {{
        'settings.featureToggles.collectivePauseSection.warning.activate.description' | translate
      }}
    </p>
    <p class="text-label mt-4x mb-4x">
      {{
        'settings.featureToggles.collectivePauseSection.warning.activate.subDescription' | translate
      }}
    </p>
    <div [ngSwitch]="selectedVehicleType">
      <ng-container *ngSwitchCase="VehicleTypeEnum.UnitLoad">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.str' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="VehicleTypeEnum.TuggerTrain">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.arz' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="VehicleTypeEnum.Forklift">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.asz' | translate
      }}</ng-container>
      <ng-container *ngSwitchCase="VehicleTypeEnum.U_AGV">{{
        'settings.featureToggles.collectivePauseSection.vehicleType.uagv' | translate
      }}</ng-container>
    </div>
  </ng-container>
  <ng-template #deactivateCollectivePauseMessage>
    <p>
      {{
        'settings.featureToggles.collectivePauseSection.warning.deactivate.description' | translate
      }}
    </p>
  </ng-template>
</ng-template>

<ng-template #error>
  <div class="error-background">
    <label data-cy="errorMessage" class="label-value error-text"
      ><ds-icon class="mx-2x icon" icon="error" tone="critical"></ds-icon>
      {{ 'settings.functions.unknownValue' | translate }}
    </label>
  </div>
</ng-template>
