<ds-box>
  <ds-box-header>
    <span class="title-md">{{ 'settings.ipstAlertNowSettings.helpTool.heading' | translate }}</span>
  </ds-box-header>
  <ds-box-content divider="full">
    <app-ipst-alert-now-help-tool-filters
      [errorLanguage]="errorLanguage()"
      (retrieveRecipientKeys)="onRetrieveRecipientKeys($event)"
      [reset]="resetValues"
      [vehicles$]="allVehicles$"
      [zones]="zones$ | async"
      [errors]="tableErrorForwarding$ | async"></app-ipst-alert-now-help-tool-filters>
    <hr class="graphLineSep thin" />
    <app-ipst-alert-now-help-tool-results
      [recipientKeys]="recipientKeys | async"></app-ipst-alert-now-help-tool-results>
  </ds-box-content>
  <ds-box-footer>
    <div class="d-flex justify-content-end">
      <button
        ds-button
        class="ms-4x"
        [variant]="'ghost'"
        [icon]="resetIcon"
        data-cy="resetValues"
        (click)="resetDropdowns()">
        {{ 'shared.tableColumnActions.reset' | translate }}
      </button>
    </div>
  </ds-box-footer>
</ds-box>
