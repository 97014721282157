import { Injectable } from '@angular/core';
import { Zone } from 'core/models';
import { MINIMUM_ZONE_POINTS } from 'modules/maps/constants/map.constant';
import { formatZone } from 'modules/maps/helpers';
import { MapLayerView, ZoneMapItem, ZoneVertex } from 'modules/maps/layers';
import { PointerUpArgs } from 'modules/maps/models';
import { Point } from 'pixi.js';
import { StageService } from '../stage.service';
import { EditorBase, EditorMode } from './editor-base';

export const NEW_POINT_INDEX = -1;

@Injectable()
export class ZoneEditService extends EditorBase {
  private selectedIndex = NEW_POINT_INDEX;
  private lastPointerSnap = false;
  private isEditable = true;
  private zoneGraphic?: ZoneMapItem;

  get hasValidPolygonArea(): boolean {
    return this.drawing.hasValidPolygonArea();
  }

  private get isExistingPoint(): boolean {
    return this.selectedIndex !== NEW_POINT_INDEX;
  }

  private get isNewPoint(): boolean {
    return this.selectedIndex === NEW_POINT_INDEX;
  }

  constructor(readonly stageService: StageService) {
    super(stageService);
  }

  start(view: MapLayerView, zone?: Zone, graphic?: ZoneMapItem): void {
    super.setup(view);

    if (zone) {
      this.setZone(zone);
      this.mode = EditorMode.Edit;
    }

    this.zoneGraphic = graphic;

    if (this.zoneGraphic && !this.zoneGraphic.vertexContainer.visible) {
      this.zoneGraphic.showVertices(true);
    }

    this.setNewGraphicStyle();
  }

  setZoneMapItem(zone: ZoneMapItem): void {
    this.zoneGraphic = zone;
  }

  private setZone(zone: Zone): void {
    const points = formatZone(zone)?.polygon.map(p => new Point(p.x, p.y));
    this.drawing.setPoints(points);
  }

  onScaleChange(scale: number): void {
    this.pointer.setScale(scale);
  }

  destroyEditor(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });

    this.enableDrawing(false);
    this.resetEditor();

    this.destroyEditContainer();
  }

  protected onCancel(): void {
    this.addPointOnFirstPosition();
  }

  resetEditor(): void {
    this.drawing.clearPoints();
    this.drawing.clearEdges();
    this.zoneGraphic?.toggleSelection();
    this.zoneGraphic = undefined;

    this.drawing.clear();
    this.resetPointer();
  }

  setEditable(isEditable: boolean): void {
    this.isEditable = isEditable;
  }

  protected setPointer(point: Point, visible: boolean): void {
    this.pointer.position.copyFrom(point);
    this.pointer.visible = visible;
  }

  protected setPointerStyle(): void {
    this.pointer.drawGraphic(this.isCreateMode);
  }

  protected drawNewGraphic(): void {
    if (!this.isCreateMode) {
      return;
    }

    this.drawing.drawGraphic(this.pointer);
  }

  // #region Pointer Events
  protected onPointerDown(cursor: Point): void {
    this.onPointerDownEditing(cursor);
  }

  protected onPointerUp(pointer: PointerUpArgs): void {
    if (pointer.dragged && this.isCreateMode) {
      return;
    }

    if (this.isCreateMode) {
      this.onPointerUpCreating(pointer.position);
    } else if (this.isEditable) {
      this.onPointerUpEditing();
    }
  }

  private onPointerDownEditing(cursor: Point): void {
    if (this.isEditable && this.isEditMode) {
      this.selectedIndex = this.drawing.getPointIndex(this.pointer.position);

      if (this.pointer.hasSnapPoint) {
        this.stageService.pauseMapDrag();
        this.pointer.position.copyFrom(cursor);

        if (this.isNewPoint) {
          this.addNewPointOnEdge(cursor);
        }
      }
    }
  }

  private onPointerUpCreating(cursor: Point): void {
    if (this.drawing.hasHitClosingPoint(cursor, this.pointer)) {
      this.completeDrawing();
    } else {
      this.drawing.addPoint(cursor);

      if (!this.pointer.hasClosePoint) {
        this.pointer.setCloseZoneHitArea(this.drawing.firstPoint);
      }
    }
  }

  private onPointerUpEditing(): void {
    if (this.isExistingPoint) {
      this.drawing.setEdges();
    }
    this.stageService.resumeMapDrag();
    this.selectedIndex = NEW_POINT_INDEX;
  }

  protected onPointerMove(cursor: Point): void {
    if (this.isCreateMode) {
      this.onPointerMoveCreating(cursor);
    } else if (this.isEditable) {
      this.onPointerMoveEditing(cursor);
    }
    if (this.pointer.hasSnapPoint) {
      this.movePointerToFront();
    }
  }

  private onPointerMoveEditing(cursor: Point): void {
    if (this.isExistingPoint) {
      this.drawing.movePoint(this.selectedIndex, cursor);
      this.pointer.showPointer(false);

      this.shapeChange.next({ shape: this.drawing.points, mode: this.mode });
    } else {
      const snapVertex = this.drawing.getVertexSnapPoint(cursor, this.pointer);
      const snapEdge = this.drawing.getEdgeSnapPoint(cursor, this.pointer);
      const position = snapVertex || snapEdge || cursor;
      this.pointer.showPointer(position === snapEdge, position, position !== cursor);
    }

    if (this.lastPointerSnap !== this.pointer.hasSnapPoint) {
      this.hitBorder.next(this.pointer.hasSnapPoint);
      this.lastPointerSnap = this.pointer.hasSnapPoint;
    }
  }

  private onPointerMoveCreating(cursor: Point): void {
    if (
      this.drawing.isPolygon &&
      this.pointer.hitClosingPoint(cursor) &&
      this.drawing.hasValidPolygonArea()
    ) {
      return this.pointer.showPointer(true, this.drawing.firstPoint);
    }
    this.pointer.showPointer(false, cursor);
  }

  onChangePosition(): void {
    if (this.zoneGraphic) {
      this.drawing.setPoints([...this.zoneGraphic.getShape()]);
      this.setEditable(true);

      this.shapeChange.next({ shape: this.drawing.points, mode: EditorMode.Edit });
    }
  }

  onMovePosition(point: Point, dragPosition: Point): void {
    this.zoneGraphic?.setPosition(new Point(point.x - dragPosition.x, point.y - dragPosition.y));
    this.setEditable(false);
  }
  // #endregion

  // #region Zone Points
  private addPointOnFirstPosition(): void {
    this.onPointerUp({ position: this.drawing.firstPoint });
  }

  private addNewPointOnEdge(point: Point): void {
    const result = this.drawing.addPointOnEdge(point, this.pointer);

    if (result !== null) {
      this.selectedIndex = result;
    }
  }

  removePointFromZone(vertex: ZoneVertex): void {
    this.drawing.removePoint(vertex.index);
    this.shapeChange.next({ shape: this.drawing.points, mode: this.mode });

    if (this.zoneGraphic && this.zoneGraphic.verticesCount > MINIMUM_ZONE_POINTS) {
      this.zoneGraphic?.vertexContainer.selectClosestVertex(vertex);
    }
  }

  private completeDrawing(): void {
    this.drawing.closePolygon();
    this.shapeChange.next({ shape: this.drawing.points, mode: this.mode });

    this.mode = EditorMode.Edit;
    this.drawing.visible = false;
  }
  // #endregion
}
