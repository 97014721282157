export const SVG_ICONS = {
  Vehicle_Icon_Manual: 'assets/global/vehicles/icons/ManualIcon.svg',
  Vehicle_Icon_Autonomous: 'assets/global/vehicles/icons/AutonomousIcon.svg',
  Vehicle_Icon_Semi_Autonomous: 'assets/global/vehicles/icons/SemiAutonomousIcon.svg',
  Vehicle_Icon_Maintenance: 'assets/global/vehicles/icons/MaintenanceIcon.svg',
  Vehicle_Icon_Loaded: 'assets/global/vehicles/icons/LoadedIcon.svg',
  Vehicle_Icon_Paused: 'assets/global/vehicles/icons/PausedIcon.svg',
  Vehicle_Icon_Charging: 'assets/global/vehicles/icons/ChargingIcon.svg',
  Vehicle_Icon_Not_Initialized: 'assets/global/vehicles/icons/NotInitialisedIcon.svg',
  Vehicle_Icon_Break_Test: 'assets/global/vehicles/icons/BrakeTestIcon.svg',
  Vehicle_Icon_STR: 'assets/global/vehicles/icons/StrIcon.svg',
  Vehicle_Icon_Tugger: 'assets/global/vehicles/icons/TuggerTrainIcon.svg',
  Vehicle_Icon_Forklift: 'assets/global/vehicles/icons/ForkliftIcon.svg',
  Vehicle_Icon_AgiloxOne: 'assets/global/vehicles/icons/AgiloxIconOne.svg',
  Vehicle_Icon_AgiloxOcf: 'assets/global/vehicles/icons/AgiloxIconOcf.svg',
  Vehicle_Icon_DsAmadeus: 'assets/global/vehicles/icons/DsAmadeusIcon.svg',
  Vehicle_Icon_DsUagv: 'assets/global/vehicles/icons/DsUagvIcon.svg',
};
