<app-base-page-layout [hasToolbar]="false" pageHeading="versionInformation.versionInformation">
  <div base-page-content class="content-area">
    <label class="label-text" data-cy="versionHeader">{{
      'versionInformation.currentVersion' | translate
    }}</label>
    <h2 class="version-number" data-cy="versionNumber">
      {{ versionInformation?.version | notApplicable }}
    </h2>

    <iframe
      *ngIf="versionInformation"
      [src]="releaseNotesUrl | safe : 'resourceUrl'"
      style="border: none; height: 100%"
      title="ReleaseNotes"></iframe>
  </div>
</app-base-page-layout>
