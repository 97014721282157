<div
  *ngIf="!filterVehicleTypesFlag || (filterVehicleTypesFlag && VehicleTypeEnums.length > 1)"
  class="container-fluid">
  <div class="row" data-cy="filterVehicleType">
    <div *ngFor="let vehicleType of VehicleTypeEnums" class="col me-4x mb-4x">
      <ds-box [elevation]="selection.includes(vehicleType) ? 'base' : 'none'" surfaceTone="neutral">
        <ds-box-content (boxClick)="onCardClick(vehicleType)" [isClickable]="true">
          <div class="vehicleFilterTypeContent">
            <div>
              <img
                [src]="
                  './assets/global/vehicles/VehicleType/icon/' + VehicleType[vehicleType] + '.png'
                "
                alt="{{ vehicleType }}"
                class="vehicleTypeFilterImage {{ size }}" />
              <label class="vehicleTypeFilterLabel">
                {{ vehicleType | enumTranslation : 'vehicleType' | uppercase }}
              </label>
            </div>

            <ds-form-field
              [attr.data-cy]="VehicleType[vehicleType] + 'FilterInput'"
              class="vehicleTypeFilterCheckbox">
              <ds-checkbox-group variant="stacked">
                <input
                  [checked]="selection.includes(vehicleType)"
                  ds-input
                  size="lg"
                  type="checkbox" />
              </ds-checkbox-group>
            </ds-form-field>
          </div>
        </ds-box-content>
      </ds-box>
    </div>
    <div class="vehicleButtonsStyling">
      <div *ngIf="showCollapseAndExpandButtons$ | async" class="vehicleExpandCollapseButtons">
        <app-expand-collapse-buttons></app-expand-collapse-buttons>
      </div>
      <div class="vehicleFilterTypeReset">
        <button
          (click)="resetSelection()"
          *ngIf="showResetButton"
          [icon]="'arrow_go_back'"
          [variant]="'ghost'"
          data-cy="vehicleTypeFilterResetButton"
          ds-button>
          {{ 'shared.tableColumnActions.reset' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
