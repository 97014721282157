/* eslint-disable max-lines */
import { NgModule } from '@angular/core';
import { AtsTranslationService } from 'core/services/ats-translation.service';
import { LibraryModule } from 'library/library.module';

import { VehicleChangeWorkingAreaModalComponent } from 'modules/vehicles/components/vehicle-change-working-area-modal/vehicle-change-working-area-modal.component';
import {
  AgGridDateTimeFilterComponent,
  AgGridSingleDateTimeFilterComponent,
  AgGridValidatedCellComponent,
  OrganizationChartTableCellComponent,
  PageNotFoundComponent,
  PoiMatrixCalculationCellComponent,
  SoftwareVersionStatusCellComponent,
  VehicleTypeFilterComponent,
} from 'shared/components';
import { AgGridCellTemplateFieldSelectorDirective } from 'shared/components/ag-grid/directives/ag-grid-cell-template-field-selector/ag-grid-cell-template-field-selector.directive';
import { AgGridExpandCollapseAllDirective } from 'shared/components/ag-grid/directives/ag-grid-expand-collapse-all/ag-grid-expand-collapse-all.directive';
import { VehicleSoftwareStatusComponent } from 'shared/components/vehicles/vehicle-software-status/vehicle-software-status.component'; // TODO - Should not be in Shared
import { VehicleTypeSelectComponent } from 'shared/components/vehicles/vehicle-type-select/vehicle-type-select.component';
import { ActionMenuComponent } from './components/action-menu/action-menu.component';
import { ActionMenuCellComponent } from './components/ag-grid/action-menu-cell/action-menu-cell.component';
import { CopyTextCellComponent } from './components/ag-grid/copy-text-cell/copy-text-cell.component';
import { SwitchNotificationCellComponent } from './components/ag-grid/switch-notification-cell/switch-notification-cell.component';
import { SwitchSettingCellComponent } from './components/ag-grid/switch-setting-cell/switch-setting-cell.component';
import { TrafficLightCellComponent } from './components/ag-grid/traffic-light-cell/traffic-light-cell.component';
import { AssignUserRoleModalComponent } from './components/assign-user-role-modal/assign-user-role-modal.component';
import { BoxFooterComponent } from './components/box-footer/box-footer.component';
import { BreadcrumbBarComponent } from './components/breadcrumb-bar/breadcrumb-bar.component';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { DetailsPanelComponent } from './components/details-panel/details-panel.component';
import { EditBarComponent } from './components/edit-bar/edit-bar.component';
import { EditToggleComponent } from './components/edit-toggle/edit-toggle.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MissionActionButtonsComponent } from './components/mission-action-buttons/mission-action-buttons.component';
import { MissionErrorHandlingModalComponent } from './components/mission-error-handling-modal/mission-error-handling-modal.component';
import { MissionTraceStepDetailsComponent } from './components/mission-trace-step-details/mission-trace-step-details.component';
import { NoPermissionComponent } from './components/no-permission/no-permission.component';
import { NotFoundDetailsComponent } from './components/not-found-details/not-found-details.component';
import { NotauthorizedComponent } from './components/notauthorized/notauthorized.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { OrganizationChartTableComponent } from './components/organization-chart-table/organization-chart-table.component';
import { OrganizationChartComponent } from './components/organization-chart/organization-chart.component';
import { RegisterUserComponent } from './components/register-user/register-user.component';
import { StartPageSettingsComponent } from './components/start-page-settings/start-page-settings.component';
import { StepAttributeHeightComponent } from './components/step-attribute-height/step-attribute-height.component';
import { StepAttributePoiAndMappingComponent } from './components/step-attribute-poi-and-mapping/step-attribute-poi-and-mapping.component';
import { StepAttributePoiComponent } from './components/step-attribute-poi/step-attribute-poi.component';
import { StepAttributeTimeSpanComponent } from './components/step-attribute-timespan/step-attribute-timespan.component';
import { StepAttributeComponent } from './components/step-attribute/step-attribute.component';
import { StepTypeSelectionComponent } from './components/step-type-selection/step-type-selection.component';
import { TableReorderableComponent } from './components/table-reorderable/table-reorderable.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { TreeTableReorderableComponent } from './components/tree-table-reorderable/tree-table-reorderable.component';
import { UserDetailsDialogComponent } from './components/user-details-dialog/user-details-dialog.component';
import { UserSettingsListOptionsComponent } from './components/user-settings-list-options/user-settings-list-options.component';
import { StepsDialogComponent } from './components/vehicles/steps-dialog/steps-dialog.component';
import { VehicleActionsComponent } from './components/vehicles/vehicle-actions/vehicle-actions.component';
import { VehicleLocalizeComponent } from './components/vehicles/vehicle-localize/vehicle-localize.component';
import { VehicleMultiselectComponent } from './components/vehicles/vehicle-multiselect/vehicle-multiselect.component';
import { VehicleSendStepsComponent } from './components/vehicles/vehicle-send-steps/vehicle-send-steps.component';
import { VehicleSoftwareUpdateMultiselectComponent } from './components/vehicles/vehicle-software-update-multiselect/vehicle-software-update-multiselect.component';
import { VehicleStateIconsComponent } from './components/vehicles/vehicle-state-icons/vehicle-state-icons.component';
import { ZoneVersionComponent } from './components/vehicles/zone-version/zone-version.component';
import { VersionInformationComponent } from './components/version-information/version-information.component';
import { WorkingAreaNotFoundComponent } from './components/working-area-not-found/working-area-not-found.component';

import { BaseToolBarComponent } from './containers/base-tool-bar/base-tool-bar.component';
import { MissionTraceStepDetailsContainerComponent } from './containers/mission-trace-step-details-container/mission-trace-step-details-container.component';
import { NotificationPanelContainerComponent } from './containers/notification-panel-container/notification-panel-container.component';
import { UserSettingsModalComponent } from './containers/user-settings-modal-container/user-settings-modal-container.component';

import { BasePageLayoutComponent } from './layouts/base-page-layout/base-page-layout.component';
import { BoxLayoutComponent } from './layouts/box-layout/box-layout.component';
import { PlainLayoutComponent } from './layouts/plain-layout/plain-layout.component';
import { SidemenuContentLayoutComponent } from './layouts/sidemenu-content-layout/sidemenu-content-layout.component';
import { SidepanelListLayoutComponent } from './layouts/sidepanel-list-layout/sidepanel-list-layout.component';
import { TabsContentLayoutComponent } from './layouts/tabs-content-layout/tabs-content-layout.component';

import { VehicleSendZoneSetComponent } from './components/vehicles/vehicle-send-zone-set/vehicle-send-zone-set.component';
import { OverlappingZonesModalComponent } from './components/zone-sets/overlapping-zones-modal.component/overlapping-zones-modal.component';
import { HeaderNavbarIntegrationService } from './services/header-navbar-integration.service';
import { MapNavigationHelperService } from './services/map-navigation-helper.service';
import { MissionErrorHandlingDialogService } from './services/mission-error-handling-dialog.service';

import { ProcessConfiguratorListCellTemplateComponent } from 'modules/jobs/process-configurator/components/process-configurator-list/process-configurator-list-cell-template/process-configurator-list-cell-template.component';
import {
  Is4AmTuggerTrainPipe,
  IsAgiloxForkliftPipe,
  IsDSTuggertrainPipe,
  IsForkliftPipe,
  IsManualVehicle,
  IsNoEmulatorPipe,
  IsTuggerTrainPipe,
  IsUnitLoadPipe,
} from 'modules/maps/pipes/is-vehicle-type.pipe';
import { EvacuationModeModalComponent } from 'modules/settings/components/evacuation-mode-modal/evacuation-mode-modal.component';
import {
  VehicleEnableMapStatusPipe,
  VehicleErrorPipe,
  VehicleMapDataTrafficLightStatusPipe,
  VehicleTrafficLightStatusPipe,
} from 'shared/pipes';
import { AllowLoadedBrakeTestPipe } from 'shared/pipes/allow-loaded-brake-test.pipe';
import { VehicleListCellTemplateComponent } from '../modules/vehicles/components/vehicle-list/vehicle-list-cell-template/vehicle-list-cell-template.component';
import { AgGridCustomDropdownEditorComponent } from './components/ag-grid/ag-grid-custom-dropdown-editor/ag-grid-custom-dropdown-editor.component';
import { AgGridcustomSetFilterComponent } from './components/ag-grid/ag-grid-custom-set-filter/ag-grid-custom-set-filter.component';
import { PersistTableFilterAndColumnStateDirective } from './components/ag-grid/directives/persisting-table-filter-and-column-state/persisting-table-filter-and-column-state.directive';
import { MissionListMissionStatusComponent } from './components/ag-grid/mission-list-mission-status/mission-list-mission-status.component';
import { MissionListVehicleStatusComponent } from './components/ag-grid/mission-list-vehicle-status/mission-list-vehicle-status.component';
import { PoiCellComponent } from './components/ag-grid/poi-cell/poi-cell.component';
import { RecipientKeyCellComponent } from './components/ag-grid/recipient-key-cell/recipient-key-cell.component';
import { RowGroupCellComponent } from './components/ag-grid/row-group-cell/row-group-cell.component';
import { AtsCopilotComponent } from './components/ats-copilot/ats-copilot.component';
import { ChatCardComponent } from './components/ats-copilot/chat-card/chat-card.component';
import { ChatbotIconComponent } from './components/ats-copilot/chatbot-icon/chatbot-icon.component';
import { ButtonToolBarComponent } from './components/button-toolbar/button-toolbar.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { StepAttributeAkzComponent } from './components/step-attribute-akz/step-attribute-akz.component';
import { StepAttributeNodeComponent } from './components/step-attribute-node/step-attribute-node.component';
import { StepAttributePreStorageLocationComponent } from './components/step-attribute-pre-storage-location/step-attribute-pre-storage-location.component';
import { VehicleMapsActionDropdownComponent } from './components/vehicles/vehicle-maps-action-dropdown/vehicle-maps-action-dropdown';
import translations_de from './i18n/shared-de.json';
import translations_en from './i18n/shared-en.json';
import { AgGridPageLayoutComponent } from './layouts/ag-grid-layout/ag-grid-layout.component';
import { TabsContentNoscrollLayoutComponent } from './layouts/tabs-content-noscroll-layout/tabs-content-noscroll-layout.component';
import { VehicleStatusTonePipe } from './pipes/vehicle-status-tone.pipe';

@NgModule({
  declarations: [
    AgGridPageLayoutComponent,
    BasePageLayoutComponent,
    SidepanelListLayoutComponent,
    TabsContentLayoutComponent,
    TabsContentNoscrollLayoutComponent,
    BreadcrumbBarComponent,
    DetailsPanelComponent,
    SidemenuContentLayoutComponent,
    PlainLayoutComponent,
    EditBarComponent,
    EditToggleComponent,
    ToolBarComponent,
    ButtonToolBarComponent,
    AtsCopilotComponent,
    ChatCardComponent,
    ChatbotIconComponent,
    ColorPickerComponent,
    VehicleActionsComponent,
    VehicleMapsActionDropdownComponent,
    VehicleLocalizeComponent,
    VehicleSendStepsComponent,
    VehicleSendZoneSetComponent,
    NotFoundDetailsComponent,
    StepsDialogComponent,
    StepAttributeComponent,
    StepTypeSelectionComponent,
    AssignUserRoleModalComponent,
    VehicleStateIconsComponent,
    VersionInformationComponent,
    MissionActionButtonsComponent,
    VehicleMultiselectComponent,
    VehicleSoftwareUpdateMultiselectComponent,
    VehicleSoftwareStatusComponent,
    MissionErrorHandlingModalComponent,
    MissionTraceStepDetailsComponent,
    ZoneVersionComponent,
    MissionTraceStepDetailsComponent,
    MissionTraceStepDetailsContainerComponent,
    UserSettingsModalComponent,
    UserSettingsListOptionsComponent,
    StartPageSettingsComponent,
    HeaderBarComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    TableReorderableComponent,
    TreeTableReorderableComponent,
    UserDetailsDialogComponent,
    StepAttributeTimeSpanComponent,
    StepAttributeHeightComponent,
    StepAttributePoiComponent,
    StepAttributePoiAndMappingComponent,
    StepAttributePreStorageLocationComponent,
    StepAttributeAkzComponent,
    NotauthorizedComponent,
    LogoutComponent,
    NoPermissionComponent,
    RegisterUserComponent,
    WorkingAreaNotFoundComponent,
    NotificationPanelComponent,
    NotificationPanelContainerComponent,
    BoxLayoutComponent,
    BoxFooterComponent,
    ActionMenuComponent,
    BaseToolBarComponent,
    VehicleChangeWorkingAreaModalComponent,
    OrganizationChartComponent,
    OrganizationChartTableComponent,
    InfoPanelComponent,
    PoiCellComponent,
    SwitchNotificationCellComponent,
    CopyTextCellComponent,
    ActionMenuCellComponent,
    RowGroupCellComponent,
    SwitchSettingCellComponent,
    TrafficLightCellComponent,
    VehicleListCellTemplateComponent,
    ProcessConfiguratorListCellTemplateComponent,
    SoftwareVersionStatusCellComponent,
    RecipientKeyCellComponent,
    OverlappingZonesModalComponent,
    AgGridDateTimeFilterComponent,
    AgGridSingleDateTimeFilterComponent,
    AgGridcustomSetFilterComponent,
    AgGridCellTemplateFieldSelectorDirective,
    AgGridExpandCollapseAllDirective,
    AgGridCustomDropdownEditorComponent,
    CountdownTimerComponent,
    OrganizationChartTableCellComponent,
    PoiMatrixCalculationCellComponent,
    AgGridValidatedCellComponent,
    EvacuationModeModalComponent,
    StepAttributeNodeComponent,
    VehicleTypeFilterComponent,
    VehicleTypeSelectComponent,
    MissionListVehicleStatusComponent,
    MissionListMissionStatusComponent,
    IsUnitLoadPipe,
    IsTuggerTrainPipe,
    IsForkliftPipe,
    IsNoEmulatorPipe,
    IsDSTuggertrainPipe,
    Is4AmTuggerTrainPipe,
    IsAgiloxForkliftPipe,
    IsManualVehicle,
    VehicleErrorPipe,
    VehicleTrafficLightStatusPipe,
    VehicleMapDataTrafficLightStatusPipe,
    VehicleStatusTonePipe,
    VehicleEnableMapStatusPipe,
    AllowLoadedBrakeTestPipe,
  ],
  imports: [LibraryModule, PersistTableFilterAndColumnStateDirective],
  exports: [
    LibraryModule,
    VehicleActionsComponent,
    VehicleMapsActionDropdownComponent,
    VehicleLocalizeComponent,
    VehicleSendStepsComponent,
    VehicleSendZoneSetComponent,
    VehicleMultiselectComponent,
    VehicleSoftwareUpdateMultiselectComponent,
    NotFoundDetailsComponent,
    StepAttributeComponent,
    StepTypeSelectionComponent,
    AssignUserRoleModalComponent,
    VehicleStateIconsComponent,
    MissionActionButtonsComponent,
    MissionTraceStepDetailsComponent,
    ZoneVersionComponent,
    MissionTraceStepDetailsComponent,
    MissionTraceStepDetailsContainerComponent,
    HeaderBarComponent,
    TableReorderableComponent,
    TreeTableReorderableComponent,
    UserDetailsDialogComponent,
    BreadcrumbBarComponent,
    DetailsPanelComponent,
    EditBarComponent,
    EditToggleComponent,
    ToolBarComponent,
    ButtonToolBarComponent,
    AtsCopilotComponent,
    ChatCardComponent,
    ChatbotIconComponent,
    AgGridPageLayoutComponent,
    BasePageLayoutComponent,
    PageHeaderComponent,
    PageNotFoundComponent,
    ColorPickerComponent,
    SidepanelListLayoutComponent,
    SidemenuContentLayoutComponent,
    PlainLayoutComponent,
    TabsContentLayoutComponent,
    TabsContentNoscrollLayoutComponent,
    BoxLayoutComponent,
    NotauthorizedComponent,
    LogoutComponent,
    NoPermissionComponent,
    RegisterUserComponent,
    WorkingAreaNotFoundComponent,
    NotificationPanelContainerComponent,
    NotificationPanelComponent,
    ActionMenuComponent,
    VehicleChangeWorkingAreaModalComponent,
    OrganizationChartComponent,
    OrganizationChartTableComponent,
    MissionErrorHandlingModalComponent,
    InfoPanelComponent,
    OverlappingZonesModalComponent,
    VehicleSoftwareStatusComponent,
    CountdownTimerComponent,
    AgGridDateTimeFilterComponent,
    AgGridSingleDateTimeFilterComponent,
    AgGridcustomSetFilterComponent,
    AgGridCellTemplateFieldSelectorDirective,
    AgGridExpandCollapseAllDirective,
    AgGridValidatedCellComponent,
    AgGridCustomDropdownEditorComponent,
    EvacuationModeModalComponent,
    StepAttributeNodeComponent,
    VehicleTypeFilterComponent,
    VehicleTypeSelectComponent,
    PersistTableFilterAndColumnStateDirective,
    IsUnitLoadPipe,
    IsTuggerTrainPipe,
    IsForkliftPipe,
    IsNoEmulatorPipe,
    IsDSTuggertrainPipe,
    Is4AmTuggerTrainPipe,
    IsAgiloxForkliftPipe,
    IsManualVehicle,
    VehicleErrorPipe,
    VehicleTrafficLightStatusPipe,
    VehicleMapDataTrafficLightStatusPipe,
    VehicleEnableMapStatusPipe,
    VehicleStatusTonePipe,
    AllowLoadedBrakeTestPipe,
  ],
  providers: [
    MapNavigationHelperService,
    MissionErrorHandlingDialogService,
    HeaderNavbarIntegrationService,
  ],
})
export class SharedModule {
  constructor(translator: AtsTranslationService) {
    translator.loadTranslations(translations_en, translations_de);
  }
}
