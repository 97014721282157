/* eslint-disable @typescript-eslint/no-magic-numbers */

import { DATE_FORMAT } from 'core/constants';
import { Zone } from 'core/models';
import { Point } from 'pixi.js';
import { checkEdgeCollision, formatDate } from 'shared/helpers';
import { ZoneMapItem, ZoneOrder } from '../layers';

export function isOverlapping(zone: ZoneMapItem, list: ZoneMapItem[]): boolean {
  if (zone.points.length === 0 || list.length === 0) return false;

  const polygon = zone.getPolygon();
  const bounds = zone.getBounds();

  const fnEdge = (compareZone: ZoneMapItem): boolean =>
    checkEdgeCollision(zone.getEdges(), compareZone.getEdges());

  const fnInside = (compareZone: ZoneMapItem): boolean =>
    compareZone.getPolygon().contains(zone.points[0].x, zone.points[0].y);

  const fnCover = (compareZone: ZoneMapItem): boolean => {
    const point = compareZone.getShape()[0];
    return polygon.contains(point.x, point.y);
  };

  const fnList = [fnEdge, fnInside, fnCover];

  for (const compareZone of list) {
    if (!compareZone || !bounds.intersects(compareZone.getBounds())) continue;

    for (const fn of fnList) {
      if (fn(compareZone)) return true;
    }
  }

  return false;
}

export function sortZonesByLayerOrder(): (a: Zone | undefined, b: Zone | undefined) => number {
  return (a, b) => (ZoneOrder[a?.zoneType ?? 0] > ZoneOrder[b?.zoneType ?? 0] ? 1 : -1);
}

export function getNewZoneSetName(fullDate: Date): string {
  const date = formatDate(fullDate, DATE_FORMAT.DATE);
  const time = formatDate(fullDate, DATE_FORMAT.TIME);
  const zoneName = date
    ?.toString()
    .concat(' ')
    .concat(time ? time.toString() : '');
  return zoneName ?? '';
}

export function formatZone(zone: Zone): Zone {
  if (zone.polygon[0] instanceof Point) {
    return zone;
  }

  return {
    ...zone,
    polygon: zone.polygon.map(p => new Point(p.x, p.y)),
  };
}
