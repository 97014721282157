import { OpcuaDeviceTrafficLightStatus, PoiDeviceOccupancy } from 'core/models';

export const opcuaDeviceTrafficLightStatus = {
  [OpcuaDeviceTrafficLightStatus.Connected]: '#55C474',
  [OpcuaDeviceTrafficLightStatus.NotInitialized]: '#8C8E91',
  [OpcuaDeviceTrafficLightStatus.LostConnection]: '#FF454A',
};

export const deviceOccupancy = {
  [PoiDeviceOccupancy.Unknown]: '#C1C5CB',
  [PoiDeviceOccupancy.Loaded]: '#008FF0',
  [PoiDeviceOccupancy.Empty]: '#C1C5CB',
};
