/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable max-lines */
import '@pixi/graphics-extras';
import {
  MapVehicle,
  PathEdgeDto,
  TrafficManagerFeatures,
  VehicleAwarenessDto,
  VehicleConflictAreaDimensions,
} from 'core/dtos';
import { delay } from 'core/helpers';
import {
  LoadType,
  MapItemType,
  ReducedVehicle,
  TrafficManagementFilter,
  Vector2D,
  VehicleFilter,
  VehicleFilterType,
  VehicleStatus,
} from 'core/models';
import { millisecondsInSecond } from 'date-fns/constants';
import { DevColors, VehicleColors, VehiclePathColors } from 'library/styles';
import { LineArrowOptions, LineOptions, MapPixiHelper } from 'modules/maps/helpers';
import {
  Circle,
  ColorSource,
  Container,
  Graphics,
  IPointData,
  LINE_CAP,
  LINE_JOIN,
  Sprite,
} from 'pixi.js';
import { difference, dotProduct, elapsed, isManualVehicle } from 'shared/helpers';
import { LayerGraphicHelper } from '../../helpers';
import { MapItemBase } from '../../map-item-container';
import { MoveMapItemContainer } from '../../map-item-move-container';
import { MapLayerView } from '../../map-layer-view';
import { MapScale } from '../../map-layer.constant';
import { ContainerEx, GraphicsEx } from '../../pixi';
import { MapLabelGraphic } from '../../shared';
import { MapPulseGraphic } from '../../shared/map-pulse.graphic';
import { VehicleDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import {
  ConflictAreaStyle,
  IconStyle,
  VehicleDisconnect,
  VehicleErrorStyle,
  VehiclePathStyle,
  VehicleSelection,
  VehicleStyle,
} from '../vehicle-layer.constant';

export abstract class VehicleMapItem implements MapItemBase {
  container!: MoveMapItemContainer;
  protected dimensions!: VehicleDimensions;
  protected conflictArea: VehicleConflictAreaDimensions | undefined;

  protected baseBackground!: Graphics | Sprite;
  protected pathContainer!: ContainerEx;
  protected tmAreasContainer!: ContainerEx;
  protected pathSegmentsContainer!: Container;

  protected vehicleBase!: Graphics | Sprite;
  protected arrowIcon!: Sprite;
  protected initializingIcon: Sprite | undefined;
  protected disconnectedIcon: Sprite | undefined;
  protected pauseIcon!: Sprite;

  private selection: Graphics | undefined;
  private readonly pulse: MapPulseGraphic = new MapPulseGraphic({
    color: VehicleErrorStyle.Color,
    alpha: VehicleErrorStyle.Opacity,
    size: VehicleErrorStyle.Size,
  });

  protected label: MapLabelGraphic | undefined;
  protected loaded!: Graphics;

  private path!: GraphicsEx;
  protected collisionArea!: Graphics;
  protected intersectionZonePath!: Graphics;
  protected stoppingArea!: Graphics;
  protected deadlockArea!: Graphics;
  private stoppingAwarenessZone!: Graphics;
  protected mapOrientation = 0;
  protected isSelected = false;

  private vehicleStoppedByTM = false;
  protected canDrawZonePath = false;
  private xLabelOffset = 0;
  private readonly showPivotPoint = false;

  getVehicle(): MapVehicle {
    return this.vehicle;
  }

  getPosition(): IPointData {
    return this.container.position;
  }

  constructor(
    protected readonly view: MapLayerView,
    protected vehicle: MapVehicle,
    protected settings: TrafficManagerFeatures,
    protected vehicleFilter?: VehicleFilter,
    protected trafficManagementFilter?: TrafficManagementFilter,
    protected isTrafficAnalysis = false,
    protected isVehicleSmoothingEnabled?: boolean
  ) {
    this.create(vehicle);
  }

  // #region Create
  protected abstract createVehicleBase(): Graphics | Sprite;
  protected abstract createLoad(vehicle: MapVehicle): Graphics;
  protected abstract getDimensions(vehicle: MapVehicle): VehicleDimensions;
  protected abstract setup(vehicle: MapVehicle, itemContainer: ContainerEx): void;

  protected setDimensions(vehicle: MapVehicle): void {
    this.dimensions = this.getDimensions(vehicle);
  }

  private create(vehicle: MapVehicle): void {
    this.setDimensions(vehicle);

    this.baseBackground = this.createVehicleBase();

    const itemContainer = this.view.addChild(new ContainerEx());
    this.pathContainer = itemContainer.addChild(new ContainerEx());
    this.tmAreasContainer = itemContainer.addChild(new ContainerEx());
    this.pathSegmentsContainer = itemContainer.addChild(new Container());

    this.container = itemContainer.addChild(
      new MoveMapItemContainer(vehicle.id.toString(), MapItemType.Vehicle)
    );
    this.container.scale.set(MapScale.MeterToCm, -MapScale.MeterToCm);
    this.container.interactive = true;
    this.container.buttonMode = true;
    this.container.smoothMovement = this.isVehicleSmoothingEnabled ?? true;

    this.createVehiclePathContainer();
    this.createVehicleTmAreasContainer();
    this.createVehicleContainer(vehicle);

    this.setup(vehicle, itemContainer);
    if (this.showPivotPoint) {
      this.container.addChildOnTop(
        new Graphics()
          .beginFill(DevColors.PivotPointRed)
          .drawCircle(this.container.pivot.x, this.container.pivot.y, 3)
          .endFill()
      );
    }
  }

  private createVehiclePathContainer(): void {
    this.pathContainer.addChild((this.path = new GraphicsEx()));
  }

  private createVehicleTmAreasContainer(): void {
    this.tmAreasContainer.addChild(
      (this.collisionArea = new GraphicsEx()),
      (this.stoppingArea = new GraphicsEx()),
      (this.deadlockArea = new GraphicsEx()),
      (this.stoppingAwarenessZone = new GraphicsEx()),
      (this.intersectionZonePath = new GraphicsEx())
    );
  }

  private createVehicleContainer(vehicle: MapVehicle): void {
    this.container.addChild(
      (this.vehicleBase = this.baseBackground),
      (this.loaded = this.createLoad(vehicle)),
      (this.arrowIcon = this.createArrowIcon(vehicle)),
      (this.pauseIcon = this.createPauseIcon())
    );

    this.setIcons(vehicle);
    this.createLabel(vehicle);
  }

  // #endregion

  // #region Update

  update(vehicle: MapVehicle, isTrafficAnalysis = false): void {
    this.isTrafficAnalysis = isTrafficAnalysis;
    this.setVisibility(this.vehicleFilter);

    this.container.moveTo(vehicle.pose2D);
    this.setOrientation(vehicle.pose2D.orientation);

    this.updateLoadType(vehicle);
    this.setIcons(vehicle);
    this.updateLabel(vehicle);

    this.createPaths(vehicle);
    this.createPathSegments(vehicle);

    this.setVehicleStatus(vehicle, isTrafficAnalysis);
    this.toggleErrorPulse(vehicle);

    if (isTrafficAnalysis && vehicle.vehicleConflictAreaDimensions) {
      this.updateVehicleConflictAreaDimensions(vehicle.vehicleConflictAreaDimensions);
    }

    this.vehicle = vehicle; // Only set this here so we can compare changes
  }

  protected updateLoadType(vehicle: MapVehicle): void {
    if (this.vehicle.loadType !== vehicle.loadType) {
      this.vehicle.loadType = vehicle.loadType;
      this.setDimensions(vehicle);
      this.updateLoad(vehicle);
    }
  }

  updateVehicleAwareness(vehicleAwareness: VehicleAwarenessDto): void {
    this.awarenessControl(vehicleAwareness);
  }
  // #endregion

  // #region Label

  private createLabel(vehicle: MapVehicle): void {
    const label = LayerGraphicHelper.createLabel(vehicle.name);

    this.xLabelOffset = this.dimensions.length / 2 - this.dimensions.origin;
    label.pivot.set(0, this.calculateLabelOffset(vehicle.pose2D.orientation, label.height));
    label.position.x = this.xLabelOffset;

    this.container.addChild(label);
    this.label = label;
  }

  protected updateLabel(vehicle: MapVehicle): void {
    if (vehicle.name !== this.vehicle.name) this.changeName(vehicle.name);
  }

  changeName(name: string): void {
    if (!this.label || !name) return;

    this.label.updateLabelText(name);
  }
  // #endregion

  // #region Position & Rotation
  protected abstract calculateLabelOffset(
    orientation: number,
    xPositionOffset: number,
    textHeight?: number
  ): number;

  private setLabelOrientation(relativeOrientation: number): void {
    if (this.label) {
      this.label.pivot.set(
        0,
        this.calculateLabelOffset(relativeOrientation, this.xLabelOffset, this.label.height)
      );
      this.label.rotation = relativeOrientation;
    }
  }

  protected setOtherOrientation(_relativeOrientation: number): void {}

  setOrientation(orientation: number): void {
    const relativeOrientation = this.mapOrientation + orientation;

    this.container.rotation = orientation;
    this.pauseIcon.rotation = relativeOrientation;

    if (this.disconnectedIcon) this.disconnectedIcon.rotation = relativeOrientation;

    if (this.loaded && this.vehicle.load?.orientation)
      this.loaded.rotation = this.mapOrientation + this.vehicle.load.orientation;

    this.setLabelOrientation(relativeOrientation);
    this.setOtherOrientation(relativeOrientation);
  }

  setPosition(point: IPointData): void {
    this.container.position.copyFrom(point);
  }

  onMapRotation(mapRotation: number): void {
    this.mapOrientation = mapRotation;

    this.setOrientation(this.vehicle.pose2D.orientation);
  }
  // #endregion

  // #region Colors
  protected setVehicleStatus(vehicle: MapVehicle, isTrafficAnalysis = false): void {
    if (this.vehicleBase instanceof Sprite) return;

    this.vehicleBase.tint = this.getVehicleBackgroundColor(vehicle, isTrafficAnalysis);
    this.arrowIcon.tint = this.getArrowColor(vehicle);

    if (this.initializingIcon) {
      this.initializingIcon.tint = this.getInitializingColor(this.arrowIcon.tint);
    }
  }

  private getVehicleBackgroundColor(
    { isConnected, isSwitchedOff, hasError, maintenanceModeEnabled }: MapVehicle,
    isTrafficAnalysis = false
  ): number {
    if (isSwitchedOff) {
      return VehicleColors.SwitchedOff;
    } else if (maintenanceModeEnabled) {
      return VehicleColors.Maintenance;
    } else if (!isTrafficAnalysis && (hasError || !isConnected)) {
      return VehicleColors.Error;
    }

    return this.getDefaultVehicleColor();
  }

  protected getDefaultVehicleColor(): number {
    return VehicleColors.Default;
  }

  protected getArrowColor(vehicle: MapVehicle): number {
    const isWhite =
      vehicle.isLoaded ||
      vehicle.maintenanceModeEnabled ||
      vehicle.hasError ||
      !vehicle.isConnected;

    return !vehicle.isSwitchedOff && isWhite ? VehicleColors.Base : VehicleColors.ArrowGrey;
  }

  protected getInitializingColor(arrowColor: number | ColorSource): number {
    return arrowColor === VehicleColors.ArrowGrey ? VehicleColors.Base : VehicleColors.ArrowGrey;
  }

  // #endregion

  // #region Arrow & Icons
  private setIcons(vehicle: MapVehicle): void {
    this.showLoaded(vehicle);
    this.showPaused(vehicle);

    this.setInitializingIcon(vehicle);
    this.setDisconnectedIcon(vehicle);
  }

  protected createArrowIcon(vehicle: MapVehicle): Sprite {
    const icon = LayerGraphicHelper.createIcon(VehicleTextures.vehicleArrow, IconStyle);
    LayerGraphicHelper.positionToItemCenter(icon, this.dimensions);
    icon.tint = this.getArrowColor(vehicle);

    return icon;
  }

  private setInitializingIcon(vehicle: MapVehicle): void {
    if (vehicle.status === VehicleStatus.NotInitialized && !this.initializingIcon) {
      this.initializingIcon = this.createInitializingIcon();
    }

    if (this.initializingIcon && vehicle.status !== VehicleStatus.NotInitialized) {
      this.initializingIcon?.destroy();
      this.initializingIcon = undefined;
    }
  }

  protected createInitializingIcon(): Sprite {
    const icon = LayerGraphicHelper.createIcon(VehicleTextures.initializeIcon, IconStyle);
    icon.tint = this.getInitializingColor(this.arrowIcon.tint);

    icon.position.set(
      (this.arrowIcon.width / 2 - VehicleStyle.InitializeIconOffset) * IconStyle.scale,
      (this.arrowIcon.height / 2) * IconStyle.scale
    );

    this.arrowIcon.addChild(icon);
    return icon;
  }

  private isDisconnected(vehicle: MapVehicle | ReducedVehicle): boolean {
    return !vehicle.isConnected && !vehicle.isSwitchedOff && !vehicle.maintenanceModeEnabled;
  }

  private setDisconnectedIcon(vehicle: MapVehicle): void {
    if (this.isDisconnected(vehicle)) {
      if (!this.disconnectedIcon) {
        this.disconnectedIcon = this.createDisconnectIcon(vehicle);
      }
      this.disconnectedIcon.visible = true;
      this.arrowIcon.visible = false;
    } else if (this.disconnectedIcon) {
      this.container.removeChild(this.disconnectedIcon);
      this.disconnectedIcon.destroy();
      this.disconnectedIcon = undefined;

      this.arrowIcon.visible = true;
    }
  }

  protected createDisconnectIcon(vehicle: MapVehicle): Sprite {
    const icon = LayerGraphicHelper.createIcon(VehicleTextures.disconnectIcon, IconStyle);
    LayerGraphicHelper.positionToItemCenter(icon, this.dimensions);
    icon.rotation = this.mapOrientation + vehicle.pose2D.orientation;

    this.container.addChild(icon);
    return icon;
  }
  // #endregion

  // #region Loaded
  private showLoaded(vehicle: MapVehicle): void {
    this.loaded.visible = vehicle.isLoaded ?? false;
  }

  changeLoadType(loadType: LoadType): void {
    if (loadType) {
      this.updateLoadType({ ...this.vehicle, loadType: loadType });
    }
  }

  updateLoad(vehicle: MapVehicle): void {
    this.loaded.destroy();
    this.loaded = this.createLoad(vehicle);

    this.container.addChildOnTop(this.loaded);
    this.container.setChildOnTop(this.arrowIcon);
    this.showLoaded(vehicle);
  }
  // #endregion

  // #region Path
  protected createPaths(vehicle: MapVehicle): void {
    const { path } = vehicle;

    if (!this.pathContainer.visible || !path || path.length < 2) {
      if (this.path.hasPoints) this.path.clear();

      return;
    }

    const options: LineOptions = {
      size: this.isSelected ? VehiclePathStyle.SelectedSize : VehiclePathStyle.Size,
      color: this.getPathColor(vehicle),
      alpha: VehiclePathStyle.Opacity,
    };

    const arrowOptions: LineArrowOptions = {
      ...options,
      size: this.isSelected ? VehiclePathStyle.SelectedArrowSize : VehiclePathStyle.ArrowSize,
    };

    MapPixiHelper.drawPathWithDirectionArrow(
      this.path,
      options,
      arrowOptions,
      vehicle.pose2D,
      path
    );
  }

  private getPathColor(vehicle: MapVehicle): number {
    return this.isSelected
      ? VehiclePathColors.Selected
      : vehicle.isPaused
      ? VehiclePathColors.Stopped
      : VehiclePathColors.Default;
  }

  updatePathColor(isPaused: boolean = this.vehicleStoppedByTM): void {
    this.vehicleStoppedByTM = isPaused;

    this.path.changeColor(this.getPathColor(this.vehicle));
  }

  private createPathSegments(vehicle: MapVehicle): void {
    const { path } = vehicle;

    this.pathSegmentsContainer.removeChildren();
    this.pathSegmentsContainer.visible = (path || []).length > 1;
    if (!path || !this.vehicleFilter?.type[vehicle.vehicleType].pathSegments) {
      return;
    }

    path.forEach(p => {
      this.drawPathSegmentsLine(p);
      this.drawPathSegmentsArrow(p);
    });
  }

  private drawPathSegmentsLine(path: Vector2D): void {
    const xPointLineTo = -0.2;
    const drawLine = new Graphics();
    drawLine.x = path.x;
    drawLine.y = path.y;

    drawLine
      .moveTo(path.x, path.y)
      .lineTo(xPointLineTo, 0)
      .lineStyle(VehiclePathStyle.Size, VehiclePathColors.PathSegments)
      .lineTo(0, 0);

    drawLine.rotation = path.theta ?? 0;
    this.pathSegmentsContainer.addChild(drawLine);
  }

  private drawPathSegmentsArrow(path: Vector2D): void {
    const polygonPoints = [0, 0.1, 0.3, 0, 0, -0.1];

    const drawPolygon = new Graphics();
    drawPolygon.x = path.x;
    drawPolygon.y = path.y;
    drawPolygon.beginFill(VehiclePathColors.PathSegments).drawPolygon(polygonPoints).endFill();
    drawPolygon.rotation = path.theta ?? 0;

    this.pathSegmentsContainer.addChild(drawPolygon);
  }

  // #endregion

  // #region Error
  private toggleErrorPulse(vehicle: ReducedVehicle | MapVehicle | undefined): void {
    if (this.isSelected || !vehicle) {
      this.pulse.stop();
      return;
    }

    if (vehicle) {
      if (
        vehicle.hasError &&
        vehicle.isConnected &&
        !vehicle.maintenanceModeEnabled &&
        !vehicle.isSwitchedOff
      ) {
        this.createErrorPulse();
      } else if (this.isDisconnected(vehicle)) {
        this.scheduleDisconnectedPulse(vehicle);
      } else {
        this.pulse.stop();
      }
    }
  }

  private scheduleDisconnectedPulse(vehicle: ReducedVehicle | MapVehicle): void {
    if (this.pulse.isRunning) return;

    if (elapsed(vehicle.lastStateMessageProcessedUtc) >= VehicleDisconnect.PulseDelay)
      this.createErrorPulse();
    else void this.createDelayedErrorPulse(vehicle);
  }

  private createErrorPulse(): void {
    if (this.pulse.isRunning) return;

    const graphic = this.pulse.start();

    LayerGraphicHelper.positionToItemCenter(graphic, this.dimensions);

    this.container.addChild(graphic);
  }

  private async createDelayedErrorPulse(vehicle: ReducedVehicle | MapVehicle) {
    if (this.pulse.isRunning) return;

    const time =
      (VehicleDisconnect.PulseDelay - elapsed(vehicle.lastStateMessageProcessedUtc)) *
      millisecondsInSecond;

    if (time > 0) {
      await delay(time);
      this.toggleErrorPulse(vehicle);
    }

    this.toggleErrorPulse(vehicle);
  }
  // #endregion

  // #region Selection
  toggleSelection(
    isSelected = false,
    isVehicleBusy = false,
    vehicle?: ReducedVehicle | MapVehicle | undefined
  ): void {
    this.isSelected = isSelected;

    if (isSelected) {
      if (isVehicleBusy) this.pathContainer.visible = true;
      this.createSelectionGraphic();
      this.createPaths(this.vehicle);
    } else {
      this.selection?.destroy();
      this.selection = undefined;
    }

    this.toggleErrorPulse(vehicle);

    if (isSelected) {
      this.moveToFront();
    }
  }

  private createSelectionGraphic(): void {
    const graphic = new Graphics()
      .lineStyle(VehicleSelection.Border, VehicleSelection.Color)
      .beginFill(VehicleSelection.Color, VehicleSelection.Opacity)
      .drawCircle(0, 0, VehicleSelection.Size)
      .endFill();

    graphic.visible = true;
    graphic.hitArea = new Circle(0, 0, 0);

    LayerGraphicHelper.positionToItemCenter(graphic, this.dimensions);

    this.selection = graphic;
    this.container.addChild(graphic);
  }
  // #endregion

  // #region Paused

  private createPauseIcon(): Sprite {
    const icon = LayerGraphicHelper.createIcon(VehicleTextures.pauseIcon, IconStyle);

    LayerGraphicHelper.positionOffsetFromItemCenter(
      icon,
      this.dimensions,
      VehicleStyle.PauseIconOffset,
      0
    );

    return icon;
  }

  private showPaused(vehicle: MapVehicle): void {
    this.pauseIcon.visible = vehicle.isPaused ?? false;
  }

  // #endregion

  // #region Conflict Area
  createVehicleConflictArea(area: Graphics, color: number, pathEdges: PathEdgeDto[]): void {
    area.visible = pathEdges && pathEdges.length > 0 ? true : false;

    if (!area.visible) {
      return;
    }

    if (pathEdges) {
      const lineTextureStyle = {
        cap: LINE_CAP.ROUND,
        join: LINE_JOIN.ROUND,
        color: color,
        width: pathEdges[0].width,
        miterLimit: 100,
      };

      area.clear().lineTextureStyle(lineTextureStyle);

      for (const edge of pathEdges) {
        area.lineTextureStyle({ ...lineTextureStyle, width: edge.width * 2 });
        area.moveTo(edge.lineSegment.p0.x, edge.lineSegment.p0.y);
        area.lineTo(edge.lineSegment.p1.x, edge.lineSegment.p1.y);
      }
      area.filters = [ConflictAreaStyle.OpacityFilter];
    }
  }

  isSimilarDirection(segmentA: [Vector2D, Vector2D], segmentB: [Vector2D, Vector2D]): boolean {
    const a = difference(...segmentA);
    const b = difference(...segmentB);

    return dotProduct(a, b) > 0;
  }

  pairwise<T>(arr: T[]): [T, T][] {
    const result = new Array<[T, T]>(arr.length - 1);
    for (let i = 0; i < arr.length - 1; i++) {
      result[i] = [arr[i], arr[i + 1]];
    }
    return result;
  }

  private awarenessControl(vehicle: VehicleAwarenessDto): void {
    const stoppingArea = vehicle.stoppingAreaPathEdges;
    const stoppingZoneColor = vehicle.isAwarenessPaused
      ? ConflictAreaStyle.AwarenessColor
      : ConflictAreaStyle.ConflictAreaColor;

    this.stoppingAwarenessZone.visible = true;

    if (this.stoppingAwarenessZone.visible && stoppingArea) {
      this.createVehicleConflictArea(this.stoppingAwarenessZone, stoppingZoneColor, stoppingArea);
    }
  }

  updateVehicleConflictAreaDimensions(
    vehicleConflictAreaDimensions: VehicleConflictAreaDimensions
  ): void {
    if (
      !this.conflictArea?.lookAheadArea &&
      !this.conflictArea?.stoppingArea &&
      ((vehicleConflictAreaDimensions.lookAheadArea &&
        vehicleConflictAreaDimensions.lookAheadArea.length > 0) ||
        (vehicleConflictAreaDimensions.stoppingArea &&
          vehicleConflictAreaDimensions.stoppingArea.length > 0))
    ) {
      this.conflictArea = vehicleConflictAreaDimensions;
      this.setTrafficManagementVisibility(this.trafficManagementFilter);
    }

    this.conflictArea = vehicleConflictAreaDimensions;
    const { lookAheadArea, stoppingArea, deadlockArea } = vehicleConflictAreaDimensions;

    if (this.canDrawZonePath) {
      this.collisionArea.clear();
      this.deadlockArea.clear();
      this.stoppingArea.clear();
      return;
    }

    this.handleTrafficManagementAreas(
      lookAheadArea,
      this.collisionArea,
      ConflictAreaStyle.ConflictAreaColor,
      this.trafficManagementFilter?.collisionArea
    );

    this.handleTrafficManagementAreas(
      stoppingArea,
      this.stoppingArea,
      ConflictAreaStyle.ConflictAreaColor,
      this.trafficManagementFilter?.stoppingArea
    );

    this.handleTrafficManagementAreas(
      deadlockArea,
      this.deadlockArea,
      ConflictAreaStyle.DeadLockColor,
      this.trafficManagementFilter?.deadlockArea
    );
  }

  updateVehicleIntersectionZoneConflictAreaDimensions(pathEdges: PathEdgeDto[]): void {
    if (!this.canDrawZonePath && pathEdges.length > 0) {
      this.canDrawZonePath = true;
      this.setTrafficManagementVisibility(this.trafficManagementFilter);
    }
    this.canDrawZonePath = pathEdges.length > 0;

    this.createVehicleConflictArea(
      this.intersectionZonePath,
      ConflictAreaStyle.IntersectionZoneColor,
      pathEdges
    );

    if (this.conflictArea) {
      this.updateVehicleConflictAreaDimensions(this.conflictArea);
    }
  }

  protected handleTrafficManagementAreas(
    pathEdges: PathEdgeDto[] | null,
    area: Graphics,
    areaColor: number,
    filter?: boolean
  ): void {
    if (this.tmAreasContainer.visible && filter && pathEdges) {
      this.createVehicleConflictArea(area, areaColor, pathEdges);
    } else if (area) {
      area.clear();
    }
  }
  // #endregion

  // #region Layer Management
  remove(): void {
    this.pathContainer.removeChildren();
    this.tmAreasContainer.removeChildren();
    this.pathSegmentsContainer.removeChildren();

    this.container.removeChildren();
    this.container.parent.removeChildren();
  }

  moveToFront(): void {
    this.container.parent.setChildIndex(this.container, this.container.parent.children.length - 1);
  }
  // #endregion

  // #region Filter
  private isVehicleVisible(
    filter: VehicleFilter,
    type: VehicleFilterType,
    status: VehicleStatus
  ): boolean {
    return (
      ((type.name || type.path) &&
        !!filter.status.find(x => x.id === status)?.selected &&
        (this.vehicle.isLoaded ? filter.load[1].selected : filter.load[0].selected) &&
        (this.vehicle.maintenanceModeEnabled
          ? filter.maintenance[1].selected
          : filter.maintenance[0].selected) &&
        !!filter.mode.find(x => x.id === this.vehicle.mode)?.selected &&
        this.isVehicleSearched(filter)) ||
      this.isSelected
    );
  }

  private isVehicleSearched(filter: VehicleFilter): boolean {
    return this.vehicle.name.toLocaleLowerCase().includes(filter.search.toLocaleLowerCase());
  }

  setVisibility(filter?: VehicleFilter): void {
    if (!filter) {
      return;
    }

    this.vehicleFilter = filter;

    const type = filter.type
      ? filter.type[this.vehicle.vehicleType]
      : { name: true, path: true, pathSegments: true };

    if (this.container.parent)
      this.container.parent.visible = this.isVehicleVisible(filter, type, this.vehicle.status);

    if (this.label) this.label.visible = this.isVehicleNameVisible(filter, type);

    this.pathContainer.visible = this.container.visible && type.path;

    this.pathSegmentsContainer.visible = type.pathSegments;

    this.setTrafficManagementVisibility(this.trafficManagementFilter);
  }

  isVehicleNameVisible(filter: VehicleFilter, type: VehicleFilterType): boolean {
    const isManualVehicleValue = isManualVehicle(this.vehicle);

    if (type.name && !isManualVehicleValue) {
      return type.name;
    } else if (isManualVehicleValue) {
      return filter.manual[0].selected;
    }
    return false;
  }
  // #endregion

  // #region Traffic Management
  setTrafficManagementVisibility(filter?: TrafficManagementFilter): void {
    if (!filter) {
      return;
    }

    this.tmAreasContainer.visible =
      (this.canDrawZonePath ||
        !!this.conflictArea?.lookAheadArea?.length ||
        !!this.conflictArea?.stoppingArea?.length) &&
      this.container.visible;

    this.trafficManagementFilter = filter;
    this.stoppingArea.visible = filter.stoppingArea && this.stoppingArea.visible;
    this.collisionArea.visible = filter.collisionArea && this.collisionArea.visible;

    if (this.vehicleStoppedByTM) {
      this.container.visible = filter.vehicleStoppedByTM;
    }
  }

  setTrafficManagement(settings: TrafficManagerFeatures): void {
    this.settings = settings;
    if (this.conflictArea) {
      this.handleTrafficManagementAreas(
        this.conflictArea.lookAheadArea,
        this.collisionArea,
        ConflictAreaStyle.ConflictAreaColor,
        this.trafficManagementFilter?.collisionArea
      );
      this.handleTrafficManagementAreas(
        this.conflictArea.stoppingArea,
        this.stoppingArea,
        ConflictAreaStyle.ConflictAreaColor,
        this.trafficManagementFilter?.stoppingArea
      );
      this.handleTrafficManagementAreas(
        this.conflictArea.deadlockArea,
        this.deadlockArea,
        ConflictAreaStyle.DeadLockColor,
        this.trafficManagementFilter?.deadlockArea
      );
    }
  }
  // #endregion
}
