import { SoftwareUpdateVersionStatus, VehicleStatus, VehicleTrafficLightStatus } from 'core/models';

export const vehicleStatus = {
  [VehicleStatus.SwitchedOff]: 'var(--ds-color-grey-300)',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [VehicleStatus.Error]: 'var(--ds-color-red-500)',
  [VehicleStatus.Busy]: 'var(--ds-color-ocean-500)',
  [VehicleStatus.Idle]: 'var(--ds-color-green-400)',
  [VehicleStatus.Charging]: 'var(--ds-color-yellow-300)',
  [VehicleStatus.Paused]: 'var(--ds-color-gamboge-400)',
  [VehicleStatus.NotInitialized]: 'var(--ds-color-black)',
};

export const vehicleTrafficLightStatus = {
  [VehicleTrafficLightStatus.Connected]: 'var(--ds-color-green-400)',
  [VehicleTrafficLightStatus.ConnectedWithError]: 'var(--ds-color-red-500)',
  [VehicleTrafficLightStatus.SwitchedOff]: 'var(--ds-color-grey-500)',
  [VehicleTrafficLightStatus.NotInitialized]: 'var(--ds-color-grey-500)',
  [VehicleTrafficLightStatus.LostConnection]: 'var(--ds-color-red-500)',
};

export const maintenanceModeColor = 'var(--ds-color-heliotrope-600)';

export const softwareUpdateVersionStatus = {
  [SoftwareUpdateVersionStatus.Unknown]: 'critical',
  [SoftwareUpdateVersionStatus.Outdated]: 'caution',
  [SoftwareUpdateVersionStatus.Approved]: 'positive',
  [SoftwareUpdateVersionStatus.Test]: 'info',
};
