export interface ZoneManagementSettings {
  settings: ZoneManagementSettingsDto;
}
export interface ZoneManagementSettingsDto {
  // General
  maximumDistanceToIntersectionZoneMeters: number;
  isAntiBlockingStaleDistanceToZoneRuleEnabled: boolean;
  antiBlockingStaleDistanceToZoneRuleSeconds: number;
  maximumCircularPathSplitDistanceMeters: number;
  coordinationZoneDeadlockResolutionStationarySeconds: number;

  // UnitLoad
  considerManualUnitLoadInZoneManagement: boolean;
  unitLoadFootprintBufferMeters: number;
  unitLoadLookAheadAreaWidthMeters: number;
  unitLoadLoadedExtraWidthMeters: number;
  unitLoadRearWidthMeters: number;
  unitLoadRearLengthMeters: number;
  unitLoadFrontLengthMeters: number;

  // Tugger
  considerManualTuggerTrainInZoneManagement: boolean;
  tuggerTrainFootprintBufferMeters: number;
  tuggerTrainLookAheadAreaWidthMeters: number;
  tuggerTrainLookAheadAreaLoadedExtraWidthMeters: number;
  tuggerTrainRearWidthMeters: number;
  tuggerTrainRearLengthMeters: number;
  tuggerTrainFrontLengthMeters: number;

  // Forklift
  considerManualForkliftInZoneManagement: boolean;
  forkliftFootprintBufferMeters: number;
  forkliftLookAheadAreaWidthMeters: number;
  forkliftLoadedExtraWidthMeters: number;
  forkliftRearWidthMeters: number;
  forkliftRearLengthMeters: number;
  forkliftFrontLengthMeters: number;

  // UAGV
  considerManualUagvInZoneManagement: boolean;
  uagvFootprintBufferMeters: number;
  uagvLookAheadAreaWidthMeters: number;
  uagvLoadedExtraWidthMeters: number;
  uagvRearWidthMeters: number;
  uagvRearLengthMeters: number;
  uagvFrontLengthMeters: number;
}

export function getDefaultZoneManagementFeatures(): ZoneManagementSettings {
  return {
    settings: {
      maximumDistanceToIntersectionZoneMeters: 0.0,
      isAntiBlockingStaleDistanceToZoneRuleEnabled: false,
      antiBlockingStaleDistanceToZoneRuleSeconds: 0.0,
      maximumCircularPathSplitDistanceMeters: 0.0,
      coordinationZoneDeadlockResolutionStationarySeconds: 0.0,
      considerManualUnitLoadInZoneManagement: false,
      unitLoadFootprintBufferMeters: 0.0,
      unitLoadLookAheadAreaWidthMeters: 0.0,
      unitLoadLoadedExtraWidthMeters: 0.0,
      unitLoadRearWidthMeters: 0.0,
      unitLoadRearLengthMeters: 0.0,
      unitLoadFrontLengthMeters: 0.0,
      considerManualTuggerTrainInZoneManagement: false,
      tuggerTrainFootprintBufferMeters: 0.0,
      tuggerTrainLookAheadAreaWidthMeters: 0.0,
      tuggerTrainLookAheadAreaLoadedExtraWidthMeters: 0.0,
      tuggerTrainRearWidthMeters: 0.0,
      tuggerTrainRearLengthMeters: 0.0,
      tuggerTrainFrontLengthMeters: 0.0,
      considerManualForkliftInZoneManagement: false,
      forkliftFootprintBufferMeters: 0.0,
      forkliftLookAheadAreaWidthMeters: 0.0,
      forkliftLoadedExtraWidthMeters: 0.0,
      forkliftRearWidthMeters: 0.0,
      forkliftRearLengthMeters: 0.0,
      forkliftFrontLengthMeters: 0.0,
      considerManualUagvInZoneManagement: false,
      uagvFootprintBufferMeters: 0.0,
      uagvLookAheadAreaWidthMeters: 0.0,
      uagvLoadedExtraWidthMeters: 0.0,
      uagvRearWidthMeters: 0.0,
      uagvRearLengthMeters: 0.0,
      uagvFrontLengthMeters: 0.0,
    },
  };
}
