/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Texture } from 'pixi.js';
import { ForkliftDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import { ForkliftAgiloxOneMapItem } from './forklift-agilox-one.graphic';

/*
  Total Length incl Forks: 2784mm
  Body Length: 1560mm  (2784mm - 1224mm)
  Body Width: 1200mm
  Fork Length: 1224mm
  Fork Width: 577mm 
  Pivot: 168mm (Center on total length)
*/

export const dimensions: ForkliftDimensions = {
  length: 156,
  width: 120,
  origin: 17,
  fork: {
    width: 57.7,
    length: 122.4,
    loadOffset: 0,
  },
  load: {
    width: 100,
    length: 200,
  },
};

export class ForkliftAgiloxOCFMapItem extends ForkliftAgiloxOneMapItem {
  protected getDimensions(): ForkliftDimensions {
    return dimensions;
  }

  protected getArrowPosition(): number | undefined {
    return -(dimensions.fork.length / 2) - dimensions.origin;
  }

  protected getBodyTexture(): Texture {
    return VehicleTextures.forkliftAgiloxOCF;
  }
}
