import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { IntersectionCollisionPoint } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class IntersectionZoneCollisionService extends TenantHttpClient {
  protected apiUrl = environment.Services.ZoneManager;
  private readonly intersectionZoneCollisionPath = API_SERVICES.IntersectionZoneCollision;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getIntersectionZoneCollisionPointByMapId(
    mapId: GuidString
  ): Observable<IntersectionCollisionPoint[]> {
    return this.get<IntersectionCollisionPoint[]>(`${this.intersectionZoneCollisionPath}/${mapId}`);
  }
}
