import { GuidString, Vector2D } from 'core/models';
import { MapLayerView } from 'modules/maps/layers/map-layer-view';
import { Graphics } from 'pixi.js';
import { GeometryHelper } from 'shared/helpers';
import { ZoneMapItemContainer, isZoneMapItemContainer } from './zone-map-item-container';

export class ZoneMapLayerView extends MapLayerView {
  determineZonesOnPoint(position: Vector2D): GuidString[] {
    return this.children
      .filter(child => {
        if (isZoneMapItemContainer(child) && child.visible)
          return GeometryHelper.pointInPolygon(child.getZoneShape(), position);
        else return false;
      })
      .map(c => c.name ?? '');
  }

  getZoneItemShape(item: ZoneMapItemContainer): Vector2D[] {
    if (item.children.length !== 2)
      throw new Error('ZoneMapItem should have a Graphics and Vertex elements');

    const graphic: Graphics = item.children[0] as Graphics;

    return GeometryHelper.mapXYArrayToVector(graphic.geometry.points);
  }
}

export function isZoneMapLayerView(layer: MapLayerView): layer is ZoneMapLayerView {
  return 'determineZonesOnPoint' in layer;
}
