/* eslint-disable max-lines */
import {
  AdxCollisionRecordDto,
  AdxVehicleMessageRecord,
  AdxZoneRecordDto,
  CollisionPoint,
  TrafficAnalysisInput,
  TrafficAnalysisZoneInput,
  VehicleDto,
  ZoneAccessPriorityReasonEnum,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import { HardwareVersion, LoadType, SoftwareUpdateStatus, VehicleStatus } from 'core/models';
import {
  TrafficAnalysisCollisionViewModel,
  TrafficAnalysisZoneAccessViewModel,
} from 'modules/maps/containers/page/maps/traffic-analysis-panel/traffic-analysis-models';

export const mapId = 'ddbc9d00-e7d2-4856-84f0-11a6deea3b2c';

export const collisionMessage = {
  id: '433ed12d-41b4-467c-8e60-1324d02d164b',
  mapId: mapId,
  prioritizedVehicleId: 'cebada7d-d1c7-470a-a5b5-d4a6ed090105',
  pausedVehicleId: '837357d3-f639-43d0-ba44-2fe22f43a740',
  vehicleIdPaused: 'cebada7d-d1c7-470a-a5b5-d4a6ed090105',
  pose2D: {
    x: 73.482843808944423,
    y: 92.700001381337643,
    orientation: 0,
  },
  collisionDateTime: '2021-03-11T10:44:43.8213628Z',
  type: 0,
  collisionCase: 4,
  releaseType: 0,
  isDeadlockCollision: false,
};

export const trafficAnalysisZoneInput: TrafficAnalysisZoneInput = {
  mapId: mapId,
  vehicleId: 'cebada7d-d1c7-470a-a5b5-d4a6ed090105',
  vehicleName: 'STR_1',
  distanceToZoneWhenFirstRequest: 3,
  accessId: 'cebada7d-d1c7-470a-a5b5-d4a6ed09015g',
  masterZoneId: 'cebada7d-d1c7-470a-a5b5-d4a6ed0901ff',
  zoneAccessRequestedTime: '2021-03-11T10:42:43.8213628Z',
  zoneAccessGrantedTime: '2021-03-11T10:58:43.8213628Z',
  zoneAccessIgnoredTime: null,
  zoneLeftTime: '2021-03-11T11:44:43.8213628Z',
  lastPriorityChangedTime: '2021-03-11T10:55:43.8213628Z',
  zoneAccessPriorityReason: ZoneAccessPriorityReasonEnum.Normal,
  isIgnored: false,
  zoneAccessPrioritiesOverTime: [
    {
      priority: ZoneAccessPriorityReasonEnum.WaitTimeExceeded,
      priorityChangedTime: '2021-03-11T10:52:43.8213628Z',
    },
    {
      priority: ZoneAccessPriorityReasonEnum.Normal,
      priorityChangedTime: '2021-03-11T10:58:43.8213628Z',
    },
  ],
};

export const trafficAnalysisInput: TrafficAnalysisInput = {
  prioritizedVehicle: {
    vehicleId: 'cebada7d-d1c7-470a-a5b5-d4a6ed090105',
    vehicleType: 0,
    isCrossingStoppingAreaOfOtherVehicle: false,
    name: 'STR_1',
    messageId: 'cebada7d-fds4-470a-a5b5-d4a6ed031255',
    distanceToCollision: 3,
    dynamicDistanceToCollision: 2,
    angleAtCollision: 50,
    velocityX: 0.5,
    isDrivingBackwardsByPath: false,
    isDrivingBackwards: false,
    interactionZoneInputs: [],
    isTurning: false,
  },
  pausedVehicle: {
    vehicleId: '837357d3-f639-43d0-ba44-2fe22f43a740',
    vehicleType: 0,
    isCrossingStoppingAreaOfOtherVehicle: false,
    name: 'STR_1',
    messageId: 'cebada7d-fds4-470a-a5b5-d4a6ed03124b',
    distanceToCollision: 4,
    dynamicDistanceToCollision: 2,
    angleAtCollision: 13,
    velocityX: 0.5,
    isDrivingBackwardsByPath: false,
    isDrivingBackwards: false,
    interactionZoneInputs: [],
    isTurning: false,
  },
  mapId: mapId,
  collisionId: '433ed12d-41b4-467c-8e60-1324d02d164b',
  collisionPoint: {
    x: 73.48,
    y: 92.7,
  },
  collisionCase: 4,
  releaseType: 0,
  isDeadlockCollision: false,
  angleBetweenVehicles: 36,
  angleBetweenVehiclesForBackwardsDriving: 0,
  isOneOfTheVehiclesDrivingBackwards: false,
  commonInteractionZones: [],
  areDependent: false,
};

export const vehiclesMessages: VehicleDto[] = [
  {
    id: 'cebada7d-d1c7-470a-a5b5-d4a6ed090105',
    map: {
      id: 'ee59a5ae-f744-45c7-b1ce-359a7815a210',
      navigationLayerId: '',
    },
    softwareDetails: {
      softwareVersion: 'Str - Emulator | V: 0.01',
      iotHubSdkVersion: '4.5',
      atsInterfaceVersion: '1.1',
    },
    softwareVersionChangedDateUtc: '2021-03-11T10:44:43.8213628Z',
    softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
    softwareDownloadPercentage: 0,
    fleetId: '',
    vehicleKey: '',
    workAreaId: '',
    availability: 0,
    isConnected: true,
    isSwitchedOff: false,
    isRetired: false,
    lastStateMessageProcessedUtc: '',
    internalIdentifier: '',
    initializationDateTime: '',
    name: 'Str 1',
    ipAddress: 'xx',
    vehicleType: 0,
    interfaceType: 0,
    maintenanceModeEnabled: false,
    hasError: false,
    isCharging: false,
    isPaused: false,
    isBusy: true,
    isLoaded: false,
    brakeTestRequired: false,
    status: VehicleStatus.Busy,
    mode: 0,
    pose2D: {
      x: 65.8000009804964,
      y: 92.60000137984753,
      orientation: 1.0,
    },
    batteryLevel: 99.8,
    batteryLevelStatus: 0,
    velocity: {
      vx: 0.42755178662429394,
      vy: 0,
      omega: 0,
    },
    path: [
      {
        x: 65.8000009804964,
        y: 92.60000137984753,
      },
      {
        x: 65.9000009804964,
        y: 92.60000137984753,
      },
      {
        x: 66.00000098049641,
        y: 92.60000137984753,
      },
      {
        x: 66.00000098347664,
        y: 92.60000137984753,
      },
      {
        x: 66.10000098347664,
        y: 92.60000137984753,
      },
      {
        x: 66.20000098347664,
        y: 92.60000137984753,
      },
      {
        x: 66.20000098645687,
        y: 92.60000137984753,
      },
      {
        x: 66.30000098645687,
        y: 92.60000137984753,
      },
      {
        x: 66.40000098645688,
        y: 92.60000137984753,
      },
      {
        x: 66.4000009894371,
        y: 92.60000137984753,
      },
      {
        x: 66.5000009894371,
        y: 92.60000137984753,
      },
      {
        x: 66.6000009894371,
        y: 92.60000137984753,
      },
      {
        x: 66.60000099241734,
        y: 92.60000137984753,
      },
      {
        x: 66.70000099241733,
        y: 92.60000137984753,
      },
      {
        x: 66.80000099241734,
        y: 92.60000137984753,
      },
      {
        x: 66.80000099539757,
        y: 92.60000137984753,
      },
      {
        x: 66.90000099539756,
        y: 92.60000137984753,
      },
      {
        x: 67.00000099539757,
        y: 92.60000137984753,
      },
      {
        x: 67.0000009983778,
        y: 92.60000137984753,
      },
      {
        x: 67.1000009983778,
        y: 92.60000137984753,
      },
      {
        x: 67.2000009983778,
        y: 92.60000137984753,
      },
      {
        x: 67.20000100135803,
        y: 92.60000137984753,
      },
      {
        x: 67.30000100135803,
        y: 92.60000137984753,
      },
      {
        x: 67.40000100135804,
        y: 92.60000137984753,
      },
      {
        x: 67.40000100433827,
        y: 92.60000137984753,
      },
      {
        x: 67.50000100433826,
        y: 92.60000137984753,
      },
      {
        x: 67.60000100433827,
        y: 92.60000137984753,
      },
      {
        x: 67.6000010073185,
        y: 92.60000137984753,
      },
      {
        x: 67.70000100731849,
        y: 92.60000137984753,
      },
      {
        x: 67.8000010073185,
        y: 92.60000137984753,
      },
      {
        x: 67.80000101029873,
        y: 92.60000137984753,
      },
      {
        x: 67.90000101029873,
        y: 92.60000137984753,
      },
      {
        x: 68.00000101029873,
        y: 92.60000137984753,
      },
      {
        x: 68.00000101327896,
        y: 92.60000137984753,
      },
      {
        x: 68.10000101327896,
        y: 92.60000137984753,
      },
      {
        x: 68.20000101327897,
        y: 92.60000137984753,
      },
      {
        x: 68.2000010162592,
        y: 92.60000137984753,
      },
      {
        x: 68.30000101625919,
        y: 92.60000137984753,
      },
      {
        x: 68.4000010162592,
        y: 92.60000137984753,
      },
      {
        x: 68.40000101923943,
        y: 92.60000137984753,
      },
      {
        x: 68.50000101923942,
        y: 92.60000137984753,
      },
      {
        x: 68.60000101923943,
        y: 92.60000137984753,
      },
      {
        x: 68.60000102221966,
        y: 92.60000137984753,
      },
      {
        x: 68.70000102221965,
        y: 92.60000137984753,
      },
      {
        x: 68.80000102221966,
        y: 92.60000137984753,
      },
      {
        x: 68.80000102519989,
        y: 92.60000137984753,
      },
      {
        x: 68.90000102519989,
        y: 92.60000137984753,
      },
      {
        x: 69.0000010251999,
        y: 92.60000137984753,
      },
      {
        x: 69.00000102818012,
        y: 92.60000137984753,
      },
      {
        x: 69.10000102818012,
        y: 92.60000137984753,
      },
      {
        x: 69.20000102818013,
        y: 92.60000137984753,
      },
      {
        x: 69.20000103116036,
        y: 92.60000137984753,
      },
      {
        x: 69.30000103116035,
        y: 92.60000137984753,
      },
      {
        x: 69.40000103116036,
        y: 92.60000137984753,
      },
      {
        x: 69.40000103414059,
        y: 92.60000137984753,
      },
      {
        x: 69.50000103414058,
        y: 92.60000137984753,
      },
      {
        x: 69.60000103414059,
        y: 92.60000137984753,
      },
      {
        x: 69.60000103712082,
        y: 92.60000137984753,
      },
      {
        x: 69.70000103712082,
        y: 92.60000137984753,
      },
      {
        x: 69.80000103712082,
        y: 92.60000137984753,
      },
      {
        x: 69.80000104010105,
        y: 92.60000137984753,
      },
      {
        x: 69.90000104010105,
        y: 92.60000137984753,
      },
      {
        x: 70.00000104010106,
        y: 92.60000137984753,
      },
      {
        x: 70.00000104308129,
        y: 92.60000137984753,
      },
      {
        x: 70.10000104308128,
        y: 92.60000137984753,
      },
      {
        x: 70.20000104308129,
        y: 92.60000137984753,
      },
      {
        x: 70.20000104606152,
        y: 92.60000137984753,
      },
      {
        x: 70.30000104606151,
        y: 92.60000137984753,
      },
      {
        x: 70.40000104606152,
        y: 92.60000137984753,
      },
      {
        x: 70.40000104904175,
        y: 92.60000137984753,
      },
      {
        x: 70.50000104904174,
        y: 92.60000137984753,
      },
      {
        x: 70.60000104904175,
        y: 92.60000137984753,
      },
      {
        x: 70.60000105202198,
        y: 92.60000137984753,
      },
      {
        x: 70.70000105202198,
        y: 92.60000137984753,
      },
      {
        x: 70.80000105202199,
        y: 92.60000137984753,
      },
      {
        x: 70.80000105500221,
        y: 92.60000137984753,
      },
      {
        x: 70.9000010550022,
        y: 92.60000137984753,
      },
      {
        x: 71.00000105500222,
        y: 92.60000137984753,
      },
      {
        x: 71.00000105798245,
        y: 92.60000137984753,
      },
      {
        x: 71.10000105798244,
        y: 92.60000137984753,
      },
      {
        x: 71.20000105798245,
        y: 92.60000137984753,
      },
      {
        x: 71.20000106096268,
        y: 92.60000137984753,
      },
      {
        x: 71.30000106096267,
        y: 92.60000137984753,
      },
      {
        x: 71.40000106096268,
        y: 92.60000137984753,
      },
      {
        x: 71.40000106394291,
        y: 92.60000137984753,
      },
      {
        x: 71.5000010639429,
        y: 92.60000137984753,
      },
      {
        x: 71.60000106394291,
        y: 92.60000137984753,
      },
      {
        x: 71.60000106692314,
        y: 92.60000137984753,
      },
      {
        x: 71.70000106692314,
        y: 92.60000137984753,
      },
      {
        x: 71.80000106692315,
        y: 92.60000137984753,
      },
      {
        x: 71.80000106990338,
        y: 92.60000137984753,
      },
      {
        x: 71.90000106990337,
        y: 92.60000137984753,
      },
      {
        x: 72.00000106990338,
        y: 92.60000137984753,
      },
      {
        x: 72.0000010728836,
        y: 92.60000137984753,
      },
      {
        x: 72.1000010728836,
        y: 92.60000137984753,
      },
      {
        x: 72.20000107288363,
        y: 92.60000137984753,
      },
      {
        x: 72.20000107586384,
        y: 92.60000137984753,
      },
      {
        x: 72.30000107586385,
        y: 92.60000137984753,
      },
      {
        x: 72.40000107586384,
        y: 92.60000137984753,
      },
      {
        x: 72.40000107884407,
        y: 92.60000137984753,
      },
      {
        x: 72.50000107884407,
        y: 92.60000137984753,
      },
      {
        x: 72.60000107884408,
        y: 92.60000137984753,
      },
      {
        x: 72.6000010818243,
        y: 92.60000137984753,
      },
      {
        x: 72.7000010818243,
        y: 92.60000137984753,
      },
      {
        x: 72.8000010818243,
        y: 92.60000137984753,
      },
      {
        x: 72.80000108480454,
        y: 92.60000137984753,
      },
      {
        x: 72.90000108480453,
        y: 92.60000137984753,
      },
      {
        x: 73.00000108480454,
        y: 92.60000137984753,
      },
      {
        x: 73.00000108778477,
        y: 92.60000137984753,
      },
      {
        x: 73.10000108778476,
        y: 92.60000137984753,
      },
      {
        x: 73.20000108778477,
        y: 92.60000137984753,
      },
      {
        x: 73.200001090765,
        y: 92.60000137984753,
      },
      {
        x: 73.300001090765,
        y: 92.60000137984753,
      },
      {
        x: 73.400001090765,
        y: 92.60000137984753,
      },
      {
        x: 73.40000109374523,
        y: 92.60000137984753,
      },
      {
        x: 73.50000109374523,
        y: 92.60000137984753,
      },
      {
        x: 73.60000109374524,
        y: 92.60000137984753,
      },
      {
        x: 73.60000109672548,
        y: 92.60000137984753,
      },
      {
        x: 73.70000109672546,
        y: 92.60000137984753,
      },
      {
        x: 73.80000109672547,
        y: 92.60000137984753,
      },
      {
        x: 73.8000010997057,
        y: 92.60000137984753,
      },
      {
        x: 73.90000109970569,
        y: 92.60000137984753,
      },
      {
        x: 74.0000010997057,
        y: 92.60000137984753,
      },
      {
        x: 74.00000110268592,
        y: 92.60000137984753,
      },
      {
        x: 74.10000110268592,
        y: 92.60000137984753,
      },
      {
        x: 74.20000110268593,
        y: 92.60000137984753,
      },
      {
        x: 74.20000110566616,
        y: 92.60000137984753,
      },
      {
        x: 74.30000110566616,
        y: 92.60000137984753,
      },
      {
        x: 74.40000110566617,
        y: 92.60000137984753,
      },
      {
        x: 74.4000011086464,
        y: 92.60000137984753,
      },
      {
        x: 74.50000110864639,
        y: 92.60000137984753,
      },
      {
        x: 74.6000011086464,
        y: 92.60000137984753,
      },
      {
        x: 74.60000111162663,
        y: 92.60000137984753,
      },
      {
        x: 74.70000111162662,
        y: 92.60000137984753,
      },
      {
        x: 74.80000111162663,
        y: 92.60000137984753,
      },
      {
        x: 74.80000111460686,
        y: 92.60000137984753,
      },
      {
        x: 74.90000111460685,
        y: 92.60000137984753,
      },
      {
        x: 75.00000111460686,
        y: 92.60000137984753,
      },
      {
        x: 75.00000111758709,
        y: 92.60000137984753,
      },
      {
        x: 75.09864058951979,
        y: 92.61644080127405,
      },
      {
        x: 75.1972800614525,
        y: 92.6328802227005,
      },
      {
        x: 75.24,
        y: 92.64,
      },
    ],
    forkLength: 0,
    zoneSetId: '',
    desiredZoneSetId: '',
    hardwareVersion: HardwareVersion.Unknown,
    isErrorForwardingEnabled: true,
    supportedLoadTypes: [],
    loadType: LoadType.Unknown,
    loadOrientation: 0,
  },
  {
    id: '837357d3-f639-43d0-ba44-2fe22f43a740',
    map: {
      id: 'ee59a5ae-f744-45c7-b1ce-359a7815a210',
      navigationLayerId: '',
    },
    softwareDetails: {
      softwareVersion: 'Str - Emulator | V: 0.01',
      iotHubSdkVersion: '4.5',
      atsInterfaceVersion: '1.1',
    },
    softwareVersionChangedDateUtc: '2021-03-11T10:44:43.8213628Z',
    softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
    softwareDownloadPercentage: 0,
    workAreaId: '',
    availability: 0,
    fleetId: '',
    vehicleKey: '',
    isConnected: true,
    isSwitchedOff: false,
    isRetired: false,
    lastStateMessageProcessedUtc: '',
    internalIdentifier: '',
    initializationDateTime: '',
    name: 'Str 1',
    ipAddress: 'xx',
    vehicleType: 0,
    interfaceType: 0,
    maintenanceModeEnabled: false,
    isErrorForwardingEnabled: true,
    hasError: false,
    isCharging: false,
    isPaused: false,
    isBusy: true,
    isLoaded: false,
    brakeTestRequired: false,
    status: VehicleStatus.Busy,
    mode: 0,
    batteryLevel: 99.8,
    batteryLevelStatus: 0,
    pose2D: {
      x: 80.70000120401383,
      y: 92.4000013768673,
      orientation: 6.12323399573676617,
    },
    velocity: {
      vx: 0.36400394992601842,
      vy: 0,
      omega: 0,
    },
    path: [
      {
        x: 80.70000120401383,
        y: 92.4000013768673,
      },
      {
        x: 80.60000120401382,
        y: 92.4000013768673,
      },
      {
        x: 80.6000012010336,
        y: 92.4000013768673,
      },
      {
        x: 80.5000012010336,
        y: 92.4000013768673,
      },
      {
        x: 80.40000120103359,
        y: 92.4000013768673,
      },
      {
        x: 80.40000119805336,
        y: 92.4000013768673,
      },
      {
        x: 80.30000119805337,
        y: 92.4000013768673,
      },
      {
        x: 80.20000119805336,
        y: 92.4000013768673,
      },
      {
        x: 80.20000119507312,
        y: 92.4000013768673,
      },
      {
        x: 80.10000119507314,
        y: 92.4000013768673,
      },
      {
        x: 80.00000119507313,
        y: 92.4000013768673,
      },
      {
        x: 80.0000011920929,
        y: 92.4000013768673,
      },
      {
        x: 79.9000011920929,
        y: 92.4000013768673,
      },
      {
        x: 79.80000119209289,
        y: 92.4000013768673,
      },
      {
        x: 79.80000118911265,
        y: 92.4000013768673,
      },
      {
        x: 79.70000118911267,
        y: 92.4000013768673,
      },
      {
        x: 79.60000118911266,
        y: 92.4000013768673,
      },
      {
        x: 79.60000118613243,
        y: 92.4000013768673,
      },
      {
        x: 79.50000118613244,
        y: 92.4000013768673,
      },
      {
        x: 79.40000118613243,
        y: 92.4000013768673,
      },
      {
        x: 79.4000011831522,
        y: 92.4000013768673,
      },
      {
        x: 79.3000011831522,
        y: 92.4000013768673,
      },
      {
        x: 79.2000011831522,
        y: 92.4000013768673,
      },
      {
        x: 79.20000118017197,
        y: 92.4000013768673,
      },
      {
        x: 79.10000118017197,
        y: 92.4000013768673,
      },
      {
        x: 79.00000118017197,
        y: 92.4000013768673,
      },
      {
        x: 79.00000117719174,
        y: 92.4000013768673,
      },
      {
        x: 78.90000117719174,
        y: 92.4000013768673,
      },
      {
        x: 78.80000117719173,
        y: 92.4000013768673,
      },
      {
        x: 78.8000011742115,
        y: 92.4000013768673,
      },
      {
        x: 78.70000117421151,
        y: 92.4000013768673,
      },
      {
        x: 78.6000011742115,
        y: 92.4000013768673,
      },
      {
        x: 78.60000117123127,
        y: 92.4000013768673,
      },
      {
        x: 78.50000117123128,
        y: 92.4000013768673,
      },
      {
        x: 78.40000117123127,
        y: 92.4000013768673,
      },
      {
        x: 78.40000116825104,
        y: 92.4000013768673,
      },
      {
        x: 78.30000116825105,
        y: 92.4000013768673,
      },
      {
        x: 78.20000116825104,
        y: 92.4000013768673,
      },
      {
        x: 78.2000011652708,
        y: 92.4000013768673,
      },
      {
        x: 78.10000116527081,
        y: 92.4000013768673,
      },
      {
        x: 78.0000011652708,
        y: 92.4000013768673,
      },
      {
        x: 78.00000116229058,
        y: 92.4000013768673,
      },
      {
        x: 77.90000116229058,
        y: 92.4000013768673,
      },
      {
        x: 77.80000116229057,
        y: 92.4000013768673,
      },
      {
        x: 77.80000115931034,
        y: 92.4000013768673,
      },
      {
        x: 77.70000115931035,
        y: 92.4000013768673,
      },
      {
        x: 77.60000115931034,
        y: 92.4000013768673,
      },
      {
        x: 77.60000115633011,
        y: 92.4000013768673,
      },
      {
        x: 77.50000115633012,
        y: 92.4000013768673,
      },
      {
        x: 77.4000011563301,
        y: 92.4000013768673,
      },
      {
        x: 77.40000115334988,
        y: 92.4000013768673,
      },
      {
        x: 77.30000115334988,
        y: 92.4000013768673,
      },
      {
        x: 77.20000115334988,
        y: 92.4000013768673,
      },
      {
        x: 77.20000115036965,
        y: 92.4000013768673,
      },
      {
        x: 77.10000115036965,
        y: 92.4000013768673,
      },
      {
        x: 77.00000115036964,
        y: 92.4000013768673,
      },
      {
        x: 77.00000114738941,
        y: 92.4000013768673,
      },
      {
        x: 76.90000114738942,
        y: 92.4000013768673,
      },
      {
        x: 76.80000114738941,
        y: 92.4000013768673,
      },
      {
        x: 76.80000114440918,
        y: 92.4000013768673,
      },
      {
        x: 76.70000114440919,
        y: 92.4000013768673,
      },
      {
        x: 76.60000114440919,
        y: 92.4000013768673,
      },
      {
        x: 76.60000114142895,
        y: 92.4000013768673,
      },
      {
        x: 76.50000114142896,
        y: 92.4000013768673,
      },
      {
        x: 76.40000114142895,
        y: 92.4000013768673,
      },
      {
        x: 76.40000113844872,
        y: 92.4000013768673,
      },
      {
        x: 76.30000113844872,
        y: 92.4000013768673,
      },
      {
        x: 76.2000011384487,
        y: 92.4000013768673,
      },
      {
        x: 76.20000113546849,
        y: 92.4000013768673,
      },
      {
        x: 76.1000011354685,
        y: 92.4000013768673,
      },
      {
        x: 76.00000113546848,
        y: 92.4000013768673,
      },
      {
        x: 76.00000113248825,
        y: 92.4000013768673,
      },
      {
        x: 75.90000113248826,
        y: 92.4000013768673,
      },
      {
        x: 75.80000113248826,
        y: 92.4000013768673,
      },
      {
        x: 75.80000112950802,
        y: 92.4000013768673,
      },
      {
        x: 75.72929045138936,
        y: 92.47071205498595,
      },
      {
        x: 75.6585797732707,
        y: 92.54142273310461,
      },
      {
        x: 75.60000112652779,
        y: 92.60000137984753,
      },
      {
        x: 75.52929044840912,
        y: 92.67071205796617,
      },
      {
        x: 75.45857977029047,
        y: 92.74142273608483,
      },
      {
        x: 75.40000112354756,
        y: 92.80000138282778,
      },
      {
        x: 75.30000112354756,
        y: 92.80000138282778,
      },
      {
        x: 75.20000112354755,
        y: 92.80000138282778,
      },
      {
        x: 75.20000112056732,
        y: 92.80000138282778,
      },
      {
        x: 75.10000112056733,
        y: 92.80000138282778,
      },
      {
        x: 75.00000112056732,
        y: 92.80000138282778,
      },
      {
        x: 75.00000111758709,
        y: 92.80000138282778,
      },
      {
        x: 74.9000011175871,
        y: 92.80000138282778,
      },
      {
        x: 74.80000111758709,
        y: 92.80000138282778,
      },
      {
        x: 74.80000111460686,
        y: 92.80000138282778,
      },
      {
        x: 74.70000111460687,
        y: 92.80000138282778,
      },
      {
        x: 74.60000111460686,
        y: 92.80000138282778,
      },
      {
        x: 74.60000111162663,
        y: 92.80000138282778,
      },
      {
        x: 74.50000111162663,
        y: 92.80000138282778,
      },
      {
        x: 74.40000111162662,
        y: 92.80000138282778,
      },
      {
        x: 74.4000011086464,
        y: 92.80000138282778,
      },
      {
        x: 74.3000011086464,
        y: 92.80000138282778,
      },
      {
        x: 74.20000110864639,
        y: 92.80000138282778,
      },
      {
        x: 74.20000110566616,
        y: 92.80000138282778,
      },
      {
        x: 74.10000110566617,
        y: 92.80000138282778,
      },
      {
        x: 74.00000110566616,
        y: 92.80000138282778,
      },
      {
        x: 74.00000110268592,
        y: 92.80000138282778,
      },
      {
        x: 73.90000110268594,
        y: 92.80000138282778,
      },
      {
        x: 73.80000110268593,
        y: 92.80000138282778,
      },
      {
        x: 73.8000010997057,
        y: 92.80000138282778,
      },
      {
        x: 73.7000010997057,
        y: 92.80000138282778,
      },
      {
        x: 73.6000010997057,
        y: 92.80000138282778,
      },
      {
        x: 73.60000109672548,
        y: 92.80000138282778,
      },
      {
        x: 73.50000109672547,
        y: 92.80000138282778,
      },
      {
        x: 73.40000109672546,
        y: 92.80000138282778,
      },
      {
        x: 73.40000109374523,
        y: 92.80000138282778,
      },
      {
        x: 73.30000109374524,
        y: 92.80000138282778,
      },
      {
        x: 73.20000109374523,
        y: 92.80000138282778,
      },
      {
        x: 73.200001090765,
        y: 92.80000138282778,
      },
      {
        x: 73.100001090765,
        y: 92.80000138282778,
      },
      {
        x: 73.000001090765,
        y: 92.80000138282778,
      },
      {
        x: 73.00000108778477,
        y: 92.80000138282778,
      },
      {
        x: 72.90000108778477,
        y: 92.80000138282778,
      },
      {
        x: 72.80000108778477,
        y: 92.80000138282778,
      },
      {
        x: 72.80000108480454,
        y: 92.80000138282778,
      },
      {
        x: 72.70000108480454,
        y: 92.80000138282778,
      },
      {
        x: 72.60000108480453,
        y: 92.80000138282778,
      },
      {
        x: 72.6000010818243,
        y: 92.80000138282778,
      },
      {
        x: 72.50000108182431,
        y: 92.80000138282778,
      },
      {
        x: 72.4000010818243,
        y: 92.80000138282778,
      },
      {
        x: 72.40000107884407,
        y: 92.80000138282778,
      },
      {
        x: 72.30000107884408,
        y: 92.80000138282778,
      },
      {
        x: 72.20000107884407,
        y: 92.80000138282778,
      },
      {
        x: 72.20000107586384,
        y: 92.80000138282778,
      },
      {
        x: 72.10000107586385,
        y: 92.80000138282778,
      },
      {
        x: 72.00000107586384,
        y: 92.80000138282778,
      },
      {
        x: 72.0000010728836,
        y: 92.80000138282778,
      },
      {
        x: 71.90000107288361,
        y: 92.80000138282778,
      },
      {
        x: 71.8000010728836,
        y: 92.80000138282778,
      },
      {
        x: 71.80000106990338,
        y: 92.80000138282778,
      },
      {
        x: 71.70000106990338,
        y: 92.80000138282778,
      },
      {
        x: 71.60000106990337,
        y: 92.80000138282778,
      },
      {
        x: 71.60000106692314,
        y: 92.80000138282778,
      },
      {
        x: 71.50000106692315,
        y: 92.80000138282778,
      },
      {
        x: 71.40000106692314,
        y: 92.80000138282778,
      },
      {
        x: 71.40000106394291,
        y: 92.80000138282778,
      },
      {
        x: 71.30000106394292,
        y: 92.80000138282778,
      },
      {
        x: 71.2000010639429,
        y: 92.80000138282778,
      },
      {
        x: 71.20000106096268,
        y: 92.80000138282778,
      },
      {
        x: 71.10000106096268,
        y: 92.80000138282778,
      },
      {
        x: 71.00000106096268,
        y: 92.80000138282778,
      },
      {
        x: 71.00000105798245,
        y: 92.80000138282778,
      },
      {
        x: 70.90000105798245,
        y: 92.80000138282778,
      },
      {
        x: 70.80000105798244,
        y: 92.80000138282778,
      },
      {
        x: 70.80000105500221,
        y: 92.80000138282778,
      },
      {
        x: 70.70000105500222,
        y: 92.80000138282778,
      },
      {
        x: 70.60000105500221,
        y: 92.80000138282778,
      },
      {
        x: 70.60000105202198,
        y: 92.80000138282778,
      },
      {
        x: 70.50000105202199,
        y: 92.80000138282778,
      },
      {
        x: 70.40000105202198,
        y: 92.80000138282778,
      },
      {
        x: 70.40000104904175,
        y: 92.80000138282778,
      },
      {
        x: 70.30000104904176,
        y: 92.80000138282778,
      },
      {
        x: 70.20000104904175,
        y: 92.80000138282778,
      },
      {
        x: 70.20000104606152,
        y: 92.80000138282778,
      },
      {
        x: 70.10000104606152,
        y: 92.80000138282778,
      },
      {
        x: 70.00000104606152,
        y: 92.80000138282778,
      },
      {
        x: 70.00000104308129,
        y: 92.80000138282778,
      },
      {
        x: 69.90000104308129,
        y: 92.80000138282778,
      },
      {
        x: 69.80000104308128,
        y: 92.80000138282778,
      },
      {
        x: 69.80000104010105,
        y: 92.80000138282778,
      },
      {
        x: 69.70000104010106,
        y: 92.80000138282778,
      },
      {
        x: 69.60000104010105,
        y: 92.80000138282778,
      },
      {
        x: 69.60000103712082,
        y: 92.80000138282778,
      },
      {
        x: 69.50000103712083,
        y: 92.80000138282778,
      },
      {
        x: 69.40000103712082,
        y: 92.80000138282778,
      },
      {
        x: 69.40000103414059,
        y: 92.80000138282778,
      },
      {
        x: 69.30000103414059,
        y: 92.80000138282778,
      },
      {
        x: 69.20000103414059,
        y: 92.80000138282778,
      },
      {
        x: 69.20000103116036,
        y: 92.80000138282778,
      },
      {
        x: 69.1292903530417,
        y: 92.8707120609464,
      },
      {
        x: 69.05857967492304,
        y: 92.94142273906509,
      },
      {
        x: 69.00000102818012,
        y: 93.000001385808,
      },
      {
        x: 68.90000102818013,
        y: 93.000001385808,
      },
      {
        x: 68.80000102818012,
        y: 93.000001385808,
      },
      {
        x: 68.80000102519989,
        y: 93.000001385808,
      },
      {
        x: 68.7000010251999,
        y: 93.000001385808,
      },
      {
        x: 68.60000102519989,
        y: 93.000001385808,
      },
      {
        x: 68.60000102221966,
        y: 93.000001385808,
      },
      {
        x: 68.50000102221967,
        y: 93.000001385808,
      },
      {
        x: 68.40000102221966,
        y: 93.000001385808,
      },
      {
        x: 68.40000101923943,
        y: 93.000001385808,
      },
      {
        x: 68.30000101923943,
        y: 93.000001385808,
      },
      {
        x: 68.20000101923942,
        y: 93.000001385808,
      },
      {
        x: 68.2000010162592,
        y: 93.000001385808,
      },
      {
        x: 68.1000010162592,
        y: 93.000001385808,
      },
      {
        x: 68.00000101625919,
        y: 93.000001385808,
      },
      {
        x: 68.00000101327896,
        y: 93.000001385808,
      },
      {
        x: 67.90000101327897,
        y: 93.000001385808,
      },
      {
        x: 67.80000101327896,
        y: 93.000001385808,
      },
      {
        x: 67.80000101029873,
        y: 93.000001385808,
      },
      {
        x: 67.70000101029874,
        y: 93.000001385808,
      },
      {
        x: 67.60000101029873,
        y: 93.000001385808,
      },
      {
        x: 67.6000010073185,
        y: 93.000001385808,
      },
      {
        x: 67.5000010073185,
        y: 93.000001385808,
      },
      {
        x: 67.4000010073185,
        y: 93.000001385808,
      },
      {
        x: 67.40000100433827,
        y: 93.000001385808,
      },
      {
        x: 67.30000100433827,
        y: 93.000001385808,
      },
      {
        x: 67.20000100433826,
        y: 93.000001385808,
      },
      {
        x: 67.20000100135803,
        y: 93.000001385808,
      },
      {
        x: 67.10000100135804,
        y: 93.000001385808,
      },
      {
        x: 67.00000100135803,
        y: 93.000001385808,
      },
      {
        x: 67.0000009983778,
        y: 93.000001385808,
      },
      {
        x: 66.9000009983778,
        y: 93.000001385808,
      },
      {
        x: 66.8000009983778,
        y: 93.000001385808,
      },
      {
        x: 66.80000099539757,
        y: 93.000001385808,
      },
      {
        x: 66.70000099539758,
        y: 93.000001385808,
      },
      {
        x: 66.60000099539757,
        y: 93.000001385808,
      },
      {
        x: 66.60000099241734,
        y: 93.000001385808,
      },
      {
        x: 66.50000099241734,
        y: 93.000001385808,
      },
      {
        x: 66.40000099241733,
        y: 93.000001385808,
      },
      {
        x: 66.4000009894371,
        y: 93.000001385808,
      },
      {
        x: 66.30000098943711,
        y: 93.000001385808,
      },
      {
        x: 66.2000009894371,
        y: 93.000001385808,
      },
      {
        x: 66.20000098645687,
        y: 93.000001385808,
      },
      {
        x: 66.10000098645688,
        y: 93.000001385808,
      },
      {
        x: 66.00000098645687,
        y: 93.000001385808,
      },
      {
        x: 66.00000098347664,
        y: 93.000001385808,
      },
      {
        x: 65.90000098347665,
        y: 93.000001385808,
      },
      {
        x: 65.80000098347664,
        y: 93.000001385808,
      },
      {
        x: 65.8000009804964,
        y: 93.000001385808,
      },
      {
        x: 65.70000098049641,
        y: 93.000001385808,
      },
      {
        x: 65.6000009804964,
        y: 93.000001385808,
      },
      {
        x: 65.60000097751618,
        y: 93.000001385808,
      },
      {
        x: 65.50000097751618,
        y: 93.000001385808,
      },
      {
        x: 65.40000097751617,
        y: 93.000001385808,
      },
      {
        x: 65.40000097453594,
        y: 93.000001385808,
      },
      {
        x: 65.30000097453595,
        y: 93.000001385808,
      },
      {
        x: 65.20000097453594,
        y: 93.000001385808,
      },
      {
        x: 65.20000097155571,
        y: 93.000001385808,
      },
      {
        x: 65.10000097155572,
        y: 93.000001385808,
      },
      {
        x: 65.0000009715557,
        y: 93.000001385808,
      },
      {
        x: 65.00000096857548,
        y: 93.000001385808,
      },
      {
        x: 64.90000096857549,
        y: 93.000001385808,
      },
      {
        x: 64.80000096857548,
        y: 93.000001385808,
      },
      {
        x: 64.80000096559525,
        y: 93.000001385808,
      },
      {
        x: 64.70000096559525,
        y: 93.000001385808,
      },
      {
        x: 64.60000096559524,
        y: 93.000001385808,
      },
      {
        x: 64.60000096261502,
        y: 93.000001385808,
      },
      {
        x: 64.50000096261502,
        y: 93.000001385808,
      },
      {
        x: 64.40000096261501,
        y: 93.000001385808,
      },
      {
        x: 64.40000095963478,
        y: 93.000001385808,
      },
      {
        x: 64.30000095963479,
        y: 93.000001385808,
      },
      {
        x: 64.20000095963478,
        y: 93.000001385808,
      },
      {
        x: 64.20000095665455,
        y: 93.000001385808,
      },
      {
        x: 64.10000095665456,
        y: 93.000001385808,
      },
      {
        x: 64.00000095665455,
        y: 93.000001385808,
      },
      {
        x: 64.00000095367432,
        y: 93.000001385808,
      },
      {
        x: 63.900000953674318,
        y: 93.000001385808,
      },
      {
        x: 63.800000953674317,
        y: 93.000001385808,
      },
      {
        x: 63.80000095069408,
        y: 93.000001385808,
      },
      {
        x: 63.70000095069408,
        y: 93.000001385808,
      },
      {
        x: 63.60000095069408,
        y: 93.000001385808,
      },
      {
        x: 63.60000094771386,
        y: 93.000001385808,
      },
      {
        x: 63.50000094771385,
        y: 93.000001385808,
      },
      {
        x: 63.40000094771385,
        y: 93.000001385808,
      },
      {
        x: 63.40000094473362,
        y: 93.000001385808,
      },
      {
        x: 63.30000094473362,
        y: 93.000001385808,
      },
      {
        x: 63.20000094473362,
        y: 93.000001385808,
      },
      {
        x: 63.20000094175339,
        y: 93.000001385808,
      },
      {
        x: 63.100000941753389,
        y: 93.000001385808,
      },
      {
        x: 63.000000941753388,
        y: 93.000001385808,
      },
      {
        x: 63.000000938773158,
        y: 93.000001385808,
      },
      {
        x: 62.900000938773157,
        y: 93.000001385808,
      },
      {
        x: 62.80000093877315,
        y: 93.000001385808,
      },
      {
        x: 62.80000093579292,
        y: 93.000001385808,
      },
      {
        x: 62.70000093579292,
        y: 93.000001385808,
      },
      {
        x: 62.60000093579292,
        y: 93.000001385808,
      },
      {
        x: 62.60000093281269,
        y: 93.000001385808,
      },
      {
        x: 62.50000093281269,
        y: 93.000001385808,
      },
      {
        x: 62.40000093281269,
        y: 93.000001385808,
      },
      {
        x: 62.40000092983246,
        y: 93.000001385808,
      },
      {
        x: 62.30000092983246,
        y: 93.000001385808,
      },
      {
        x: 62.200000929832459,
        y: 93.000001385808,
      },
      {
        x: 62.200000926852229,
        y: 93.000001385808,
      },
      {
        x: 62.100000926852228,
        y: 93.000001385808,
      },
      {
        x: 62.00000092685222,
        y: 93.000001385808,
      },
      {
        x: 62.000000923871997,
        y: 93.000001385808,
      },
      {
        x: 61.90000092387199,
        y: 93.000001385808,
      },
      {
        x: 61.80000092387199,
        y: 93.000001385808,
      },
      {
        x: 61.80000092089176,
        y: 93.000001385808,
      },
      {
        x: 61.70000092089176,
        y: 93.000001385808,
      },
      {
        x: 61.60000092089176,
        y: 93.000001385808,
      },
      {
        x: 61.60000091791153,
        y: 93.000001385808,
      },
      {
        x: 61.52929023979287,
        y: 93.07071206392665,
      },
      {
        x: 61.45857956167422,
        y: 93.1414227420453,
      },
      {
        x: 61.4000009149313,
        y: 93.20000138878823,
      },
      {
        x: 61.33195518536793,
        y: 93.27328016257838,
      },
      {
        x: 61.27,
        y: 93.34,
      },
    ],
    forkLength: 0,
    zoneSetId: '',
    desiredZoneSetId: '',
    hardwareVersion: HardwareVersion.Unknown,
    loadType: LoadType.Unknown,
    supportedLoadTypes: [],
    loadOrientation: 0,
  },
];

export const adxVehiclesMessages: AdxVehicleMessageRecord[] = [
  {
    id: 'c67348e7-d471-4bd6-9e51-437407f18c2c',
    name: 'TIM',
    vehicleType: 0,
    mode: 0,
    isBusy: true,
    isCharging: false,
    isLoaded: false,
    isPaused: false,
    mapId: mapId,
    maintenanceModeEnabled: false,
    hardwareVersion: HardwareVersion.StrVersion3,
    pose2D: { x: 128.06, y: 33.58, orientation: 1.5707963267948966 },
    path: [],
    velocity: { vx: 0, vy: 0, omega: 0 },
  },
  {
    id: '39913228-260f-4911-a6e8-f9086bf39a41',
    name: 'J',
    vehicleType: 0,
    mode: 0,
    isBusy: true,
    isCharging: false,
    isLoaded: false,
    isPaused: false,
    mapId: mapId,
    hardwareVersion: HardwareVersion.StrVersion3,
    maintenanceModeEnabled: false,
    pose2D: { x: 119.80000178217888, y: 30.600000455975533, orientation: 0.0 },
    path: [
      { x: 119.80000178217888, y: 30.600000455975533, theta: 0.0 },
      { x: 124.47071253182312, y: 30.670711134094187, theta: 0.7853981633974232 },
      { x: 124.90000185966493, y: 31.000000461935997, theta: 0.0 },
      { x: 131.07071263017076, y: 31.07071114005465, theta: 0.7853981633973227 },
      { x: 131.30000195503234, y: 31.20000046491623, theta: 0.0 },
      { x: 133.68619525584467, y: 31.250702743564208, theta: 0.5317271532470396 },
      { x: 133.79873641719837, y: 31.3957821399145, theta: 1.279322528751878 },
      { x: 133.80000199377537, y: 31.500000467896463, theta: 1.5707963267948966 },
      { x: 133.7900509506847, y: 33.69950415257887, theta: 1.6704717246502232 },
      { x: 133.77, y: 33.9, theta: 1.670471724653669 },
    ],
    velocity: { vx: 0, vy: 0, omega: 0 },
  },
  {
    id: '2c0c2fc0-1a50-48bf-8331-13df9801b14f',
    name: 'J2',
    vehicleType: 0,
    mode: 1,
    isBusy: true,
    isCharging: false,
    isLoaded: false,
    isPaused: false,
    mapId: mapId,
    maintenanceModeEnabled: false,
    hardwareVersion: HardwareVersion.TuggerTrainDsV1,
    pose2D: { x: 133.80000199377537, y: 31.80000047683716, orientation: 1.5707963267948966 },
    path: [
      { x: 133.80000199377537, y: 31.80000047683716, theta: 1.5707963267948966 },
      { x: 133.771265576577, y: 31.30421832798196, theta: 1.279322528751878 },
      { x: 133.68380673495045, y: 31.249297721352015, theta: 3.6733198068368327 },
      { x: 133.50000199079514, y: 31.20000046491623, theta: 3.141592653589793 },
      { x: 122.13185978047711, y: 31.12681124218486, theta: 3.9626873657279567 },
      { x: 121.9534645935884, y: 31.007208090443804, theta: 1.3339420078985842 },
      { x: 122.00000181794168, y: 31.30000046491623, theta: 1.5707963267948966 },
      { x: 121.94631310342504, y: 33.48436590490785, theta: 2.1375393567594623 },
      { x: 121.93, y: 33.51, theta: 2.1375393567594623 },
    ],
    velocity: { vx: 0, vy: 0, omega: 0 },
    trailers: [
      { trailerId: '1', trailerPosition: { x: 133.8, y: 32, orientation: 1.57, theta: 1.57 } },
      { trailerId: '2', trailerPosition: { x: 133.8, y: 34, orientation: 1.57, theta: 1.57 } },
    ],
  },
];

export const adxCollisionRecords: AdxCollisionRecordDto[] = [
  {
    collisionDateTimeUtc: Date.now().toString(),
    message: trafficAnalysisInput,
    resolutionDateTimeUtc: Date.now().toString(),
    pausedVehicleEnqueuedTimeUtc: Date.now().toString(),
    prioritizedVehicleEnqueuedTimeUtc: Date.now().toString(),
  },
];

export const adxZoneRecords: AdxZoneRecordDto[] = [
  {
    message: trafficAnalysisZoneInput,
  },
];

export const zoneMessages: TrafficAnalysisZoneAccessViewModel[] = [
  {
    time: '2025-01-01',
    vehicleName: 'vehicleName',
    masterZoneId: '1',
    id: 'id',
    vehicleId: 'vehicleId',
  },
  {
    time: '2025-01-01',
    vehicleName: 'vehicleName2',
    masterZoneId: '1',
    id: 'id2',
    vehicleId: 'vehicleId2',
  },
];

export const collisionMessages: TrafficAnalysisCollisionViewModel[] = [
  {
    time: '2025-01-01',
    pausedVehicle: 'vehicleName',
    prioritizedVehicle: 'vehicleName2',
    id: 'id',
    prioritizedVehicleId: 'vehicleId2',
    pausedVehicleId: 'vehicleId',
  },
  {
    time: '2025-01-01',
    pausedVehicle: 'vehicle2Name',
    prioritizedVehicle: 'vehicle2Name2',
    id: 'id2',
    prioritizedVehicleId: 'vehicle2Id2',
    pausedVehicleId: 'vehicle2Id',
  },
];

export class TrafficAnalysisTestHelper {
  static getVehicleMessages(): VehicleDto[] {
    return vehiclesMessages;
  }

  static getVehicleMessage1JsonString(): string {
    return JSON.stringify(vehiclesMessages[0]);
  }

  static getVehicleMessage2JsonString(): string {
    return JSON.stringify(vehiclesMessages[1]);
  }

  static getCollisionMessage(): CollisionPoint {
    return objectHelper.omit(collisionMessage, ['collisionDateTime', 'type', 'vehicleIdPaused']);
  }

  static getCollisionMessageJsonString(): string {
    return JSON.stringify(collisionMessage);
  }

  static getZoneMessages(): TrafficAnalysisZoneAccessViewModel[] {
    return zoneMessages;
  }

  static getCollisionMessages(): TrafficAnalysisCollisionViewModel[] {
    return collisionMessages;
  }
}
