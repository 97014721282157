import { NavigationLayerResponseModel } from 'core/dtos';
import { MapLayerType } from 'core/models';

import { SCALE_MODES, Sprite } from 'pixi.js';
import { IMapLayer, MapLayerBase } from '../map-layer';
import { MapLayerView } from '../map-layer-view';
import { OffsetMapLayerDrawing } from '../offset-map-layer-drawing';

export class GppMapLayer extends MapLayerBase implements IMapLayer {
  readonly drawing: OffsetMapLayerDrawing;

  constructor(
    view: MapLayerView,
    protected navigationLayer: NavigationLayerResponseModel,
    blob: Blob
  ) {
    super();
    this.drawing = view.addChild(new OffsetMapLayerDrawing(navigationLayer, MapLayerType.GppLayer));
    this.drawing.addChild(this.createImage(blob));
  }

  createImage(data: Blob): Sprite {
    return Sprite.from(URL.createObjectURL(data), { scaleMode: SCALE_MODES.NEAREST });
  }
}
