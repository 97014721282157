import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { CollisionPoint } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class PredictedCollisionsService extends TenantHttpClient {
  protected apiUrl = environment.Services.TrafficManager;
  private readonly servicePath = API_SERVICES.PredictedCollision;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }
  getCollisionPointByMapIdForTrafficSettings(mapId: GuidString): Observable<CollisionPoint[]> {
    return this.get<CollisionPoint[]>(`${this.servicePath}/GetPredictedCollisions/${mapId}`);
  }
}
