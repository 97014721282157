<app-base-page-layout
  [hasToolbar]="hasToolbar"
  [isNewLayout]="isNewLayout"
  [pageHeading]="pageHeading"
  [secondaryPageHeading]="secondaryPageHeading">
  <div
    [ngClass]="{ 'scroll-child': scrollChild }"
    base-page-content
    class="content-area pl-container body-area view">
    <div class="container-fluid">
      <div class="row">
        <div class="col pb-0x">
          <h3
            *ngIf="contentHeading"
            [attr.data-translatekey]="contentHeading"
            data-cy="contentHeading">
            <ng-content select="[contentHeadingPrefix]"></ng-content>
            {{ contentHeading | translate }}
          </h3>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'scroll-child': scrollChild }" class="pl-content">
      <ng-content class="col pb-0x" select="[plain-layout-content]"></ng-content>
    </div>
  </div>
</app-base-page-layout>
