import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DsDatepickerConfiguration, DsTimepickerComponentConfiguration } from '@bmw-ds/components';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { DATE_FORMAT } from 'core/constants';
import { format } from 'date-fns';
import { LocaleSettings } from 'primeng/calendar';
import { setTimeString } from 'shared/helpers';

export interface IAgGridDateTimeFilterModel {
  dateFrom: string | undefined;
  dateTo: string | undefined;
}

export interface StartEndDate {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

@Component({
  selector: 'app-ag-grid-date-time-filter',
  templateUrl: './ag-grid-date-time-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridDateTimeFilterComponent implements IFilterAngularComp {
  params: IFilterParams | undefined;

  readonly datePickerConfig: Partial<DsDatepickerConfiguration> = {
    selectionMode: 'single',
    allowDirectInput: true,
    closeOnDateSelect: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  readonly timePickerConfig: Partial<DsTimepickerComponentConfiguration> = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    hours: 1,
    minutes: 1,
    seconds: 1,
    format: 'HH:mm:ss',
    allowDirectInput: true,
  };
  calendarLocale?: LocaleSettings;
  fromDate: Date | null = null;
  fromTime = '';
  toDate: Date | null = null;
  toTime = '';

  toDatetimeIsInValid = false;
  fromDatetimeIsInValid = false;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  getStartAndEndDate(): StartEndDate {
    if (this.fromDate === null || this.toDate === null) {
      return { startDate: undefined, endDate: undefined };
    }

    const startDate = setTimeString(this.fromDate, this.fromTime);
    const endDate = setTimeString(this.toDate, this.toTime);
    return { startDate, endDate };
  }

  isFilterActive(): boolean {
    const { startDate, endDate } = this.getStartAndEndDate();
    return startDate !== undefined && endDate !== undefined;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const field = this.params?.colDef.field;
    if (field === undefined) return false;

    const { startDate, endDate } = this.getStartAndEndDate();
    if (startDate === undefined || endDate === undefined) return false;

    let cellDate: Date | undefined;
    try {
      cellDate = new Date(params.data[field]);
    } catch (e) {
      console.error(
        'AgGridDateTimeFilterComponent could not parse timestamp into date:',
        params.data[field],
        e
      );
      return false;
    }

    return cellDate >= startDate && cellDate <= endDate;
  }

  getModel(): IAgGridDateTimeFilterModel {
    const { startDate, endDate } = this.getStartAndEndDate();
    if (startDate === undefined || endDate === undefined) {
      return { dateFrom: undefined, dateTo: undefined };
    }

    return {
      dateFrom: startDate.toISOString(),
      dateTo: endDate.toISOString(),
    };
  }

  setModel(model: { dateFrom: string | undefined; dateTo: string | undefined } | undefined): void {
    if (!model) {
      this.clearFilter();
      return;
    }

    if (model?.dateFrom && model?.dateTo) {
      const from = new Date(model.dateFrom);
      this.fromDate = from;
      this.fromTime = format(from, DATE_FORMAT.TIME);

      const to = new Date(model.dateTo);
      this.toDate = to;
      this.toTime = format(to, DATE_FORMAT.TIME);

      this.updateFilter();
    }
  }

  isFormValid(): boolean {
    const fromDateValid =
      this.fromDate !== null && setTimeString(this.fromDate, this.fromTime) !== undefined;
    const toDateValid =
      this.toDate !== null && setTimeString(this.toDate, this.toTime) !== undefined;

    this.fromDatetimeIsInValid = !fromDateValid;
    this.toDatetimeIsInValid = !toDateValid;

    return fromDateValid && toDateValid;
  }

  updateFilter(): void {
    if (this.isFormValid()) this.params?.filterChangedCallback();
  }

  clearFilter(): void {
    this.fromDate = null;
    this.toDate = null;
    this.fromTime = '';
    this.toTime = '';

    this.fromDatetimeIsInValid = false;
    this.toDatetimeIsInValid = false;

    if (this.params?.filterChangedCallback) {
      this.params.filterChangedCallback();
    }
  }
}
