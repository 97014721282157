import { VehicleDto } from 'core/dtos';
import {
  CommentModalType,
  HardwareVersion,
  HardwareVersionCommentModalType,
  ReducedVehicle,
} from 'core/models';

export const directVehicleAccess = {
  onDirectVehicleAccess: (selectedVehicle: ReducedVehicle): void => {
    if (selectedVehicle && selectedVehicle.ipAddress) {
      let url = selectedVehicle.ipAddress;
      if (!url.startsWith('http')) {
        url = 'http:\\\\' + url;
      }
      window.open(url, '_blank');
    }
  },
};

export function strWebToolAccess(): void {
  const url = 'https://strwebbasedtool.bmwgroup.net/';
  window.open(url, '_blank');
}

export function getDefaultRecipientKeyFormat(recipientKey: string): string {
  return `Default (${recipientKey})`;
}

export function determineModalTypeForVehicle(
  selectedVehicle: VehicleDto | ReducedVehicle | undefined
): CommentModalType {
  return selectedVehicle?.hardwareVersion
    ? HardwareVersionCommentModalType[selectedVehicle.hardwareVersion]
    : CommentModalType.Default;
}

export function isManualVehicle(vehicle: VehicleDto | ReducedVehicle | undefined): boolean {
  return (
    vehicle?.hardwareVersion === HardwareVersion.ForkliftManual ||
    vehicle?.hardwareVersion === HardwareVersion.TuggerTrainManual
  );
}
