import { ZoneType } from 'core/dtos';

export const ZoneBorder = {
  [ZoneType.Restricted]: 0x444444,
  alignment: 1,
  alpha: 1,
  selected: 0xfecb00,
  size: 0.04,
  error: 0xfe0000,
};

export const ZoneAlpha = {
  [ZoneType.Restricted]: 0.7,
  [ZoneType.Eraser]: 0.7,
  [ZoneType.NoReplanning]: 0.3,
  [ZoneType.LimitedCapacity]: 0.3,
  [ZoneType.Interaction]: 0.3,
  [ZoneType.Intersection]: 0.3,
  [ZoneType.TrafficManagementCustom]: 0.3,
  [ZoneType.AlertNow]: 0.7,
  default: 0.4,
};

export const ZoneErrorColor = 0xfe0000;
export const ZoneInactiveColor = 0xbbbbbb;
export const ZoneInactiveBorderColor = 0x777777;
export const ZoneAllowedAngleVariance = 0.05;

export const ZoneColor = {
  [ZoneType.Restricted]: 0x444444,
  [ZoneType.PreferredDirection]: 0,
  [ZoneType.SpeedLimit]: 0x6d7c9f,
  [ZoneType.NoReplanning]: 0xa5b0c6,
  [ZoneType.Eraser]: 0xf4f2ee,
  [ZoneType.Interaction]: 0xffad1f,
  [ZoneType.LimitedCapacity]: 0xf3c14c,
  [ZoneType.Intersection]: 0xffc0cb,
  [ZoneType.TrafficManagementCustom]: 0xa29cfe,
  [ZoneType.AlertNow]: 0xecf3ff,
  [ZoneType.Service]: 0xe5fcb1,
};

export const ZoneOrder = {
  // bottom to top
  [ZoneType.Service]: 1,
  [ZoneType.AlertNow]: 2,
  [ZoneType.Eraser]: 3,
  [ZoneType.Restricted]: 4,
  [ZoneType.NoReplanning]: 5,
  [ZoneType.SpeedLimit]: 6,
  [ZoneType.PreferredDirection]: 7,
  [ZoneType.LimitedCapacity]: 8,
  [ZoneType.Interaction]: 9,
  [ZoneType.TrafficManagementCustom]: 10,
  [ZoneType.Intersection]: 11,
};

export const DirectionStyle = {
  center: 0.5,
  height: 38,
  resolution: 2,
  resourceOptions: { scale: 5 },
  scale: 0.05,
  size: 4.5,
  width: 40,
};

export const ZoneEditorStyle = {
  alpha: 0.5,
  borderSize: 0.04,
  borderColor: 0x379acb,
  fillColor: 0xeceecc,

  verticesColor: 0x444444,
  selectedVerticesColor: 0x3a7df2,
  verticesBorderColor: 0x6e6e6e,
  verticesBorder: 0, //strange visualization when setting to lower values
  verticesSize: 0.15,

  pointerScale: 1.3,
  closeZoneHitArea: 0.15,
  closeZoneColor: 0x00ca1c,
  closeZoneBorder: 0,
};
