import { MissionDeliveryStatus, MissionReportCategory, MissionStatus } from 'core/models';

export const missionStatus = {
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [MissionStatus.Completed]: '#55C474',
  [MissionStatus.CompletedWithSupport]: '#55C474',
  [MissionStatus.Aborted]: '#DF001A',
};

export const report = {
  [MissionReportCategory.IO]: '#ABD350',
  [MissionReportCategory.Organizational]: '#EC9500',
  [MissionReportCategory.CallOffSystem]: '#FFE38E',
  [MissionReportCategory.Vehicle]: '#FF8485',
  [MissionReportCategory.Services]: '#51D6E4',
  [MissionReportCategory.NoReasonAssigned]: '#C6C8CB',
  [MissionReportCategory.NotRelevant]: '#8C8E91',
  [MissionReportCategory.SystemOptimization]: '#161616',
};

export const deliveryStatus = {
  [MissionDeliveryStatus.Overdue]: '#DF001A', //Icon/critical
  [MissionDeliveryStatus.DueShortly]: '#DF001A', //Icon/critical
  [MissionDeliveryStatus.DueShortlyPriority]: '#DF001A', //Icon/critical
  [MissionDeliveryStatus.Due]: '#F0CA1A', //Surface/Signal/Caution/strong
  [MissionDeliveryStatus.DuePriority]: '#F0CA1A', //Surface/Signal/Caution/strong
  [MissionDeliveryStatus.NotUrgent]: '#55C474', //--ds-color-green-400
  [MissionDeliveryStatus.NotUrgentPriority]: '#55C474', //--ds-color-green-400
  [MissionDeliveryStatus.Normal]: '#69707A', //Icon/neutral
  [MissionDeliveryStatus.ValidationError]: '#DF001A', //Icon/critical
  [MissionDeliveryStatus.UnsupportedVehicleAction]: '#DF001A', //Icon/critical
  [MissionDeliveryStatus.VehicleOutDatedOrder]: '#DF001A', //Icon/critical
};
