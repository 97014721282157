import { MapColors } from 'library/styles';
import { Graphics, ILineStyleOptions } from 'pixi.js';

export class GraphicsEx extends Graphics {
  private readonly baseColor = MapColors.Base;

  get hasPoints(): boolean {
    return this.geometry.points && this.geometry.points.length > 0;
  }

  lineStyle(
    widthOrOptions: number | ILineStyleOptions | undefined,
    color?: number,
    alpha?: number,
    alignment?: number,
    native?: boolean
  ): this {
    if (typeof widthOrOptions === 'number') {
      this.tint = color ?? this.baseColor;
      return super.lineStyle(widthOrOptions, this.baseColor, alpha, alignment, native);
    } else {
      this.tint = widthOrOptions?.color ?? this.baseColor;
      return super.lineStyle({ ...widthOrOptions, color: this.baseColor });
    }
  }

  beginFill(color?: number, alpha?: number): this {
    if (color) this.tint = color;
    super.beginFill(this.baseColor, alpha);

    return this;
  }

  changeColor(color: number): void {
    this.tint = color ?? this.baseColor;

    this.children.forEach(e => {
      if (isGraphicsEx(e)) {
        e.changeColor(color);
      }
    });
  }

  remove(): void {
    this.parent.removeChild(this);
    this.destroy();
  }
}

export function isGraphicsEx(graphic: object | undefined): graphic is GraphicsEx {
  return graphic !== undefined && 'changeColor' in graphic;
}
