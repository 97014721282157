import { ONE_SECOND } from 'core/constants/constant';
import {
  EventDto,
  MissionDto,
  ProcessChainDto,
  RaiseEventStepDto,
  StepDto,
  WaitForEventStepDto,
} from 'core/dtos';
import {
  EventDetail,
  EventType,
  GuidString,
  Mission,
  ProcessChain,
  StepModel,
  StepType,
  StepTypeBackend,
} from 'core/models';
import { sortBy } from 'lodash';
import * as uuid from 'uuid';
import objectHelper from '../object.helper';

export function convertProcessChainModelToDto(processChain: ProcessChain): ProcessChainDto {
  const eventDetails: EventDetail[] = objectHelper.cloneDeep(processChain.eventDetails);

  let convertedMissionDtos: MissionDto[] = convertMissionModelToMissionDto(
    processChain.missions,
    eventDetails
  );
  const eventDtos: EventDto[] = convertEventDetailsToEventDtos(eventDetails);

  convertedMissionDtos = addRaiseEventSteps(convertedMissionDtos, eventDetails);
  convertedMissionDtos = updateStepSequenceNumbers(convertedMissionDtos);

  return {
    id: processChain.id,
    name: processChain.name,
    materialNumber: processChain.materialNumber,
    source: processChain.source,
    destination: processChain.destination,
    referenceId: processChain.referenceId,
    missionIds: processChain.missionIds,
    missions: convertedMissionDtos,
    events: eventDtos,
    fleetId: processChain.fleetId,
    workAreaId: processChain.workAreaId,
    deviceTriggerDevice: processChain.deviceTriggerDevice,
    deviceTriggerNode: processChain.deviceTriggerNode,
    deviceTriggerValue: processChain.deviceTriggerValue,
    triggerType: processChain.triggerType,
    type: processChain.type,
    maxQueueLength: processChain.maxQueueLength,
    enableRepetitiveProcess: processChain.enableRepetitiveProcess,
    replenishmentTime: processChain.replenishmentTime,
    isActive: processChain.isActive,
    missionPrioritizationTime: processChain.missionPrioritizationTime,
    deviceTriggerBlockingTimeSeconds: processChain.deviceTriggerBlockingTimeSeconds,
    processChainGroupId: processChain.processChainGroupId,
    loadType: processChain.loadType,
  };
}

function convertEventDetailsToEventDtos(eventDetails: EventDetail[]): EventDto[] {
  return eventDetails.map(e => ({
    id: e.id,
    name: e.name,
    type: e.type,
    connectionId: e.connectionId,
    deviceId: e.deviceId,
  }));
}

function convertMissionModelToMissionDto(
  missions: Mission[],
  eventDetails: EventDetail[]
): MissionDto[] {
  return missions.map(mission => {
    const steps = mission.steps.map(step => convertStepModelToStepDtos(step, eventDetails));
    return { ...mission, steps: steps };
  });
}

export function convertStepModelToStepDtos(step: StepModel, eventDetails: EventDetail[]): StepDto {
  let convertedStep: StepDto;
  switch (step.type) {
    case StepType.Goto:
      convertedStep = { ...step, type: StepTypeBackend.Goto };
      break;
    case StepType.GotoPose:
      convertedStep = { ...step, type: StepTypeBackend.GotoPose };
      break;
    case StepType.Dock:
      convertedStep = { ...step, type: StepTypeBackend.Dock };
      break;
    case StepType.TurnDolly:
      convertedStep = { ...step, type: StepTypeBackend.TurnDolly };
      break;
    case StepType.GotoAndPushSideButton:
      convertedStep = { ...step, type: StepTypeBackend.GotoAndPushSideButton };
      break;
    case StepType.Wait:
      convertedStep = {
        sequenceNumber: step.sequenceNumber,
        type: StepTypeBackend.Wait,
        timeSpanMillis: step.timeSpanS && step.timeSpanS !== 0 ? step.timeSpanS * ONE_SECOND : 0,
      };
      break;
    case StepType.Lift:
      convertedStep = { ...step, type: StepTypeBackend.Lift };
      break;
    case StepType.Drop:
      convertedStep = { ...step, type: StepTypeBackend.Drop };
      break;
    case StepType.DockToCharge:
      convertedStep = { ...step, type: StepTypeBackend.DockToCharge };
      break;
    case StepType.GoToMapping:
      convertedStep = { ...step, type: StepTypeBackend.GoToMapping };
      break;
    case StepType.DockToMapping:
      convertedStep = { ...step, type: StepTypeBackend.DockToMapping };
      break;
    case StepType.StartToCharge:
      convertedStep = { ...step, type: StepTypeBackend.StartToCharge };
      break;
    case StepType.StopToCharge:
      convertedStep = { ...step, type: StepTypeBackend.StopToCharge };
      break;
    case StepType.WaitForSideButton:
      convertedStep = { ...step, type: StepTypeBackend.WaitForSideButton };
      break;
    case StepType.WaitForDevice:
      convertedStep = { ...step, type: StepTypeBackend.WaitForDevice };
      break;
    case StepType.WaitForScanDestination:
      convertedStep = { ...step, type: StepTypeBackend.WaitForScanDestination };
      break;
    case StepType.WaitForSapAcknowledgement:
      convertedStep = { ...step, type: StepTypeBackend.WaitForSapAcknowledgement };
      break;
    case StepType.WaitForEndOfStep: {
      let event = getEventWhichIsLinkedToStep(step, eventDetails);
      if (event === undefined) {
        event = addEvent(step, eventDetails);
      } else {
        const details = eventDetails.find(detail => detail.id === event?.id);
        if (details) {
          details.usedDuringSave = true;
        }
      }
      convertedStep = createWaitEventStep(step.sequenceNumber, event);
      break;
    }
    case StepType.Deliver:
      convertedStep = { ...step, type: StepTypeBackend.Deliver };
      break;
    case StepType.DeliverToMapping:
      convertedStep = { ...step, type: StepTypeBackend.DeliverToMapping };
      break;
    default: {
      console.error('Bad step: ', step);
      throw new Error('Unknown step type');
    }
  }
  return convertedStep;
}

function getEventWhichIsLinkedToStep(
  step: StepModel,
  eventDetails: EventDetail[]
): EventDto | undefined {
  let eventDetail: EventDetail | undefined;
  if (step.type === StepType.WaitForEndOfStep) {
    eventDetail = eventDetails.find(
      e =>
        e.type === EventType.Process &&
        e.missionIndex === step.waitForStep.missionIndex &&
        e.sequenceNumber === step.waitForStep.sequenceNumber
    );
  } else {
    eventDetail = undefined;
  }
  if (eventDetail) {
    return {
      id: eventDetail.id,
      name: eventDetail.name,
      type: EventType.Process,
      connectionId: eventDetail.connectionId,
      deviceId: eventDetail.deviceId,
    };
  } else {
    return undefined;
  }
}

function addEvent(waitForStepNew: StepModel, eventDetails: EventDetail[]): EventDto {
  let eventName = '';
  const connectionId: GuidString = '';
  const deviceId = '';
  let missionIndex = 0;
  let eventType = 0;
  let sequenceNumber = waitForStepNew.sequenceNumber;

  if (waitForStepNew.type === StepType.WaitForEndOfStep) {
    const s = waitForStepNew.waitForStep;
    eventName = `Step ${s.sequenceNumber + 1} in ${s.stepMission}`;
    eventType = EventType.Process;
    missionIndex = s.missionIndex;
    sequenceNumber = s.sequenceNumber;
  }

  const event: EventDto = {
    id: uuid.v4(),
    name: eventName,
    connectionId: connectionId,
    deviceId: deviceId,
    type: eventType,
  };

  eventDetails.push({
    id: event.id,
    type: eventType,
    name: eventName,
    missionIndex: missionIndex,
    sequenceNumber: sequenceNumber,
    usedDuringSave: true,
    connectionId: connectionId,
    deviceId: deviceId,
  });

  return event;
}

export function createWaitEventStep(sequenceNumber: number, event: EventDto): WaitForEventStepDto {
  return {
    eventId: event.id,
    eventName: event.name,
    sequenceNumber: sequenceNumber,
    type: StepTypeBackend.WaitForEvent,
  };
}

function addRaiseEventSteps(missions: MissionDto[], eventDetails: EventDetail[]): MissionDto[] {
  sortBy(eventDetails, ['missionIndex', 'sequenceNumber'])
    .filter(detail => detail.type === EventType.Process && detail.usedDuringSave)
    .reverse()
    .forEach(insertRaiseEventStepAtCorrectIndex.bind(undefined, missions));

  return missions;
}

function createRaiseEventStep(eventDetail: EventDetail): RaiseEventStepDto {
  return {
    sequenceNumber: eventDetail.sequenceNumber,
    eventId: eventDetail.id,
    eventName: eventDetail.name,
    type: StepTypeBackend.RaiseEvent,
  };
}

function insertRaiseEventStepAtCorrectIndex(
  missions: MissionDto[],
  eventDetail: EventDetail
): void {
  const raiseEventStep = createRaiseEventStep(eventDetail);
  const insertAt = eventDetail.sequenceNumber + 1;
  missions[eventDetail.missionIndex].steps.splice(insertAt, 0, raiseEventStep);
}

function updateStepSequenceNumbers(missions: MissionDto[]): MissionDto[] {
  return missions.map(mission => {
    const steps = mission.steps.map((step, index) => {
      return { ...step, sequenceNumber: index };
    });
    return { ...mission, steps };
  });
}
