import { IPillarLabelItem, MapItemType } from 'core/models';
import { BitmapText, Graphics, Point, Rectangle } from 'pixi.js';
import { FontName, MapFonts } from '../map-layer-fonts.constant';
import { GridName, GridStyle } from './pillar-grid.constant';

export class PillarLabelMapItemGraphic extends Graphics implements IPillarLabelItem {
  get id(): string {
    return this.name ?? '';
  }
  set id(val: string) {
    this.name = val;
  }

  row: number;
  column: number;
  type = MapItemType.PillarGrid;

  constructor(
    gridId: string,
    label: string,
    width: number,
    height: number,
    row: number,
    column: number
  ) {
    super();

    this.row = row;
    this.column = column;
    const graphicText = new BitmapText(` ${label} `, MapFonts[FontName.Default]);

    this.id = gridId;
    graphicText.name = label;
    graphicText.accessibleTitle = label;
    graphicText.scale.set(GridName.scale);
    graphicText.interactive = false;

    this.beginFill(GridStyle.color, GridName.alpha)
      .drawRect(0, 0, graphicText.width, graphicText.height)
      .endFill();

    this.position.copyFrom(new Point(column * width, row * height));
    this.hitArea = new Rectangle(0, 0, graphicText.width, graphicText.height);
    this.accessibleTitle = label;
    this.interactive = true;
    this.cursor = 'pointer';

    this.addChild(graphicText);
  }
}
