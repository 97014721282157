import { of, tap } from 'rxjs';
/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IntersectionZoneCollisionService, PredictedCollisionsService } from 'core/api-services';
import { catchError, concatMap, map } from 'rxjs/operators';

import * as CollisionsActions from '../actions/collision-points.actions';

import { ToastService } from 'core/services/toast.service';
import * as IntersectionZoneCollisionsActions from '../actions/intersection-collision-points.actions';

@Injectable()
export class CollisionPointsEffects {
  loadCollisionPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollisionsActions.loadCollisionPoints),
      concatMap(({ mapId }) =>
        this.collisionsService.getCollisionPointByMapIdForTrafficSettings(mapId).pipe(
          map(collisionPoints => CollisionsActions.loadCollisionPointsSuccess({ collisionPoints })),
          catchError(errorMessage =>
            of(CollisionsActions.loadCollisionPointsFailure({ errorMessage }))
          )
        )
      )
    )
  );

  loadIntersectionZoneCollisionPoints$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntersectionZoneCollisionsActions.loadIntersectionCollisionPoints),
      concatMap(({ mapId }) =>
        this.intersectionZoneCollisionService.getIntersectionZoneCollisionPointByMapId(mapId).pipe(
          map(intersectionCollisionPoints =>
            IntersectionZoneCollisionsActions.loadIntersectionCollisionPointsSuccess({
              intersectionCollisionPoints,
            })
          ),
          catchError(errorMessage =>
            of(
              IntersectionZoneCollisionsActions.loadIntersectionCollisionPointsFailure({
                errorMessage,
              })
            )
          )
        )
      )
    )
  );

  allFails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          IntersectionZoneCollisionsActions.loadIntersectionCollisionPointsFailure,
          CollisionsActions.loadCollisionPointsFailure
        ),
        tap(({ errorMessage }) => {
          this.toastService.createErrorToast(errorMessage);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly collisionsService: PredictedCollisionsService,
    private readonly intersectionZoneCollisionService: IntersectionZoneCollisionService,
    private readonly toastService: ToastService
  ) {}
}
