import { Action, createReducer, on } from '@ngrx/store';
import { ZoneType } from 'core/dtos';
import {
  ManualVehiclesFilter,
  MapsFilter,
  OccupancyStatus,
  PoiType,
  PoisFilter,
  TrafficManagementFilter,
  VehicleFilter,
  VehicleLoadStatus,
  VehicleMaintenanceStatus,
  VehicleMode,
  VehicleStatus,
  ZoneFilter,
} from 'core/models';

import * as FiltersActions from '../actions/filters.actions';

export const featureKey = 'filters';

export interface FiltersState {
  loaded: boolean;
  vehicles: VehicleFilter;
  pois: PoisFilter;
  zones: ZoneFilter;
  maps: MapsFilter;
  trafficManagement: TrafficManagementFilter;
}

export const initialState: FiltersState = {
  loaded: false,
  vehicles: {
    type: [
      {
        name: true,
        path: true,
        pathSegments: false,
      },
      {
        name: true,
        path: true,
        pathSegments: false,
      },
      {
        name: true,
        path: true,
        pathSegments: false,
      },
      {
        name: true,
        path: true,
        pathSegments: false,
      },
      {
        name: true,
        path: true,
        pathSegments: false,
      },
    ],
    mode: [
      { id: VehicleMode.Autonomous, selected: true },
      { id: VehicleMode.Manual, selected: true },
    ],
    status: [
      { id: VehicleStatus.NotInitialized, selected: true },
      { id: VehicleStatus.Busy, selected: true },
      { id: VehicleStatus.Idle, selected: true },
      { id: VehicleStatus.Charging, selected: true },
      { id: VehicleStatus.Paused, selected: true },
      { id: VehicleStatus.Error, selected: true },
      { id: VehicleStatus.NotConnected, selected: true },
      { id: VehicleStatus.SwitchedOff, selected: true },
    ],
    load: [
      { id: VehicleLoadStatus.NotLoaded, selected: true },
      { id: VehicleLoadStatus.Loaded, selected: true },
    ],
    maintenance: [
      { id: VehicleMaintenanceStatus.NotInMaintenance, selected: true },
      { id: VehicleMaintenanceStatus.Maintenance, selected: true },
    ],
    manual: [{ id: ManualVehiclesFilter.Name, selected: true }],
    search: '',
  },
  pois: {
    name: true,
    entryExitVector: true,
    occupancy: [
      { id: OccupancyStatus.Free, selected: true },
      { id: OccupancyStatus.Booked, selected: true },
      { id: OccupancyStatus.Occupied, selected: true },
    ],
    type: [
      { id: PoiType.DollyPlace, selected: true },
      { id: PoiType.TurnTableLarge, selected: true },
      { id: PoiType.ChargingStation, selected: true },
      { id: PoiType.ParkingSpace, selected: true },
      { id: PoiType.ParkingSpaceLarge, selected: true },
      { id: PoiType.WayPoint, selected: true },
      { id: PoiType.AutomaticConveyorLoading, selected: true },
      { id: PoiType.TurnTableSmall, selected: true },
      { id: PoiType.Handover, selected: true },
      { id: PoiType.AssignableWaypoint, selected: true },
      { id: PoiType.TripleTurnTable, selected: true },
      { id: PoiType.PalletStationCrosswise, selected: true },
      { id: PoiType.PalletStationLengthwise, selected: true },
      { id: PoiType.RackChangerDropoff, selected: true },
      { id: PoiType.RackChangerPickup, selected: true },
      { id: PoiType.PrePositionPoi, selected: true },
    ],
  },
  zones: {
    type: [
      { id: ZoneType.Eraser, selected: true },
      { id: ZoneType.Interaction, selected: true },
      { id: ZoneType.LimitedCapacity, selected: true },
      { id: ZoneType.NoReplanning, selected: true },
      { id: ZoneType.PreferredDirection, selected: true },
      { id: ZoneType.Restricted, selected: true },
      { id: ZoneType.SpeedLimit, selected: true },
      { id: ZoneType.Intersection, selected: true },
      { id: ZoneType.TrafficManagementCustom, selected: true },
      { id: ZoneType.AlertNow, selected: true },
      { id: ZoneType.Service, selected: true },
    ],
  },
  maps: {
    backgroundMap: true,
    baseMap: true,
    pillarsGrid: true,
    graphMap: true,
    pathPlannerMap: false,
  },
  trafficManagement: {
    collisionPoint: true,
    intersectionCollisionPoint: true,
    vehicleStoppedByTM: true,
    stoppingArea: true,
    collisionArea: true,
    deadlockArea: true,
    dependencies: true,
  },
};

const mapsReducer = createReducer(
  initialState,

  on(FiltersActions.loadFilters, state => ({
    ...state,
    loaded: false,
  })),

  on(FiltersActions.loadFiltersSuccess, FiltersActions.updateFilters, (state, { filters }) => ({
    ...state,
    ...filters,
    loaded: true,
  })),

  on(FiltersActions.resetFilters, state => ({
    ...state,
    ...initialState,
    loaded: true,
  }))
);

export function reducer(state: FiltersState | undefined, action: Action): FiltersState {
  return mapsReducer(state, action);
}

export const getLoaded = (state: FiltersState): boolean => state.loaded;
export const getVehiclesFilters = (state: FiltersState): VehicleFilter => state.vehicles;
export const getPoisFilters = (state: FiltersState): PoisFilter => state.pois;
export const getZonesFilters = (state: FiltersState): ZoneFilter => state.zones;
export const getMapsFilters = (state: FiltersState): MapsFilter => state.maps;
export const getTrafficManagementFilters = (state: FiltersState): TrafficManagementFilter =>
  state.trafficManagement;
