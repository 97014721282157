import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { InteractionZoneState, ZoneMembership } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { Observable, firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable()
export class ZoneMembershipService extends TenantHttpClient {
  private readonly servicePath = API_SERVICES.ZoneMembership;

  protected apiUrl = environment.Services.ZoneManager;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  getZonesMembershipByZoneId(masterZoneId: GuidString): Observable<ZoneMembership[]> {
    return this.get<ZoneMembership[]>(`${this.servicePath}/ByZoneId/${masterZoneId.toString()}`);
  }

  getCurrentInteractionZoneStatesByMapId(mapId: GuidString): Observable<InteractionZoneState[]> {
    return this.get<InteractionZoneState[]>(
      `${this.servicePath}/Maps/${mapId}/InteractionZoneStatus`
    );
  }

  async allowVehicleInZone(masterZoneId: GuidString, vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>({
        template: `{path}/VehicleAllowance/{zoneId}/{vehicleId}/true`,
        path: this.servicePath,
        zoneId: masterZoneId,
        vehicleId: vehicleId,
      })
    );
  }

  async excludeVehicleFromZone(masterZoneId: GuidString, vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(
        `${this.servicePath}/VehicleExclusion/${masterZoneId.toString()}/${vehicleId.toString()}/`,
        {}
      )
    );
  }

  async removeVehicleFromZone(masterZoneId: GuidString, vehicleId: GuidString): Promise<void> {
    return firstValueFrom(
      this.post<void>(
        `${this.servicePath}/VehicleRemoval/${masterZoneId.toString()}/${vehicleId.toString()}/`,
        {}
      )
    );
  }
}
