import { BMWColorsNumber, OtherColorsNumber } from './colors.enum';

export const GraphLayerColors = {
  Edges: OtherColorsNumber.OceanBlue200,
  DisconnectedEdge: OtherColorsNumber.Red,
  Arrow: OtherColorsNumber.OceanBlue250,
  NodeColor: OtherColorsNumber.LightBlue,
  NodeColorSwitch: OtherColorsNumber.OceanBlue400,
  NodePoiColor: OtherColorsNumber.GreyBlue,
  NodeOutline: OtherColorsNumber.Orange,
  NodeSelection: BMWColorsNumber.SignalBlue,
  ActionNodeColor: BMWColorsNumber.HintBlue,
};

export const RouteConfigStateColors = {
  Selected: BMWColorsNumber.HintBlue,
  Unavailable: 0xb8b8b8,
  Available: 0xabd350,
};

export enum RouteColors {
  Brown = 0x523000,
  MuddyBrown = 0x976900,
  Yellow = 0xf6c800,
  LightYellow = 0xffe767,
  Orange = 0xe96200,
  Peach = 0xff8761,
  DarkGreen = 0x084114,
  Green = 0x2a813f,
  LightGreen = 0x55c474,
  Cyan = 0x035970,
  OceanBlue = 0x0291b7,
  Blue = 0x0071c5,
  LightBlue = 0x62acff,
  Violet = 0xea7eec,
  Fuchsia = 0xc117b9,
  Purple = 0x7547ff,
  Magenta = 0x6c0061,
  Indigo = 0x4e009c,
  Lavender = 0x9f9dff,
  Red = 0xaa0014,
  LightRed = 0xff454a,
}

export const RouteConfigPickerColors: Record<RouteColors, string> = {
  [RouteColors.Yellow]: 'var(--ds-color-yellow-300)',
  [RouteColors.LightYellow]: 'var(--ds-color-yellow-200)',
  [RouteColors.Orange]: 'var(--ds-color-persimmon-500)',
  [RouteColors.Peach]: 'var(--ds-color-persimmon-400)',
  [RouteColors.Brown]: 'var(--ds-color-yellow-800)',
  [RouteColors.MuddyBrown]: 'var(--ds-color-yellow-600)',
  [RouteColors.DarkGreen]: 'var(--ds-color-green-800)',
  [RouteColors.Green]: 'var(--ds-color-green-600)',
  [RouteColors.LightGreen]: 'var(--ds-color-green-400)',
  [RouteColors.Cyan]: 'var(--ds-color-ocean-700)',
  [RouteColors.OceanBlue]: 'var(--ds-color-ocean-500)',
  [RouteColors.Blue]: 'var(--ds-color-blue-600)',
  [RouteColors.LightBlue]: 'var(--ds-color-blue-400)',
  [RouteColors.Violet]: 'var(--ds-color-fuschia-400)',
  [RouteColors.Fuchsia]: 'var(--ds-color-fuschia-600)',
  [RouteColors.Purple]: 'var(--ds-color-violet-600)',
  [RouteColors.Magenta]: 'var(--ds-color-fuschia-800)',
  [RouteColors.Indigo]: 'var(--ds-color-violet-800)',
  [RouteColors.Lavender]: 'var(--ds-color-violet-400)',
  [RouteColors.Red]: 'var(--ds-color-red-700)',
  [RouteColors.LightRed]: 'var(--ds-color-red-500)',
};
