<div class="title">
  <h5>{{ translationKey + '.title' | translate }}</h5>

  <ds-switch
    data-cy="toggle"
    *ngIf="isLoaded; else error"
    [(ngModel)]="isToggledOn"
    (ngModelChange)="onToggle($event)"
    [disabled]="disabled || doesNotHaveRequiredPermission"
    [ds-tooltip]="tooltipText"
    [dsTooltipConfig]="{ disabled: !doesNotHaveRequiredPermission }">
    {{
      toggle.isToggledOn
        ? ('settings.featureToggles.on' | translate)
        : ('settings.featureToggles.off' | translate)
    }}</ds-switch
  >
  <ng-template #error>
    <div class="error-background">
      <label data-cy="errorMessage" data-cy="errorMessage" class="label-value error-text"
        ><ds-icon class="ms-2x icon" icon="error" tone="critical"></ds-icon>
        {{ 'settings.functions.unknownValue' | translate }}
      </label>
    </div>
  </ng-template>

  <div class="triggerStatus" data-cy="loadedVehiclesToggle">
    <h5 *ngIf="loadedVehiclesToggle.isToggledOn">
      {{ 'settings.functions.parkingAndCharging.loadedVehiclesOnly' | translate }}
    </h5>
  </div>
  <app-last-changed-date dateUpdated="{{ toggle.dateUpdated }}"></app-last-changed-date>
</div>
<div class="col-9 position-description">
  <span data-cy="description">{{ description | translate }}</span>
</div>
<app-functions-modal
  [isModalOpen]="isModalOpen"
  [modalInputData]="modalInputData"
  (confirm)="onConfirm($event)">
</app-functions-modal>
