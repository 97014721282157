type PartialOrRecordKey<K extends PropertyKey, V> = Partial<Record<K, V>> | Record<K, V>;

function recordKeys<K extends PropertyKey, V>(obj: PartialOrRecordKey<K, V>): K[] {
  return Object.keys(obj) as K[];
}

export function recordKeyFromValue<K extends number, V>(
  obj: PartialOrRecordKey<K, V>,
  value: V
): K | undefined {
  const keys = recordKeys(obj);

  const key = keys.find(k => obj[k] === value);
  return key ? (Number(key) as K) : undefined;
}

function omit<T extends object, U extends keyof T>(obj: T, omittedKeys: U[]): Omit<T, U> {
  return recordKeys(obj).reduce(
    (remainingObj, propertyKey) =>
      omittedKeys.includes(propertyKey)
        ? remainingObj
        : { ...remainingObj, [propertyKey]: obj[propertyKey] },
    {} as Omit<T, U>
  );
}

function cloneDeep<T>(val: T): T {
  if (typeof val === 'object') {
    if (val === null) {
      return val;
    }

    if (Array.isArray(val)) {
      return val.map(el => cloneDeep(el)) as T;
    }

    const obj = { ...val };
    Object.entries(obj).forEach(([key, value]) => {
      obj[key] = cloneDeep(value);
    });

    return obj;
  }

  return val;
}

function notUndefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function filterNotNull<T>(items: ReadonlyArray<T | null | undefined>): T[] {
  return items.filter((it): it is T => it != null);
}

const objectHelper = { omit, cloneDeep, notUndefined };
export default objectHelper;

export function reduceNested<T1, T2, TRes>(
  arr: T1[],
  selector: (item: T1) => T2[],
  seed: TRes,
  reducer: (acc: TRes, item: T2) => TRes
): TRes {
  let result = seed;
  for (const item of arr) {
    for (const subItem of selector(item)) {
      result = reducer(result, subItem);
    }
  }
  return result;
}
