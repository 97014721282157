import { Container } from 'pixi.js';
import { MapElements } from './map-layer.constant';

export class MapStage extends Container {
  constructor() {
    super();

    this.scale.y = -1; // reverse y-scale
    this.sortableChildren = true;
    this.name = MapElements.Stage;
  }
}
