<ds-tabs
  [activeTabId]="selectedKey"
  (activeTabIdChange)="onActiveTabIdChange($event)"
  [guardSelectionChange]="guardSelectionChange"
  (requestSelectionChange)="onRequestSelectionChange($event)">
  <ds-tabs-label
    data-cy="tabData"
    *ngFor="let tab of tabs"
    for="{{ tab.key }}"
    icon="{{ tabIcon }}">
    <ng-container *ngIf="!tabTemplate">
      {{ tab.heading }}
    </ng-container>
    <ng-container *ngTemplateOutlet="tabTemplate; context: { tab }"></ng-container>
  </ds-tabs-label>

  <ds-tabs-content *ngFor="let tab of tabs" id="{{ tab.key }}">
    <div *ngIf="tab.key === selectedKey" style="width: 100%; height: 100%">
      <ng-container
        class="content"
        *ngTemplateOutlet="contentTemplate; context: { key: selectedKey }"></ng-container>
    </div>
  </ds-tabs-content>
</ds-tabs>
