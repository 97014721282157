/* eslint-disable max-lines */
import { ONE_SECOND } from 'core/constants/constant';
import {
  EventDto,
  FailureCommentDto,
  MissionDto,
  ProcessChainDto,
  ReducedMissionFailureReason,
  StepDto,
} from 'core/dtos';
import {
  EventDetail,
  EventType,
  Mission,
  ProcessChain,
  StepModel,
  StepType,
  StepTypeBackend,
  WaitForEndOfStepStepModel,
} from 'core/models';

const EVENT_ID_KEY = 'eventId';

export function mapProcessChainDtosToModels(processChainDtos: ProcessChainDto[]): ProcessChain[] {
  return processChainDtos.map(processChainDto => convertProcessChainDtoToModel(processChainDto));
}

export function convertProcessChainDtoToModel(processChainDto: ProcessChainDto): ProcessChain {
  let convertedMissions: Mission[] = [];
  let missionsCopy: MissionDto[] = [];

  const eventDetails: EventDetail[] = mapEventDetails(
    processChainDto.missions,
    processChainDto.events
  );

  const processEvent = eventDetails.find(evt => evt.type === EventType.Process);
  const waitForEventIds = getEventIds(
    processChainDto.missions,
    StepTypeBackend.WaitForEvent,
    eventDetails
  );

  const hasWaitForEventsWithoutMatchingRaiseEvents =
    waitForEventIds.length > 0 && !hasRaiseEvents(processChainDto.missions, waitForEventIds);
  const hasProcessEventInvalidSequenceNumber = processEvent && processEvent.sequenceNumber < 0;
  const isCleanProcessChain =
    !hasWaitForEventsWithoutMatchingRaiseEvents && !hasProcessEventInvalidSequenceNumber;

  if (isCleanProcessChain) {
    missionsCopy = removeRaiseEventSteps(processChainDto.missions);
    missionsCopy = removeWaitForMissionAbortSteps(missionsCopy, eventDetails);
    missionsCopy = updateStepSequenceNumbers(missionsCopy);

    const stepsEmpty = missionsCopy.some(mission => !mission.steps.length);
    if (!stepsEmpty) {
      convertedMissions = missionsCopy.reduce((missions: Mission[], mission) => {
        const convertedSteps = mission.steps.reduce((steps: StepModel[], step) => {
          const convertedStep = convertStepDtoToStepModel(step, eventDetails, missionsCopy);
          if (convertedStep) {
            return [...steps, convertedStep];
          }
          return [...steps];
        }, []);
        if (convertedSteps.length === mission.steps.length) {
          return [...missions, { ...mission, steps: convertedSteps }];
        }
        return [...missions];
      }, []);
    }
  }

  return {
    id: processChainDto.id,
    name: processChainDto.name,
    materialNumber: processChainDto.materialNumber,
    source: processChainDto.source,
    destination: processChainDto.destination,
    referenceId: processChainDto.referenceId,
    missionIds: processChainDto.missionIds,
    missions: convertedMissions,
    eventDetails: eventDetails,
    fleetId: processChainDto.fleetId,
    workAreaId: processChainDto.workAreaId,
    deviceTriggerDevice: processChainDto.deviceTriggerDevice,
    deviceTriggerNode: processChainDto.deviceTriggerNode,
    deviceTriggerValue: processChainDto.deviceTriggerValue,
    triggerType: processChainDto.triggerType,
    type: processChainDto.type,
    maxQueueLength: processChainDto.maxQueueLength,
    enableRepetitiveProcess: processChainDto.enableRepetitiveProcess,
    replenishmentTime: processChainDto.replenishmentTime,
    isActive: processChainDto.isActive,
    missionPrioritizationTime: processChainDto.missionPrioritizationTime,
    deviceTriggerBlockingTimeSeconds: processChainDto.deviceTriggerBlockingTimeSeconds,
    processChainGroupId: processChainDto.processChainGroupId,
    loadType: processChainDto.loadType,
  };
}

export function mapEventDetails(missions: MissionDto[], events: EventDto[]): EventDetail[] {
  let eventDetails: EventDetail[] = [];

  missions.forEach((mission, missionIndex) => {
    mission.steps.forEach(step => {
      if (step.type === StepTypeBackend.RaiseEvent) {
        const linkedEvent = events.find(event => event.id === step.eventId);
        if (linkedEvent && linkedEvent.type === EventType.Process) {
          eventDetails = [
            ...eventDetails,
            {
              type: linkedEvent.type,
              id: linkedEvent.id,
              missionIndex: missionIndex,
              name: linkedEvent.name,
              sequenceNumber: getTrueFrontendSequenceNumber(step, missions, events),
              connectionId: linkedEvent.connectionId,
              deviceId: linkedEvent.deviceId,
              usedDuringSave: false,
            },
          ];
        }
      }
    });
  });
  return eventDetails;
}

function getTrueFrontendSequenceNumber(
  raiseEventStep: StepDto,
  missions: MissionDto[],
  events: EventDto[]
): number {
  const mission = missions.find(m => m.steps.some(s => s === raiseEventStep));
  let sequenceNumber = raiseEventStep.sequenceNumber;
  if (mission) {
    for (const missionStep of mission.steps) {
      if (missionStep.sequenceNumber >= raiseEventStep.sequenceNumber) {
        break;
      }

      switch (missionStep.type) {
        case StepTypeBackend.RaiseEvent:
          sequenceNumber--;
          break;

        case StepTypeBackend.WaitForEvent: {
          const event = events.find(e => e.id === missionStep.eventId);
          if (event && event.type === EventType.MissionAbort) {
            sequenceNumber--;
          }
          break;
        }
      }
    }
  }

  return sequenceNumber - 1;
}

function removeRaiseEventSteps(missions: MissionDto[]): MissionDto[] {
  return missions.map(mission => {
    const steps = mission.steps.filter(step => step.type !== StepTypeBackend.RaiseEvent);
    return { ...mission, steps: steps };
  });
}

function removeWaitForMissionAbortSteps(
  missions: MissionDto[],
  eventDetails: EventDetail[]
): MissionDto[] {
  return missions.map(mission => {
    const steps = mission.steps.filter(step => {
      if (step.type === StepTypeBackend.WaitForEvent) {
        const eventDetail = eventDetails.find(detail => detail.id === step.eventId);
        if (eventDetail && eventDetail.type === EventType.MissionAbort) {
          return false;
        }
      }
      return true;
    });
    return { ...mission, steps: steps };
  });
}

function updateStepSequenceNumbers(missions: MissionDto[]): MissionDto[] {
  return missions.map(mission => {
    const steps = mission.steps.map((step, index) => {
      return { ...step, sequenceNumber: index };
    });
    return { ...mission, steps };
  });
}

export function convertReducedMissionFailureToFailureComment(
  missionFailureReason: ReducedMissionFailureReason | null
): FailureCommentDto {
  if (missionFailureReason && missionFailureReason.missionFailureReasonId !== null) {
    return {
      missionFailureReasonId: missionFailureReason.missionFailureReasonId,
      missionFailureReasonComments: missionFailureReason.missionFailureReasonComments,
      missionFailureMinutesToSolve: missionFailureReason.missionFailureMinutesToSolve,
      missionFailureMinutesForEmergencyProcess:
        missionFailureReason.missionFailureMinutesForEmergencyProcess === undefined
          ? null
          : missionFailureReason.missionFailureMinutesForEmergencyProcess,

      durationOfMissionInFailed: missionFailureReason.durationOfMissionInFailed,
      missionFailureStartDateTime: missionFailureReason.missionFailureStartDateTime,
      missionFailureEndDateTime: missionFailureReason.missionFailureEndDateTime,
      missionFailureLocationId: missionFailureReason.missionFailureLocationId,
      missionFailureShiftGroupId: missionFailureReason.missionFailureShiftGroupId,
      missionFailureReasonDe: '',
      missionFailureReasonEn: '',
      vehicleName: missionFailureReason.vehicleName,
      missionName: missionFailureReason.missionName,
    };
  }
  return {
    missionFailureReasonId: null,
    missionFailureReasonComments: null,
    missionFailureMinutesForEmergencyProcess: null,
    missionFailureMinutesToSolve: null,
    durationOfMissionInFailed: null,
    missionFailureStartDateTime: null,
    missionFailureEndDateTime: null,
    missionFailureLocationId: null,
    missionFailureShiftGroupId: null,
    vehicleName: missionFailureReason?.vehicleName || '',
    missionName: missionFailureReason?.missionName || '',
  };
}

function convertStepDtoToStepModel(
  stepDto: StepDto,
  eventDetails: EventDetail[],
  missions: MissionDto[]
): StepModel | undefined {
  let convertedStep: StepModel;
  switch (stepDto.type) {
    case StepTypeBackend.Goto:
      convertedStep = { ...stepDto, type: StepType.Goto };
      break;
    case StepTypeBackend.Dock:
      convertedStep = { ...stepDto, type: StepType.Dock };
      break;
    case StepTypeBackend.Deliver:
      convertedStep = { ...stepDto, type: StepType.Deliver };
      break;
    case StepTypeBackend.TurnDolly:
      convertedStep = { ...stepDto, type: StepType.TurnDolly };
      break;
    case StepTypeBackend.GotoAndPushSideButton:
      convertedStep = { ...stepDto, type: StepType.GotoAndPushSideButton };
      break;
    case StepTypeBackend.Wait:
      convertedStep = {
        sequenceNumber: stepDto.sequenceNumber,
        type: StepType.Wait,
        timeSpanS:
          stepDto.timeSpanMillis && stepDto.timeSpanMillis !== 0
            ? stepDto.timeSpanMillis / ONE_SECOND
            : 0,
      };
      break;
    case StepTypeBackend.Lift:
      convertedStep = { ...stepDto, type: StepType.Lift };
      break;
    case StepTypeBackend.Drop:
      convertedStep = { ...stepDto, type: StepType.Drop };
      break;
    case StepTypeBackend.DockToCharge:
      convertedStep = { ...stepDto, type: StepType.DockToCharge };
      break;
    case StepTypeBackend.GoToMapping:
      convertedStep = { ...stepDto, type: StepType.GoToMapping };
      break;
    case StepTypeBackend.DockToMapping:
      convertedStep = { ...stepDto, type: StepType.DockToMapping };
      break;
    case StepTypeBackend.DeliverToMapping:
      convertedStep = { ...stepDto, type: StepType.DeliverToMapping };
      break;
    case StepTypeBackend.StartToCharge:
      convertedStep = { ...stepDto, type: StepType.StartToCharge };
      break;
    case StepTypeBackend.StopToCharge:
      convertedStep = { ...stepDto, type: StepType.StopToCharge };
      break;
    case StepTypeBackend.WaitForSideButton:
      convertedStep = { ...stepDto, type: StepType.WaitForSideButton };
      break;
    case StepTypeBackend.WaitForDevice:
      convertedStep = {
        device: stepDto.device,
        streamingService: stepDto.streamingService,
        sequenceNumber: stepDto.sequenceNumber,
        substeps: stepDto.substeps.map(ss => ({ ...ss })),
        type: StepType.WaitForDevice,
      };
      break;
    case StepTypeBackend.WaitForScanDestination:
      convertedStep = { ...stepDto, type: StepType.WaitForScanDestination };
      break;
    case StepTypeBackend.WaitForSapAcknowledgement:
      convertedStep = { ...stepDto, type: StepType.WaitForSapAcknowledgement };
      break;
    case StepTypeBackend.WaitForEvent: {
      const eventDetail = eventDetails.find(detail => detail.id === stepDto.eventId);

      if (eventDetail?.type === EventType.Process) {
        const waitForStep: StepDto =
          missions[eventDetail.missionIndex].steps[eventDetail.sequenceNumber];

        return createWaitForEndOfStepStep(
          stepDto.sequenceNumber,
          waitForStep,
          eventDetail.missionIndex
        );
      } else {
        return undefined;
      }
    }
    case StepTypeBackend.GotoPose:
      convertedStep = { ...stepDto, type: StepType.GotoPose };
      break;
    case StepTypeBackend.RaiseEvent:
    default:
      throw new Error('Step type could not be converted.');
  }
  return convertedStep;
}

function createWaitForEndOfStepStep(
  sequenceNumber: number,
  waitForStep: StepDto,
  missionIndex: number
): WaitForEndOfStepStepModel {
  return {
    type: StepType.WaitForEndOfStep,
    sequenceNumber: sequenceNumber,
    waitForStep: {
      sequenceNumber: waitForStep.sequenceNumber,
      missionIndex: missionIndex,
    },
    waitForStepId: `${missionIndex}-${waitForStep.sequenceNumber}`,
  };
}

export function getEventIds(
  missions: MissionDto[],
  type: StepTypeBackend,
  events: EventDetail[]
): string[] {
  return missions
    .map(mission =>
      mission.steps
        .filter(
          step =>
            'eventId' in step &&
            step.type === type &&
            events.find(
              evt =>
                evt.id === step.eventId &&
                evt.type !== EventType.Connection &&
                evt.type !== EventType.Ama
            )
        )
        .map(step => step[EVENT_ID_KEY])
    )
    .filter(mission => mission.length > 0)
    .flat();
}

export function hasRaiseEvents(missions: MissionDto[], eventIds: string[]): boolean {
  return missions.some(mission =>
    mission.steps.some(
      step => step.type === StepTypeBackend.RaiseEvent && eventIds.includes(step.eventId.toString())
    )
  );
}
