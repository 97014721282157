import { ImageOffsetResponseModel, NavigationLayerResponseModel } from 'core/dtos';
import { MapLayerType } from 'core/models';
import { MapNavigationLayerColors } from 'modules/maps/constants';
import { MAXIMUM_OPACITY } from 'modules/maps/constants/map.constant';
import { covertPixelsToDistance } from 'modules/maps/helpers';

import { BitmapText, Color, Container, Graphics, Sprite, Text } from 'pixi.js';
import { IMapLayer, MapLayerBase } from '../map-layer';
import { FontName, MapFonts } from '../map-layer-fonts.constant';
import { MapLayerView } from '../map-layer-view';
import { OffsetMapLayerDrawing } from '../offset-map-layer-drawing';
import { NavigationLayerStyle, OffSetPanelStyle } from './navigation-layer.constant';

export const NavigationLayerItems = {
  layer: 0,
  panel: 1,
  border: 2,
};

export class NavigationMapLayer extends MapLayerBase implements IMapLayer {
  readonly drawing: OffsetMapLayerDrawing;
  originalLayer!: NavigationLayerResponseModel;
  protected readonly graphicContainer: Sprite | Container;
  private readonly offSetPanel: Graphics;
  private borderGraphic: Graphics;

  constructor(
    view: MapLayerView,
    protected resolution: number,
    protected navigationLayer: NavigationLayerResponseModel,
    protected layerUrl: string
  ) {
    super();

    this.originalLayer = navigationLayer;
    this.drawing = view.addChild(
      new OffsetMapLayerDrawing(navigationLayer, MapLayerType.NavigationLayer)
    );

    this.drawing.addChild(
      (this.graphicContainer = this.createGraphic(layerUrl)),
      (this.offSetPanel = this.createOffSetPanel(navigationLayer.imageOffset)),
      (this.borderGraphic = this.createBorder(MapNavigationLayerColors[0]))
    );

    this.update(navigationLayer);
  }

  createBorder(borderColor: string): Graphics {
    const border = new Graphics()
      .lineStyle(NavigationLayerStyle.border, new Color(borderColor))
      .drawRect(
        NavigationLayerStyle.borderX,
        NavigationLayerStyle.borderY,
        this.navigationLayer.widthInPixels + 2,
        this.navigationLayer.heightInPixels + 2
      );
    border.visible = false;

    return border;
  }

  createOffSetPanel(offset: ImageOffsetResponseModel): Graphics {
    const offsetPanelText = this.getOffsetPanelText(offset);

    const text = new BitmapText(` ${offsetPanelText} `, MapFonts[FontName.Default]);
    text.align = 'center';
    text.scale.set(OffSetPanelStyle.scale);
    text.position.set(NavigationLayerStyle.x, NavigationLayerStyle.y);

    const background = new Graphics()
      .beginFill(OffSetPanelStyle.panelColor)
      .drawRoundedRect(
        0,
        0,
        text.width + OffSetPanelStyle.width,
        text.height + OffSetPanelStyle.height,
        OffSetPanelStyle.rounding
      );
    background.addChild(text);
    background.position.set(
      -(text.width + OffSetPanelStyle.positionX) / 2,
      this.navigationLayer.heightInPixels + OffSetPanelStyle.positionY
    );
    background.visible = false;

    return background;
  }

  update(
    layer: NavigationLayerResponseModel,
    isVisible = false,
    opacity = (layer.opacity ?? MAXIMUM_OPACITY) / MAXIMUM_OPACITY
  ): void {
    this.navigationLayer = { ...this.navigationLayer, ...layer };

    this.setVisibility(layer.isSelected ?? isVisible);
    this.updateOffset(layer.imageOffset);
    this.setOpacity(opacity);
  }

  setBorder(visibility: boolean, borderColor: string = MapNavigationLayerColors[0]): void {
    this.drawing.removeChildAt(NavigationLayerItems.border);
    this.drawing.addChildAt(
      (this.borderGraphic = this.createBorder(borderColor)),
      NavigationLayerItems.border
    );
    this.borderGraphic.visible = visibility;
  }

  setPanel(visibility?: boolean): void {
    this.offSetPanel.visible = visibility !== undefined && visibility;
  }

  getOffset(): ImageOffsetResponseModel {
    return this.navigationLayer.imageOffset;
  }

  getOffsetPanelText(offset: ImageOffsetResponseModel): string {
    const xDistance = covertPixelsToDistance(offset.x, this.resolution);
    const yDistance = covertPixelsToDistance(offset.y, this.resolution);

    return `X: ${xDistance}m, Y: ${yDistance}m, Delta: ${offset.rotation}`;
  }

  updateOffset(offset: ImageOffsetResponseModel): void {
    this.drawing.updateOffset(offset);
    this.navigationLayer.imageOffset = offset;

    const textGraphic = this.offSetPanel.children[0] as Text;

    if (textGraphic) {
      textGraphic.text = this.getOffsetPanelText(offset);
    }
  }

  resetStyle(): void {
    this.borderGraphic.visible = false;

    this.setPanel(false);
    this.updateOffset(this.originalLayer.imageOffset);
  }

  private createGraphic(url: string): Sprite | Graphics {
    return url === '' ? new Graphics() : Sprite.from(url);
  }
}
