import { removeZoneEndPoint } from 'core/helpers';
import { GuidString, IZoneVertexMapItem, MapItemType } from 'core/models';
import { Container, Graphics, Point } from 'pixi.js';
import { calculateDistance, getCircularItem, removeConsecutiveDuplicates } from 'shared/helpers';
import { ZoneEditorStyle } from './zone.constant';

export class ZoneVertices extends Container {
  private hasSelected = false;

  get hasSelectedVertex(): boolean {
    return this.hasSelected;
  }

  get count(): number {
    return this.vertices.length;
  }

  vertices: ZoneVertex[] = [];

  constructor() {
    super();

    this.visible = false;
  }

  createVertices(zoneId: GuidString, points: Point[]): void {
    this.vertices = [];
    this.removeChildren();

    if (points.length < 2) {
      return;
    }

    removeZoneEndPoint(removeConsecutiveDuplicates(points, (p, c) => p.equals(c))).forEach(
      (p: Point, i: number) => {
        const item = new ZoneVertex(zoneId.toString(), p, i);
        this.vertices.push(item);
        this.addChild(item);
      }
    );

    this.visible = true;
  }

  getVertex(index: number): ZoneVertex | undefined {
    return this.vertices.find(v => v.index === index);
  }

  clearSelection(): void {
    if (this.hasSelectedVertex) {
      this.vertices.filter(v => v.isSelected).forEach(v => v.toggleSelection(false));
    }
    this.hasSelected = false;
  }

  setSelectedVertex(index: number): void {
    this.clearSelection();
    const item = this.getVertex(index);

    if (item) {
      item?.toggleSelection(true);
      this.hasSelected = true;
    }
  }

  getSelectedVertex(): ZoneVertex | undefined {
    return this.vertices.find(v => v.isSelected);
  }

  selectClosestVertex(vertex: ZoneVertex): void {
    const NOT_FOUND = -1;

    if (this.vertices.length < 2) {
      return;
    }

    const previous = getCircularItem(this.vertices, vertex.index - 1);
    const next = getCircularItem(this.vertices, vertex.index);

    const neighbors = [
      {
        vertex: previous,
        distance: previous ? calculateDistance(vertex.point, previous.point) : NOT_FOUND,
      },
      {
        vertex: next,
        distance: next ? calculateDistance(vertex.point, next.point) : NOT_FOUND,
      },
    ].filter(n => n.distance !== NOT_FOUND);

    if (neighbors.length === 0) {
      return;
    }

    const selectVertex =
      neighbors.length === 2
        ? neighbors[0].distance < neighbors[1].distance
          ? neighbors[0].vertex
          : neighbors[1].vertex
        : neighbors[0].vertex;

    if (selectVertex) {
      this.setSelectedVertex(selectVertex?.index);
    }
  }
}

export class ZoneVertex extends Graphics implements IZoneVertexMapItem {
  get id(): string {
    return this.name ?? '';
  }
  set id(val: string) {
    this.name = val;
  }

  index: number;
  point: Point;
  isSelected = false;
  type = MapItemType.ZoneVertex;

  private readonly size = ZoneEditorStyle.verticesSize;
  private readonly centre = this.size / 2;

  constructor(zoneId: string, point: Point, index: number) {
    super();

    this.id = zoneId;
    this.index = index;
    this.point = point;
    this.interactive = true;

    this.drawBasicVertex(this);

    this.on('mouseover', () => {
      if (this.isSelected) {
        return;
      }

      this.addChild(
        new Graphics()
          .beginFill(ZoneEditorStyle.selectedVerticesColor)
          .lineStyle(ZoneEditorStyle.verticesBorder, ZoneEditorStyle.verticesBorderColor)
          .drawRect(point.x - this.centre, point.y - this.centre, this.size, this.size)
          .endFill()
      );
    });

    this.on('mouseout', () => {
      if (this.isSelected) {
        return;
      }

      this.removeChildren();
      this.addChild(this.drawBasicVertex());
    });
  }

  drawBasicVertex(graphic: Graphics = new Graphics()): Graphics {
    return graphic
      .beginFill(ZoneEditorStyle.verticesColor)
      .lineStyle(ZoneEditorStyle.verticesBorder, ZoneEditorStyle.verticesBorderColor)
      .drawRect(this.point.x - this.centre, this.point.y - this.centre, this.size, this.size)
      .endFill();
  }

  toggleSelection(isSelected: boolean): void {
    this.isSelected = isSelected;
    this.removeChildren();

    if (this.isSelected) {
      this.addChild(
        new Graphics()
          .beginFill(ZoneEditorStyle.selectedVerticesColor)
          .lineStyle(ZoneEditorStyle.verticesBorder, ZoneEditorStyle.selectedVerticesColor)
          .drawRect(this.point.x - this.centre, this.point.y - this.centre, this.size, this.size)
          .endFill()
      );
    } else {
      this.drawBasicVertex(this);
    }
  }
}
