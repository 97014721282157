import { Container, DisplayObject } from 'pixi.js';

export class ContainerEx extends Container {
  get buttonMode(): boolean {
    return this.cursor === 'pointer';
  }

  set buttonMode(val: boolean) {
    this.cursor = val ? 'pointer' : '';
  }

  addChildOnTop(child: DisplayObject): void {
    if (this.children.length > 0) this.addChildAt(child, this.children.length - 1);
    else this.addChild(child);
  }

  addChildAtBottom(child: DisplayObject): void {
    this.addChildAt(child, 0);
  }

  setChildOnTop(child: DisplayObject): void {
    if (!this.children.find(c => child === c))
      throw new Error('Container child is not contained in the parent');

    if (this.children.length) this.setChildIndex(child, this.children.length - 1);
  }
}
