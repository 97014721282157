import { Viewport } from 'pixi-viewport';
import { Container, DisplayObject } from 'pixi.js';
import { MapItemContainer } from '../layers';
import { MapEventTarget } from '../models';

export function isContainer(item: DisplayObject | object): item is Container {
  return 'children' in item;
}

export function isMapViewPort(
  item: DisplayObject | MapEventTarget | MapItemContainer
): item is Viewport {
  return 'drag' in item && 'wheel' in item && 'addEventListener' in item;
}
