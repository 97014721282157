import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { DsTabsSelectionChangeRequest } from '@bmw-ds/components';
import { Tab } from 'library/models';
import { TabTemplateContext } from 'library/models/template-context.models';

@Component({
  selector: 'app-tab-list',
  templateUrl: './tab-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabListComponent implements OnChanges {
  @Input() tabs: Tab[] = [];
  @Input() contentTemplate!: TemplateRef<TabTemplateContext>;
  @Input() tabTemplate: TemplateRef<TabTemplateContext> | undefined;
  @Input() selectedKey: string | undefined;
  @Input() guardSelectionChange = false;

  @Output() readonly selectedTabIdChanged = new EventEmitter<string>();
  @Output() readonly tabSelectionChangeRequest = new EventEmitter<DsTabsSelectionChangeRequest>();

  tabIcon = '';

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnChanges(changes: TypedChanges<TabListComponent>): void {
    if (changes && changes.selectedKey) {
      this.cdRef.markForCheck();
    }
    this.tabIcon = this.tabTemplate ? '' : 'information';
  }

  onActiveTabIdChange($event: string): void {
    this.cdRef.markForCheck();

    this.selectedTabIdChanged.emit($event);
  }

  onRequestSelectionChange(tabSelectionRequest: DsTabsSelectionChangeRequest): void {
    this.tabSelectionChangeRequest.emit(tabSelectionRequest);
  }
}
