/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import {
  CellClickedEvent,
  ColDef,
  EditableCallbackParams,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  NewValueParams,
  RowNode,
} from 'ag-grid-community';
import { ZonesService } from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import {
  AlertNowGroupDto,
  AlertNowVehicleDto,
  AlertNowVehicleGroupDto,
  FleetDto,
  VehicleDto,
  WorkAreaSettingRecipientKeyDto,
  ZoneSetDto,
  ZoneType,
} from 'core/dtos';
import objectHelper from 'core/helpers/object.helper';
import {
  AlertNowServiceTab,
  AlertNowTabDto,
  AtsActions,
  ExtendedDsMenuItem,
  GuidString,
  IpstWorkingAreaSetting,
  ToolBarControlKeys,
  ToolBarControls,
  ToolBarItem,
  VehicleType,
  Zone,
  ZoneSetStatus,
} from 'core/models';
import {
  AtsTranslationService,
  EditBarService,
  PermissionService,
  ToastService,
  ToolbarService,
} from 'core/services';
import { ModalDialogService } from 'library/components';
import { Icons } from 'library/constants';
import { clone, cloneDeep, isEqual } from 'lodash';
import { filter, Observable, take, takeUntil } from 'rxjs';
import { AgGridActionDirective, RecipientKeyCellComponent } from 'shared/components';

import * as fromMaps from 'store-modules/maps-store';
import * as fromRoot from 'store/index';

@Component({
  selector: 'app-ipst-alertnow-settings-service',
  templateUrl: './ipst-alertnow-settings-service.component.html',
  styleUrls: ['./ipst-alertnow-settings-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertnowSettingsServiceComponent
  extends AgGridActionDirective<WorkAreaSettingRecipientKeyDto>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() vehicles: VehicleDto[] = [];
  @Input() allFleets: FleetDto[] = [];
  @Input() alertNowGroups: AlertNowGroupDto[] = [];
  @Input() workingAreaSetting: IpstWorkingAreaSetting | undefined;
  @Input() alertNowUrl: string | undefined;

  @Output() readonly save = new EventEmitter<AlertNowTabDto>();
  @Output() readonly saveAlertNowGroups = new EventEmitter<AlertNowGroupDto[]>();
  @Output() readonly deleteAlertNowGroup = new EventEmitter<AlertNowGroupDto>();

  isEditMode$: Observable<boolean>;

  mainForm: UntypedFormGroup;
  selectedWorkingAreaId: GuidString = EMPTY_GUID;
  selectedRowId: GuidString | undefined = undefined;
  canDeleteRecipientKey = true;
  showAddGroups = true;
  canEditAlertNowUrl = false;
  hasRecipientKeys = false;

  hasAtLeastOneRecipientKey = false;
  editMode = false;

  resetIcon = Icons.Reset;
  originalAlertNowUrl = '';
  recipientKeysTabName = AlertNowServiceTab.RecipientKeys;
  activeId = this.recipientKeysTabName;

  zones: Zone[] = [];
  zoneSets?: ZoneSetDto[] = [];
  originalKeys: WorkAreaSettingRecipientKeyDto[] = [];
  recipientKeys: WorkAreaSettingRecipientKeyDto[] = [];
  alertNowGroupsCopy: AlertNowGroupDto[] = [];
  TOOLBAR_ITEMS: ToolBarItem[] = [];

  columns: ColDef[] = [
    {
      field: 'recipientKey',
      tooltipField: 'type',
      headerName: 'Name',
      filter: true,
      sortable: true,
      sort: 'asc',
      cellRenderer: RecipientKeyCellComponent,
      cellRendererParams: {
        validators: [Validators.required],
        validationMessage: 'validation.fieldRequired',
      },
      editable: (params: EditableCallbackParams<WorkAreaSettingRecipientKeyDto>): boolean => {
        return params.context.componentParent.editMode;
      },
      onCellValueChanged: function (event: NewValueParams<WorkAreaSettingRecipientKeyDto>): void {
        event.context.componentParent.cdRef.markForCheck();

        if (event.data.recipientKey) {
          event.context.componentParent.editBarService.setIsFormValid(true);
          event.context.componentParent.editBarService.setHasChanges(true);
        } else {
          event.context.componentParent.editBarService.setIsFormValid(false);
          event.context.componentParent.editBarService.setHasChanges(false);
        }

        if (
          event.context.componentParent.mainForm.get('alertNowUrl')?.value.url === '' &&
          event.context.componentParent.recipientKeys.length > 0
        ) {
          event.context.componentParent.editBarService.setIsFormValid(false);
          event.context.componentParent.editBarService.setHasChanges(false);
        }

        event.context.componentParent.editBarService.onSave =
          event.context.componentParent.onSave.bind(event.context.componentParent);
      },
    },
  ];

  constructor(
    protected readonly translationService: AtsTranslationService,
    protected readonly cdRef: ChangeDetectorRef,
    protected readonly toolbarService: ToolbarService,
    private readonly modalService: ModalDialogService,
    private readonly editBarService: EditBarService,
    private readonly rootStore: Store<fromRoot.RootState>,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly toastService: ToastService,
    private readonly permissionService: PermissionService,
    private readonly zonesService: ZonesService
  ) {
    super(translationService, toolbarService, cdRef);

    this.isEditMode$ = this.rootStore.pipe(select(fromRoot.selectIsEditMode));

    this.mainForm = this.createMainForm();
    this.canEditAlertNowUrl = this.permissionService.actionAllowed(AtsActions.IpstSettingsEdit);
    this.subscribeToWorkArea();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.subscribeToFormStatusChanges();
    this.subscribeToFormValueChanges();
    this.subscribeToEditMode();

    this.originalKeys = objectHelper.cloneDeep(this.workingAreaSetting?.recipientList) ?? [];
    this.recipientKeys = objectHelper.cloneDeep(this.workingAreaSetting?.recipientList) ?? [];

    this.originalAlertNowUrl = this?.alertNowUrl ?? '';
    this.hasRecipientKeys = this.recipientKeys.length > 0;
    this.showAddGroups = this.alertNowGroups.length < 1;

    this.getZones();
    this.setAlertNowUrl();
    this.bindMethodsToEditBar();

    this.cdRef.detectChanges();
  }

  ngOnChanges({
    workingAreaSetting,
    alertNowUrl,
    alertNowGroups,
  }: TypedChanges<IpstAlertnowSettingsServiceComponent>): void {
    if (
      workingAreaSetting?.currentValue?.recipientList &&
      workingAreaSetting?.currentValue !== workingAreaSetting?.previousValue
    ) {
      this.recipientKeys = workingAreaSetting.currentValue.recipientList;
      this.hasRecipientKeys = this.recipientKeys.length > 0;
      this.gridApi?.setRowData(this.recipientKeys);
      this.editBarService.onSave = this.onSave.bind(this);
      this.gridApi?.refreshCells();
    }

    if (alertNowUrl?.currentValue && alertNowUrl?.currentValue !== alertNowUrl?.previousValue) {
      this.originalAlertNowUrl = alertNowUrl.currentValue ?? '';
    }

    if (alertNowGroups?.currentValue) {
      const alertNowGroupsCurrent = alertNowGroups?.currentValue;
      const updatedAlertNowGroups = alertNowGroupsCurrent.flatMap(group => {
        return {
          ...group,
          name: this.getName(group.masterZoneId),
          alertNowVehicleGroups: group.alertNowVehicleGroups.map(vehicleGroup =>
            this.getAlertNowVehicleGroup(vehicleGroup)
          ),
        };
      });

      this.alertNowGroups = updatedAlertNowGroups;
      this.alertNowGroupsCopy = clone(this.alertNowGroups);
      this.showAddGroups = alertNowGroups.currentValue.length < 1;
    }

    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.toolbarService.configureItems([]);
  }

  subscribeToFormStatusChanges(): void {
    this.mainForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.editBarService.setIsFormValid(status === 'VALID');
    });
  }

  subscribeToFormValueChanges(): void {
    this.mainForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.checkForChanges();
    });
  }

  subscribeToWorkArea(): void {
    this.rootStore
      .pipe(select(fromRoot.selectSelectedWorkingAreaId), takeUntil(this.ngUnsubscribe))
      .subscribe(wa => {
        this.selectedWorkingAreaId = wa;
      });
  }

  subscribeToEditMode(): void {
    this.isEditMode$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(val => {
      this.editMode = val;

      if (
        this.showAddGroups &&
        this.editMode &&
        this.activeId === AlertNowServiceTab.ZoneAlertNowGroups &&
        this.hasRecipientKeys
      ) {
        this.onAddAlertNowGroup();
      }

      this.updateActionMenuColumn();
      this.buildToolBarItems();
    });
  }

  isAllNonFleetVehiclesSelected(
    vehicleIds: GuidString[],
    alertNowVehicleIds: GuidString[]
  ): boolean {
    if (alertNowVehicleIds?.length < 1) return false;
    return vehicleIds.every(id => alertNowVehicleIds?.includes(id));
  }

  bindMethodsToEditBar(): void {
    this.editBarService.onCancel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onCancel.bind(this));
  }

  buildToolBarItems(): void {
    if (
      this.editMode &&
      this.activeId === AlertNowServiceTab.ZoneAlertNowGroups &&
      this.hasRecipientKeys
    ) {
      this.TOOLBAR_ITEMS = [
        {
          key: ToolBarControlKeys.NewZoneAlertNowGroup,
          type: ToolBarControls.Button,
          hasSeparatorBorder: false,
          command: this.onAddAlertNowGroup.bind(this),
        },
      ];

      this.toolbarService.configureItems(this.TOOLBAR_ITEMS);
    } else {
      this.toolbarService.configureItems([]);
    }
  }

  checkForChanges(): void {
    const keysChanged = !isEqual(this.recipientKeys, this.originalKeys);
    const urlChanged = !isEqual(
      this.mainForm.get('alertNowUrl')?.value.url,
      this.originalAlertNowUrl
    );
    const hasChanges = keysChanged || urlChanged;
    let isAlertUrlEmpty: boolean;

    if (this.recipientKeys.length > 0) {
      isAlertUrlEmpty = this.mainForm.get('alertNowUrl')?.value.url === '';
      this.hasAtLeastOneRecipientKey = true;
      this.editBarService.setHasChanges(
        hasChanges && this.hasAtLeastOneRecipientKey && !isAlertUrlEmpty
      );
    } else {
      isAlertUrlEmpty = false;
      this.hasAtLeastOneRecipientKey = false;
      this.editBarService.setHasChanges(
        hasChanges && !this.hasAtLeastOneRecipientKey && isAlertUrlEmpty
      );
    }

    this.editBarService.setHasChanges(hasChanges && !isAlertUrlEmpty);
  }

  createMainForm(): UntypedFormGroup {
    return this.formBuilder.group({
      alertNowUrl: ['', this.recipientKeys.length ? Validators.required : ''],
    });
  }

  setAlertNowUrl(): void {
    const alertNowControl = this.mainForm.get('alertNowUrl');
    alertNowControl?.setValue({ url: this.alertNowUrl });
    alertNowControl?.updateValueAndValidity();
    this.checkForChanges();
  }

  setGridMenuItems(): void {
    if (this.editMode) {
      this.menuItems = [
        this.createMenuItem(
          'shared.treeTable.actionMenu.setAsDefault',
          this.onSetAsDefault.bind(this),
          true,
          false
        ),
        this.createMenuItem(
          'shared.treeTable.actionMenu.delete',
          this.onDelete.bind(this),
          true,
          false
        ),
      ];
    } else {
      this.menuItems = [];
    }
  }

  updateGridMenuItems(item?: WorkAreaSettingRecipientKeyDto | undefined): void {
    this.menuItems.forEach(m => {
      m.disabled = this.getIsGridMenuItemDisabled(item, m);
    });

    this.cdRef.markForCheck();
  }

  updateRequiredValueForAlertNowUrl(): void {
    const alertNowField = this.mainForm.get('alertNowUrl');

    if (!alertNowField) return;

    if (this.recipientKeys.length > 0) {
      alertNowField.setValidators([Validators.required]);
    } else {
      alertNowField.clearValidators();
    }

    alertNowField.updateValueAndValidity();
  }

  getZoneFromActiveZoneSet(zones: Zone[]): void {
    this.rootStore
      .pipe(select(fromMaps.selectAllZoneSets), takeUntil(this.ngUnsubscribe))
      .subscribe(zoneSets => {
        this.zoneSets = zoneSets.filter(i => i.status === ZoneSetStatus.Active);
        const zoneSetIds = this.zoneSets.map(i => i.id);
        this.zones = zones.filter(zone => zoneSetIds.includes(zone.zoneSetId));
        this.cdRef.markForCheck();
      });
  }

  getIsGridMenuItemDisabled(
    item: WorkAreaSettingRecipientKeyDto | undefined,
    menuItem?: ExtendedDsMenuItem
  ): boolean {
    if (menuItem?.key !== 'shared.treeTable.actionMenu.setAsDefault') return false;
    return item === undefined || item.isDefault;
  }

  getName(masterZoneId: GuidString): string {
    if (masterZoneId === '') return '';

    const group = ` ${this.translateService.get(
      'settings.ipstAlertNowSettings.alertNowService.group'
    )}`;
    const nonExistingGroup = this.translateService.get(
      'settings.ipstAlertNowSettings.alertNowService.nonExistingGroup'
    );
    const zone = this.zones.find(i => i.masterZoneId === masterZoneId);

    return zone ? `${zone.zoneName}${group}` : nonExistingGroup;
  }

  getZones(): void {
    this.zonesService
      .getZonesByZoneType(ZoneType.AlertNow)
      .pipe(take(1))
      .subscribe(zones => {
        this.getZoneFromActiveZoneSet(zones);
        this.alertNowGroups = this.alertNowGroups.flatMap(group => {
          return {
            ...group,
            name: this.getName(group.masterZoneId),
          };
        });

        this.cdRef.markForCheck();
      });
  }

  getNonFleetVehiclesFromType(vehicleType: VehicleType): GuidString[] {
    return this.vehicles.filter(i => i.vehicleType === vehicleType && !i.fleetId).map(i => i.id);
  }

  getNonFleetVehicleIds(vehicleTypes: VehicleType[]): GuidString[] {
    const nonFleetVehicleTypes: GuidString[] = [];

    vehicleTypes.forEach(type => {
      nonFleetVehicleTypes.push(...this.getNonFleetVehiclesFromType(type));
    });

    return nonFleetVehicleTypes;
  }

  getAlertNowVehicleGroup(alertNowVehicleGroup: AlertNowVehicleGroupDto): AlertNowVehicleGroupDto {
    const alertNowFleetIds = alertNowVehicleGroup.alertNowFleets.map(i => i.id);
    const fleets: FleetDto[] = this.allFleets.filter(fleet => alertNowFleetIds.includes(fleet.id));

    const fleetVehicles: AlertNowVehicleDto[] = fleets.flatMap(fleet =>
      fleet.vehicles.map(vehicle => {
        return {
          id: vehicle.id,
          vehicleGroupId: alertNowVehicleGroup.id,
        };
      })
    );

    const nonFleetVehicles: AlertNowVehicleDto[] = this.getNonFleetVehicleIds(
      alertNowVehicleGroup.nonFleetVehicleTypes
    ).map(id => {
      return {
        id: id,
        vehicleGroupId: alertNowVehicleGroup.id,
      };
    });

    const allVehicles = fleetVehicles.concat(
      nonFleetVehicles.filter(vehicle => !fleetVehicles.some(v => v.id === vehicle.id))
    );

    return {
      ...alertNowVehicleGroup,
      alertNowFleets: [],
      alertNowVehicles: allVehicles.concat(
        alertNowVehicleGroup.alertNowVehicles.filter(
          vehicle => !fleetVehicles.some(v => v.id === vehicle.id)
        )
      ),
    };
  }

  removeDuplicates(): void {
    this.recipientKeys = Array.from(
      new Map(this.recipientKeys.map(item => [item.recipientKey, item])).values()
    );
  }

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
  }

  onTabChanged(): void {
    if (this.activeId === AlertNowServiceTab.RecipientKeys) {
      this.editBarService.onSave = this.onSave.bind(this);
    }

    this.buildToolBarItems();
  }

  onAddAlertNowGroup(): void {
    this.alertNowGroups.push({
      id: crypto.randomUUID(),
      name: '',
      workAreaId: '',
      masterZoneId: '',
      recipientList: [],
      alertNowVehicleGroups: [],
    });

    this.alertNowGroups = clone(this.alertNowGroups);
    this.editBarService.setHasChanges(false);
    this.cdRef.markForCheck();
  }

  onCancelAlertNowGroupUpdate(): void {
    this.alertNowGroups = cloneDeep(this.alertNowGroupsCopy);
    this.cdRef.markForCheck();
  }

  onAddRecipientKey(): void {
    if (this.activeId === AlertNowServiceTab.ZoneAlertNowGroups) {
      this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: true }));
      this.activeId = AlertNowServiceTab.RecipientKeys;
    }

    if (this.workingAreaSetting) {
      this.recipientKeys = [
        ...this.recipientKeys,
        {
          id: crypto.randomUUID(),
          workAreaId: this.workingAreaSetting.workingAreaId,
          recipientKey: '',
          isDefault: this.recipientKeys.length > 0 ? false : true,
          isNew: true,
        },
      ];

      this.updateRequiredValueForAlertNowUrl();
      this.gridApi.refreshCells();
      this.cdRef.markForCheck();
    }
  }

  onAddZoneAlertNowGroup(): void {
    this.rootStore.dispatch(fromRoot.setIsEditMode({ isEditMode: true }));
    this.editBarService.setHasChanges(false);
  }

  onSetAsDefault(rowNode: RowNode<WorkAreaSettingRecipientKeyDto>): void {
    this.recipientKeys.forEach(r => (r.isDefault = false));
    const node = this.recipientKeys.find(r => r.id === rowNode.data?.id);

    if (node) node.isDefault = true;

    this.recipientKeys = [...this.recipientKeys];
    this.gridApi?.redrawRows();
    this.cdRef.markForCheck();
  }

  onDelete(rowNode: RowNode<WorkAreaSettingRecipientKeyDto>): void {
    const item = rowNode.data;

    if (!this.canDeleteRecipientKey || !item) {
      return;
    }

    const translateParamsObj = {
      keyName: item.recipientKey,
    };

    this.modalService
      .createDeleteModal(
        'settings.ipstAlertNowSettings.alertNowService.deleteKeyConfiguration',
        translateParamsObj
      )
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        this.recipientKeys = this.recipientKeys.filter(r => r.id !== item?.id);

        if (item.isDefault && this.recipientKeys.length) {
          this.recipientKeys[0].isDefault = true;
        }

        this.updateRequiredValueForAlertNowUrl();
        this.gridApi?.redrawRows();
        this.cdRef.markForCheck();
      });
  }

  onDeleteAlertNowGroup(alertNowZoneGroup: AlertNowGroupDto): void {
    this.deleteAlertNowGroup.emit(alertNowZoneGroup);
  }

  onSave(): void {
    const emptyRecipientKeyExists = this.recipientKeys.some(s => !s.recipientKey);

    if (emptyRecipientKeyExists) {
      this.toastService.createErrorToast('settings.ipstAlertNowSettings.emptyRecipientKeyError');
      return;
    }

    this.removeDuplicates();

    if (this.workingAreaSetting) {
      const nodeList: WorkAreaSettingRecipientKeyDto[] = [];

      this.recipientKeys.forEach((node: WorkAreaSettingRecipientKeyDto) => {
        const item = { ...node };

        if (item.isNew) item.id = EMPTY_GUID;
        nodeList.push(item);
      });

      const updatedWorkingAreaSetting: AlertNowTabDto = {
        alertNowUrl: this.mainForm.get('alertNowUrl')?.value.url ?? this.alertNowUrl,
        recipientList: nodeList,
        workingAreaId: this.selectedWorkingAreaId,
      };

      this.workingAreaSetting = {
        ...this.workingAreaSetting,
      };

      this.editBarService.setHasChanges(false);
      this.save.emit(updatedWorkingAreaSetting);
    }
  }

  onSaveAlertNowGroup(alertNowGroups: AlertNowGroupDto[]): void {
    this.editBarService.setHasChanges(false);
    this.saveAlertNowGroups.emit(
      alertNowGroups.map(i => ({ ...i, name: this.getName(i.masterZoneId) }))
    );
  }

  onCancel(): void {
    this.recipientKeys = objectHelper.cloneDeep(this.originalKeys) ?? [];
    this.updateRequiredValueForAlertNowUrl();
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => {
    return params.data.id.toString();
  };

  onCellSelected(_event: CellClickedEvent<WorkAreaSettingRecipientKeyDto>): void {}
}
