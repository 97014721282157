import { NavigationLayerResponseModel, isNavigationLayerResponse } from 'core/dtos';
import { IMapLayerDrawing, MapLayerType } from 'core/models';
import { DisplayObject } from 'pixi.js';
import { ContainerEx } from './pixi/container-extended';

export function isMapLayerDrawing(
  drawing: DisplayObject | MapLayerDrawing
): drawing is MapLayerDrawing {
  return 'id' in drawing && 'type' in drawing;
}

export class MapLayerDrawing extends ContainerEx implements IMapLayerDrawing {
  get id(): string {
    return this.name ?? '';
  }

  set id(val: string) {
    this.name = val;
  }

  type: MapLayerType;

  constructor(identifier: string | NavigationLayerResponseModel, type: MapLayerType) {
    super();

    this.id =
      typeof identifier === 'string'
        ? identifier
        : isNavigationLayerResponse(identifier)
        ? identifier.id.toString()
        : type;

    this.type = type;
  }

  setVisibility(isVisible: boolean): void {
    this.visible = isVisible;
  }

  setOpacity(opacity: number): void {
    if (opacity > 1) {
      throw new Error('Opacity should be less than 1');
    }
    this.alpha = opacity;
  }

  setIndex(index: number): void {
    this.parent?.setChildIndex(this, index);
  }
}
