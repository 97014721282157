import {
  ContainerEx,
  DrawPointerGraphic,
  DrawZoneGraphic,
  MapLayerView,
} from 'modules/maps/layers';
import { PointerUpArgs } from 'modules/maps/models';
import { StageService, TickerCallback } from 'modules/maps/services';
import { Point } from 'pixi.js';
import { ReplaySubject, Subject, Subscription } from 'rxjs';

export enum EditorMode {
  Create,
  Edit,
}

export interface EditorData {
  shape: Point[];
  mode: EditorMode;
}

export abstract class EditorBase {
  protected readonly ngUnsubscribe = new Subject<void>();

  protected readonly shapeChange = new Subject<EditorData>();
  readonly shapeChange$ = this.shapeChange.asObservable();

  protected readonly hitBorder = new ReplaySubject<boolean>(1);
  readonly hitBorder$ = this.hitBorder.asObservable();

  protected subscriptions: Subscription[] = [];
  private _mode = EditorMode.Create;

  protected drawing: DrawZoneGraphic = new DrawZoneGraphic();
  protected pointer: DrawPointerGraphic;
  private editContainer = new ContainerEx();
  private view!: ContainerEx;

  get isStarted(): boolean {
    return this.editContainer?.parent === this.view;
  }

  protected get mode(): EditorMode {
    return this._mode;
  }

  protected set mode(val: EditorMode) {
    if (this._mode !== val) {
      this._mode = val;
      this.setPointerStyle();
    }
  }

  get isCreateMode(): boolean {
    return this.mode === EditorMode.Create;
  }

  get isEditMode(): boolean {
    return this.mode === EditorMode.Edit;
  }

  constructor(protected readonly stageService: StageService) {
    this.pointer = new DrawPointerGraphic();
  }

  protected setup(view: MapLayerView): void {
    if (!this.isStarted) {
      this.view = view;

      this.subscriptions.push(this.stageService.cursor$.subscribe(this.onPointerMove.bind(this)));

      this.subscriptions.push(this.stageService.pointerUp$.subscribe(this.onPointerUp.bind(this)));

      this.subscriptions.push(
        this.stageService.pointerDown$.subscribe(this.onPointerDown.bind(this))
      );

      this.subscriptions.push(this.stageService.rightClick$.subscribe(this.onCancel.bind(this)));

      this.subscriptions.push(this.stageService.scale$.subscribe(this.onScaleChange.bind(this)));
    }

    this.mode = EditorMode.Create;
    this.addEditContainer();
    this.movePointerToFront();
  }

  protected addEditContainer(): void {
    if (!this.editContainer?.parent) {
      this.editContainer = new ContainerEx();
      this.editContainer.addChild(this.drawing, this.pointer);
    }
    this.view.addChild(this.editContainer);
  }

  protected destroyEditContainer(): void {
    this.view?.removeChild(this.editContainer);
    this.editContainer.destroy();
  }

  protected resetPointer(): void {
    if (this.pointer) this.pointer.destroy();
    this.pointer = new DrawPointerGraphic();
    this.editContainer.addChild(this.drawing, this.pointer);
  }

  protected movePointerToFront(): void {
    this.editContainer.setChildOnTop(this.pointer);

    if (this.editContainer.parent && this.view.children.length) {
      this.view.setChildOnTop(this.editContainer);
    }
  }

  setNewGraphicStyle(): void {
    if (this.isCreateMode) {
      this.enableDrawing();
      this.drawing.reset();
    }
    this.setPointerStyle();
  }

  protected enableDrawing(enable = true): void {
    if (enable) {
      this.stageService.addTicker(TickerCallback.DrawNewZone, this.drawNewGraphic, this);
    } else {
      this.stageService.removeTicker(TickerCallback.DrawNewZone, this.drawNewGraphic, this);
    }
  }

  protected abstract setPointerStyle(): void;
  protected abstract drawNewGraphic(): void;
  protected abstract onPointerMove(point: Point): void;
  protected abstract onPointerUp(pointer: PointerUpArgs): void;
  protected abstract onPointerDown(point: Point): void;
  protected abstract onScaleChange(scale: number): void;
  protected abstract onCancel(): void;
  protected abstract destroyEditor(): void;
}
