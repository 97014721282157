import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { EMPTY_GUID } from 'core/constants';
import { LoadTypeSignalRService } from 'core/signalR/modules/load-type-signalr.service';
import { SettingsSignalRService } from 'core/signalR/modules/settings-signalr.service';
import { ensureStoreLoaded, ensureStoreLoadedWithRefresh } from 'shared/helpers';
import * as fromMaps from 'store-modules/maps-store';

import * as fromSettings from 'store-modules/settings-store';

@Injectable({
  providedIn: 'root',
})
export class SettingsGuard implements CanActivate {
  readonly settingsSignalrSubscriber;
  readonly loadTypeSignalrSubscriber;
  constructor(
    private readonly store: Store<fromSettings.SettingsFeatureState>,
    private readonly settingsSignalRService: SettingsSignalRService,
    private readonly mapsStore: Store<fromMaps.MapsFeatureState>,
    private readonly loadTypeSignalRService: LoadTypeSignalRService
  ) {
    this.settingsSignalrSubscriber = this.settingsSignalRService.signalrSubscriberFactory(
      SettingsGuard.name
    );
    this.loadTypeSignalrSubscriber = this.loadTypeSignalRService.signalrSubscriberFactory(
      SettingsGuard.name
    );
  }

  async canActivate(): Promise<boolean> {
    await Promise.all([
      ensureStoreLoaded(
        this.store,
        fromSettings.selectFleetSettingsLoaded,
        fromSettings.loadFleetSettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectTrafficSettingsLoaded,
        fromSettings.loadTrafficSettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectZoneManagerSettingsLoaded,
        fromSettings.loadZoneSettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectIpstFeaturesLoaded,
        fromSettings.loadIpstSettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectEmulatorSettingsLoaded,
        fromSettings.loadEmulatorSettings({
          workAreaId: EMPTY_GUID,
        })
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectJobSettingsLoaded,
        fromSettings.loadJobSettings()
      ),

      ensureStoreLoaded(
        this.store,
        fromSettings.selectLoadTypesLoaded,
        fromSettings.loadLoadTypes()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectMapSettingsLoaded,
        fromSettings.loadMapSettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectShutdownRequestLoaded,
        fromSettings.loadShutdownRequest()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectInfobarMessageLoaded,
        fromSettings.loadInfobarMessage()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectOrderGatewayFeaturesLoaded,
        fromSettings.loadOrderGatewaySettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectGraphManagerFeatureSettingLoaded,
        fromSettings.loadGraphManagerSettings()
      ),
      ensureStoreLoaded(
        this.store,
        fromSettings.selectGraphManagerParkingAndChargingSettingsLoaded,
        fromSettings.loadGraphManagerParkingAndChargingSettings()
      ),
    ]);

    ensureStoreLoadedWithRefresh(this.store, fromSettings.loadEvacuationDeviceSettings());
    ensureStoreLoadedWithRefresh(this.store, fromSettings.loadRolesAndPermissions());
    ensureStoreLoadedWithRefresh(this.mapsStore, fromMaps.loadZoneSets());

    await this.settingsSignalrSubscriber.joinInfobarMessage();
    await this.settingsSignalrSubscriber.joinJobManagerFeatures();
    await this.settingsSignalrSubscriber.joinMapManagerFeatures();
    await this.settingsSignalrSubscriber.joinShutDownMode();
    await this.settingsSignalrSubscriber.joinFleetManagerFeatures();
    await this.settingsSignalrSubscriber.joinTrafficManagerFeatures();
    await this.loadTypeSignalrSubscriber.joinLoadTypes();

    return true;
  }
}
