import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { AtsTranslationService } from 'core/services';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class AbstractTranslatePipe<TResult> implements OnDestroy {
  onLangChange?: Subscription = undefined;
  lastTranslationKey?: string = undefined;
  lastTranslation?: TResult = undefined;
  itemsToRemove?: number[] | string[] = undefined;
  disabledOptions?: number[] = undefined;

  constructor(
    protected atsTranslationService: AtsTranslationService,
    private readonly _ref?: ChangeDetectorRef
  ) {}

  protected getCachedOrFresh(
    key: string,
    itemsToRemove: number[] | string[],
    callback: (key: string) => TResult,
    disabledOptions: number[] = []
  ): TResult {
    if (
      this.lastTranslation &&
      key === this.lastTranslationKey &&
      this.itemsToRemove &&
      isEqual(itemsToRemove, this.itemsToRemove) &&
      this.disabledOptions &&
      isEqual(disabledOptions, this.disabledOptions)
    ) {
      return this.lastTranslation;
    } else {
      this.lastTranslationKey = key;
      this.itemsToRemove = itemsToRemove;
      this.disabledOptions = disabledOptions;
      return (this.lastTranslation = callback.call(this, key));
    }
  }

  protected ensureLangChangeReaction(): void {
    if (this.onLangChange === undefined) {
      this.onLangChange = this.atsTranslationService.onLangChange.subscribe(() => {
        if (this.lastTranslationKey && this._ref) {
          this._ref.markForCheck();
        }
        this.lastTranslation = undefined;
      });
    }
  }

  protected _dispose(): void {
    if (this.onLangChange !== undefined) {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
  }

  ngOnDestroy(): void {
    this._dispose();
  }
}
