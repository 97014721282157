<ds-tabs [(activeTabId)]="activeTabId" (activeTabIdChange)="onselectionchange()">
  <ds-tabs-label data-cy="operationTab" for="operationTab">
    {{ 'settings.functions.operation' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="operationTab">
    <app-operation
      [fleetManagerSettings]="fleetSettings$ | async"
      [jobManagerSettings]="jobManagerSettings$ | async"
      [fleetSettingsLoaded]="fleetSettingsLoaded$ | async"
      [jobManagerSettingsLoaded]="jobManagerSettingsLoaded$ | async"
      [graphManagerSettingsLoaded]="graphManagerSettingsLoaded$ | async"
      [enableGraphManager]="graphManagerSetting$ | async"
      [beginShiftSetting]="isBeginShiftModeEnabledSetting$ | async"
      [evacuationDevices]="evacuationDevices$ | async"
      [allOpcuaDevices]="allOpcuaDevices$ | async"
      [currentWorkingArea]="currentWorkingArea$ | async"
      [evacuationModeTriggerActive]="evacuationModeTriggerActive$ | async"
      [allVehicles]="allVehicles$ | async"
      (saveEndOfShiftMode)="onSaveEndOfShiftMode($event)"
      (saveShutDownMode)="onSaveShutDownMode($event)"
      (saveEvacuationMode)="onSaveEvacuationMode($event)"
      (selectOpcuaDevice)="onSelectOpcuaDevice($event)"
      (saveBeginShiftMode)="onSaveBeginShiftMode($event)"></app-operation>
  </ds-tabs-content>
  <ds-tabs-label data-cy="configurationTab" for="configurationTab">
    {{ 'settings.functions.configuration' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="configurationTab">
    <app-configuration
      [requiredPermission]="'ToggleMissionAssignment'"
      [jobManagerSettings]="jobManagerSettings$ | async"
      [loadTypeSettings]="loadTypeSettings$ | async"
      [graphManagerSettings]="graphManagerSetting$ | async"
      [graphManagerSettingsLoaded]="graphManagerSettingsLoaded$ | async"
      [graphManagerLatestDeliveryTimeAssignmentSettings]="
        graphManagerLatestDeliveryTimeAssignmentSettings$ | async
      "
      [graphManagerParkingAndChargingSettings]="graphManagerParkingAndChargingSettings$ | async"
      [graphManagerParkingAndChargingSettingsLoaded]="
        graphManagerParkingAndChargingSettingsLoaded$ | async
      "
      [trafficManagerSettings]="trafficManagerSettings$ | async"
      [jobManagerSettingsLoaded]="jobManagerSettingsLoaded$ | async"
      [trafficManagerSettingsLoaded]="trafficManagerSettingsLoaded$ | async"
      [mapManagerSettings]="mapManagerSettings$ | async"
      [mapManagerSettingsLoaded]="mapManagerSettingsLoaded$ | async"
      [workAreaId]="selectedWorkingArea"
      [zoneManagerSettings]="zoneManagerSettings$ | async"
      [zoneManagerSettingsLoaded]="zoneManagerSettingsLoaded$ | async"
      (saveParkingAndCharging)="onSaveParkingAndCharging($event)"
      (saveMissionAssignmentOptimization)="onSaveMissionAssignmentOptimization($event)"
      (saveWaitOnPoi)="onSaveWaitOnPoi($event)"
      (saveServiceZones)="onSaveServiceZones($event)"
      (saveAssignmentTrigger)="onSaveAssignmentTrigger($event)"
      (saveAmaSettings)="onSaveAmaSettings($event)"
      (saveWaitingQueueSettings)="onSaveWaitingQueueSettings($event)"
      (saveGraphManagerSettings)="onSaveGraphManagerSettings($event)"
      (saveGraphManagerParkingAndChargingSettings)="
        onSaveGraphManagerParkingAndChargingSettings($event)
      "
      (saveVehicleSmoothingSettings)="onSaveVehicleSmoothingSettings($event)"
      (saveTrafficManagementSettings)="onSaveTrafficManagementSettings($event)"
      (saveAvoidConflictAtPois)="onSaveAvoidConflictAtPois($event)"
      (saveMissionErrorHandlingDefaults)="onSaveMissionErrorHandlingDefaults($event)">
    </app-configuration>
  </ds-tabs-content>
  <ds-tabs-label data-cy="temporaryTab" for="temporaryTab">
    {{ 'settings.functions.temporary' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="temporaryTab">
    <app-temporary
      [jobManagerSettings]="jobManagerSettings$ | async"
      [mapManagerSettings]="mapManagerSettings$ | async"
      [jobManagerSettingsLoaded]="jobManagerSettingsLoaded$ | async"
      [mapManagerSettingsLoaded]="mapManagerSettingsLoaded$ | async"
      [orderGatewayFeatures]="orderGatewaySettings$ | async"
      [orderGatewayFeaturesLoaded]="orderGatewaySettingsLoaded$ | async"
      [graphManagerFeatures]="graphManagerSetting$ | async"
      [graphManagerFeaturesLoaded]="graphManagerSettingsLoaded$ | async"
      [ipstServiceFeatures]="ipstSettings$ | async"
      [ipstServiceFeaturesLoaded]="ipstSettingsLoaded$ | async"
      (saveMatrixOptimization)="onSaveMatrixOptimization($event)"
      (saveBrakeTest)="onSaveBrakeTest($event)"
      (saveIncludeTuggerTrainErrors)="onSaveIncludeTuggerTrainErrors($event)"
      (saveNewIpstToggle)="onSaveNewIpstToggle($event)"
      (savePktInterfaceToggle)="onSavePktInterfaceToggle($event)"
      (restartGraphManager)="onRestartGraphManager($event)">
    </app-temporary>
  </ds-tabs-content>
  <ds-tabs-label data-cy="changeLogTab" for="changeLogTab" *ngIf="canViewChangeLog">
    {{ 'settings.functions.changelog' | translate }}
  </ds-tabs-label>
  <ds-tabs-content id="changeLogTab">
    <app-changelog *ngIf="canViewChangeLog"></app-changelog>
  </ds-tabs-content>
</ds-tabs>
