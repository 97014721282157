<div main>
  <div [formGroup]="parkyChargyForm" *ngIf="isLoaded; else error" class="mt-4x">
    <app-toggle
      data-cy="graphManagerParkingAndChargingToggle"
      translationKey="settings.functions.graphManager.parkingCharging"
      description="settings.functions.graphManager.parkingCharging.parkingChargingDescription"
      tooltipText="{{
        ('settings.roles.permissions.Tooltip_Pretext' | translate) +
          ('settings.roles.permissions.ToggleParkyChargy' | translate) +
          ('settings.roles.permissions.Tooltip_Posttext' | translate)
      }}"
      [toggle]="parkingAndChargingToggle"
      [isLoaded]="isLoaded"
      [disabled]="disableFormGroup"
      [requiredPermission]="'ToggleParkyChargy'"
      (saveToggle)="onChangeGraphManagerParkingAndCharging($event)">
    </app-toggle>
    <label class="vehicle-text-label" data-cy="graphManagerParkingAndChargingToggleVehiclesTitle">{{
      'settings.functions.graphManager.parkingCharging.vehicles' | translate
    }}</label>
    <ds-switch formControlName="enableDsTugger" data-cy="graphManagerParkingAndChargingDsTugger">{{
      'settings.functions.graphManager.parkingCharging.dsTugger' | translate
    }}</ds-switch>
    <ds-switch
      formControlName="enableSchillerForklift"
      data-cy="graphManagerParkingAndChargingSchillerForklift"
      >{{
        'settings.functions.graphManager.parkingCharging.schillerForklift' | translate
      }}</ds-switch
    >
    <ds-switch formControlName="enableDsUagv" data-cy="graphManagerParkingAndChargingDsUagv">{{
      'settings.functions.graphManager.parkingCharging.dsUagv' | translate
    }}</ds-switch>
  </div>
</div>

<ng-template #error>
  <div class="error-background">
    <label data-cy="errorMessage" class="label-value error-text"
      ><ds-icon class="mx-2x icon" icon="error" tone="critical"></ds-icon>
      {{ 'settings.functions.unknownValue' | translate }}
    </label>
  </div>
</ng-template>
