import { ImageOffsetResponseModel } from 'core/dtos';
import { GuidString, MapItem } from 'core/models';
import { DisplayObject, Point } from 'pixi.js';
import { Observable, Subject } from 'rxjs';
import { GeometryConverter, GeometryHelper, calculateOrientationFromPoints } from 'shared/helpers';
import { MapPointerEvent } from '../models/map-pixi.models';
import {
  isMapItemContainer,
  isMapItemLabelGraphic,
  isMapItemRotationGraphic,
} from './map-item-container';
import {
  MapItemRotationContainer,
  isMapItemRotationContainer,
} from './map-item-rotation-container.graphic';
import { MapLayerDrawing, isMapLayerDrawing } from './map-layer-drawing';
import { MapElements } from './map-layer.constant';
import { ContainerEx } from './pixi';

export type MapDragTargetTypes = MapElements | GuidString | undefined;

export class MapLayerView extends ContainerEx {
  get isOnStage(): boolean {
    return this.parent !== null && this.parent.name === MapElements.Stage;
  }

  get viewport(): DisplayObject {
    if (!this.isOnStage || !this.parent.parent)
      throw new Error('MapLayerView is not added to the Viewport');

    const view = this.parent.parent;

    if (!view || view.name !== MapElements.ViewPort)
      throw new Error('MapLayerView is not a grandchild of Viewport');

    return view;
  }

  get hasDrawing(): boolean {
    return this.children.filter(isMapLayerDrawing).length > 0;
  }

  get drawing(): MapLayerDrawing {
    return this.children.filter(isMapLayerDrawing)[0];
  }

  constructor() {
    super();

    this.interactive = true;
    this.hide();
  }

  // Typically used on image layers with reversed vertical orientation
  resetImage(): void {
    this.removeChildren();
    this.setScaleReverse();
  }

  reset(resolution: number | undefined): void {
    this.removeChildren();

    if (resolution) this.setScale(resolution);
  }

  setScale(resolution: number): void {
    this.scale.set(1 / resolution);
  }

  setScaleReverse(): void {
    this.scale.set(1, -1);
  }

  updatePosition(position: ImageOffsetResponseModel, height: number | undefined): void {
    if (height) {
      this.x = position.x;
      this.y = position.y + height;
    }
  }

  remove(): void {
    this.children.forEach(c => c.destroy());
    this.removeChildren();
  }

  // #region Filter
  setChildVisibility(name: string, filter: boolean): void {
    const layer = this.getChildByName(name);
    layer && (layer.visible = filter);
  }

  hide(): void {
    this.visible = false;
  }

  show(): void {
    this.visible = true;
  }
  // #endregion

  // #region Drag & Drop
  private readonly positionMoved = new Subject<[Point, Point]>();
  positionMoved$: Observable<[Point, Point]> = this.positionMoved.asObservable();

  private readonly positionChanged = new Subject<void>();
  positionChanged$: Observable<void> = this.positionChanged.asObservable();

  private readonly rotationMoved = new Subject<number>();
  rotationMoved$: Observable<number> = this.rotationMoved.asObservable();

  private readonly rotationChanged = new Subject<number>();
  rotationChanged$: Observable<number> = this.rotationChanged.asObservable();

  removedDragAndDrop = new Subject<void>();
  pauseDragAndDrop = false;
  private dragTarget: MapDragTargetTypes;
  private selectedItem: MapItem | undefined;
  private rotationItem: MapItemRotationContainer | undefined;
  private hasMoved = false;
  // eslint-disable-next-line @typescript-eslint/prefer-readonly
  private startPosition = new Point();

  private fnEnableMapDrag: ((enable: boolean) => void) | undefined;

  get dragItemId(): GuidString | undefined {
    return this.isDragSetup ? this.selectedItem?.id : undefined;
  }

  get isDragSetup(): boolean {
    return this.selectedItem !== undefined;
  }

  get isRotationEnabled(): boolean {
    return this.rotationItem !== undefined && this.fnEnableMapDrag !== undefined;
  }

  addDragAndDrop(item: MapItem, fnEnableMapDrag: (enable: boolean) => void): void {
    if (!((this.isOnStage && this.interactive && this.hasDrawing) || this.isDragSetup)) return;

    this.selectedItem = item;
    this.rotationItem = this.getSelectedViewItem(item);
    this.fnEnableMapDrag = fnEnableMapDrag;

    this.on('pointerdown', this.onDragStart, this)
      .on('pointerup', this.onDragEnd, this)
      .on('pointerupoutside', this.onDragEnd, this);
  }

  removeDragAndDrop(): void {
    this.rotationItem = undefined;
    this.selectedItem = undefined;
    this.fnEnableMapDrag = undefined;
    this.removedDragAndDrop.next();

    this.off('pointerdown', this.onDragStart, this)
      .off('pointerup', this.onDragEnd, this)
      .off('pointerupoutside', this.onDragEnd, this);
  }

  private onDragStart(event: MapPointerEvent): void {
    if (this.pauseDragAndDrop) return;

    if (
      !(
        isMapItemContainer(event.target) ||
        isMapItemLabelGraphic(event.target) ||
        isMapItemRotationGraphic(event.target)
      )
    )
      return;

    const item = event.target;
    const targetItem = item.accessibleTitle ? item.parent.parent : item;

    if (this.selectedItem?.id !== targetItem.name && targetItem.name !== MapElements.Rotation)
      return;

    this.allowMapDrag(false);
    this.subscribeToPointerMove(true);

    this.dragTarget = targetItem.name;
    this.hasMoved = false;

    event.getLocalPosition(event.target, this.startPosition);
  }

  private onDragEnd(event: MapPointerEvent): void {
    if (this.hasMoved) {
      if (this.dragTarget === MapElements.Rotation && this.rotationItem) {
        const radian = GeometryHelper.calculateOrientationFromPoints(
          this.rotationItem.position,
          event.getLocalPosition(this)
        );

        this.rotationChanged.next(radian);
      } else if (this.selectedItem) {
        this.positionChanged.next();
      }
    }

    this.hasMoved = false;
    this.dragTarget = undefined;

    this.subscribeToPointerMove(false);
    this.allowMapDrag(true);
  }

  private onDragMove(event: MapPointerEvent): void {
    if (this.dragTarget) {
      const point = event.getLocalPosition(this);
      this.hasMoved = true;

      if (this.dragTarget === MapElements.Rotation && this.rotationItem) {
        const radian = GeometryConverter.radianToPositiveValue(
          calculateOrientationFromPoints(this.rotationItem.position, point)
        );
        this.rotationItem.setMoveRotation(radian);
        this.rotationMoved.next(radian);
      } else {
        this.positionMoved.next([point, this.startPosition]);
      }
    }
  }

  private subscribeToPointerMove(enable: boolean): void {
    if (enable) this.viewport.on('pointermove', this.onDragMove, this);
    else this.viewport.off('pointermove', this.onDragMove);
  }

  private getSelectedViewItem(item: MapItem): MapItemRotationContainer | undefined {
    return (
      this.drawing.children.filter(isMapItemRotationContainer).find(i => i.id === item.id) ??
      this.drawing.parent.children.filter(isMapItemRotationContainer).find(i => i.id === item.id) // Rotation Item on the View?
    );
  }

  private allowMapDrag(allow: boolean) {
    if (this.fnEnableMapDrag) {
      this.fnEnableMapDrag(allow);
    }
  }
  // #endregion
}
