/* eslint-disable max-lines */
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EMPTY_GUID } from 'core/constants';
import {
  BaseVehicleDto,
  ErrorAggregate,
  FleetDto,
  MapNavigationDto,
  VehicleDetailsSignalRDto,
  VehicleDto,
  VehicleListSignalrDto,
} from 'core/dtos';
import { getTrafficLightStatus } from 'core/helpers';
import objectHelper from 'core/helpers/object.helper';
import {
  BasicMenuItem,
  BatteryLevelStatus,
  Fleet,
  GuidString,
  HardwareVersion,
  LoadType,
  ReducedVehicle,
  SoftwareUpdateStatus,
  TreeTableVehicle,
  VehicleAvailability,
  VehicleInterfaceType,
  VehicleMode,
  VehicleStatus,
  VehicleType,
} from 'core/models';
import { VehicleSendStepInputModel } from 'shared/components';

const fleetId1 = 'dfcbe822-39b7-4f65-b87f-831397f87b39';
const fleetName1 = 'Fleet 1';
const fleetId2 = '5d6e026a-4545-4d09-af7f-3ab8d5986473';
const fleetName2 = 'Fleet 2';

const mapId1 = 'bb2b55bc-dd94-4262-a5af-966431d57b31';
const mapId2 = '03840cd1-a25a-4c33-a7ba-1fc05e3f0212';
const mapName1 = 'map 1';
const mapName2 = 'map 2';
const defaultDate = '1970-01-01T00:00:40.000Z';
const softwareUpdateDate = new Date('2021-03-11T10:44:43.8213628Z');
const vehicleId = 'dfcbe822-39b7-4f65-b87f-831397f87b123';
const fourAm1 = '4am 567';
const ipAddress1 = '10.245.204.29:7123';

const baseVehicleDto1: BaseVehicleDto = {
  internalIdentifier: 'baseVehicleDto1',
  name: 'baseVehicleDto1',
  ipAddress: '10.0.0.1',
  vehicleType: VehicleType.UnitLoad,
  interfaceType: VehicleInterfaceType.Ros,
  maintenanceModeEnabled: false,
  isErrorForwardingEnabled: true,
  hardwareVersion: HardwareVersion.Unknown,
  supportedLoadTypes: [],
  loadType: LoadType.Unknown,
};

const vehicleDtoInFleet1: VehicleDto = {
  id: vehicleId,
  fleetId: fleetId1,
  fleetName: fleetName1,
  internalIdentifier: 'Vehicle1',
  name: 'Vehicle 1',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  hasError: false,
  isRetired: false,
  isLoaded: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  isConnected: false,
  isSwitchedOff: false,
  brakeTestRequired: false,
  status: VehicleStatus.NotConnected,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: 'vehicleDtoInFleet1 NL',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.UnitLoad,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.StrVersion3,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const vehicleDtoInFleet2: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f87b3b',
  fleetId: fleetId1,
  fleetName: fleetName1,
  internalIdentifier: 'Vehicle2',
  name: 'Vehicle 2',
  ipAddress: '10.245.204.29:7001',
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 43, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: true,
  isSwitchedOff: false,
  vehicleType: VehicleType.UnitLoad,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.StrVersion4,
  supportedLoadTypes: [
    LoadType.GltPalletCrosswise,
    LoadType.GltPalletLengthwise,
    LoadType.GltStrStandard,
  ],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const vehicleDtoInFleet3: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f87b3a',
  fleetId: fleetId2,
  fleetName: fleetName2,
  internalIdentifier: 'Vehicle3',
  name: 'Vehicle 3',
  ipAddress: '10.245.204.29:7002',
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: false,
  isSwitchedOff: false,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.3',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 44, y: 12, orientation: 4 },
  map: {
    id: mapId2,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.UnitLoad,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.Unknown,
  supportedLoadTypes: [],
  loadType: LoadType.Unknown,
  loadOrientation: 0,
};

const vehicleDtoInFleetWithoutMap: VehicleDto = {
  ...vehicleDtoInFleet1,
  id: 'noMapId',
  map: undefined,
};

const vehicleDtoWithoutFleet4: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f87b3c',
  fleetName: '',
  internalIdentifier: 'Vehicle4',
  name: 'Vehicle 4',
  ipAddress: '10.245.204.29:7003',
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: false,
  isSwitchedOff: true,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.4',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 46, y: 12, orientation: 4 },
  map: {
    id: mapId2,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.UnitLoad,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  fleetId: null,
  hardwareVersion: HardwareVersion.Unknown,
  supportedLoadTypes: [LoadType.GltStrStandard, LoadType.GltPalletCrosswise],
  loadType: LoadType.Unknown,
  loadOrientation: 0,
};

const tuggerTrainVehicleDto5: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f87678',
  fleetName: '',
  internalIdentifier: 'Vehicle5',
  name: 'Vehicle 5',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: true,
  isSwitchedOff: false,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: fourAm1,
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 46, y: 12, orientation: 4 },
  map: {
    id: mapId2,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.TuggerTrain,
  interfaceType: VehicleInterfaceType.Vda5050,
  forkLength: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  fleetId: null,
  trailers: [],
  hardwareVersion: HardwareVersion.TuggerTrainFourAmV2,
  supportedLoadTypes: [],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const tuggerTrainVehicleDto6: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f87678',
  fleetName: '',
  internalIdentifier: 'Vehicle6',
  name: 'Vehicle 6',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: false,
  isSwitchedOff: false,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: fourAm1,
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 46, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.TuggerTrain,
  interfaceType: VehicleInterfaceType.Vda5050,
  forkLength: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  fleetId: null,
  trailers: [],
  hardwareVersion: HardwareVersion.TuggerTrainDsV1,
  supportedLoadTypes: [],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const tuggerTrainVehicleDto7: VehicleDto = {
  id: 'a860c7c8-2937-4db2-a237-d0730f79aaaf',
  fleetName: '',
  internalIdentifier: 'Vehicle7',
  name: 'Vehicle 7',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: false,
  isSwitchedOff: false,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: fourAm1,
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 46, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.TuggerTrain,
  interfaceType: VehicleInterfaceType.Vda5050,
  forkLength: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  fleetId: null,
  trailers: [],
  hardwareVersion: HardwareVersion.TuggerTrainFourAmV2,
  supportedLoadTypes: [],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const tuggerTrainVehicleDtoWithPath: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f87679',
  fleetName: '',
  internalIdentifier: 'VehicleWithPath',
  name: 'VehicleWithPath',
  ipAddress: '10.245.204.29:7124',
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: true,
  isSwitchedOff: false,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: true,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: fourAm1,
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 55.3, y: 35.4, orientation: 3.1416 },
  map: {
    id: mapId2,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [
    { x: 55.3, y: 35.4, theta: 0 },
    { x: 53.8, y: 35.4, theta: 0 },
    { x: 53.7, y: 35.3, theta: 0 },
    { x: 35.5, y: 35.3, theta: 0 },
    { x: 31.51, y: 31.28, theta: 0 },
  ],
  trailers: [
    {
      trailerId: 'Trailer 1',
      trailerPosition: {
        orientation: 0,
        x: 57.1,
        y: 35.4,
      },
    },
    {
      trailerId: 'Trailer 2',
      trailerPosition: {
        orientation: 0.02,
        x: 58.9,
        y: 35.41,
      },
    },
    {
      trailerId: 'Trailer 3',
      trailerPosition: {
        orientation: 0.06,
        x: 60.7,
        y: 35.46,
      },
    },
    {
      trailerId: 'Trailer 4',
      trailerPosition: {
        orientation: 0,
        x: 62.5,
        y: 35.6,
      },
    },
  ],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.TuggerTrain,
  interfaceType: VehicleInterfaceType.Vda5050,
  forkLength: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  fleetId: null,
  hardwareVersion: HardwareVersion.Unknown,
  supportedLoadTypes: [],
  loadType: LoadType.Unknown,
  loadOrientation: 0,
};

const forkliftVehicleDto1: VehicleDto = {
  id: 'dfcbe822-39b7-4f65-b87f-831397f77679',
  fleetName: '',
  internalIdentifier: 'Forklift',
  name: 'Forklift',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isConnected: false,
  isSwitchedOff: false,
  isRetired: false,
  lastStateMessageEnqueuedUtc: defaultDate,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '4am 568',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 46, y: 12, orientation: 4 },
  map: {
    id: mapId2,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  vehicleType: VehicleType.Forklift,
  interfaceType: VehicleInterfaceType.Vda5050,
  forkLength: 150,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  fleetId: null,
  hardwareVersion: HardwareVersion.ForkliftFourAm_20t,
  supportedLoadTypes: [],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const uagv1Vehicle: VehicleDto = {
  id: vehicleId,
  fleetName: '',
  fleetId: null,
  internalIdentifier: 'agv1',
  name: 'agv 1',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.U_AGV,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.DSType1,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};
const uagv2Vehicle: VehicleDto = {
  id: vehicleId,
  fleetName: '',
  fleetId: null,
  internalIdentifier: 'agv2',
  name: 'agv 2',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.U_AGV,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.DSType2,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const uagv3Vehicle: VehicleDto = {
  id: vehicleId,
  fleetName: '',
  fleetId: null,
  internalIdentifier: 'agv3',
  name: 'agv 3',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.U_AGV,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.DSType3,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const uagv4Vehicle: VehicleDto = {
  id: vehicleId,
  fleetName: '',
  fleetId: null,
  internalIdentifier: 'agv4',
  name: 'agv 4',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.U_AGV,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.DSType4,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const uagv5Vehicle: VehicleDto = {
  id: vehicleId,
  fleetName: '',
  fleetId: null,
  internalIdentifier: 'agv5',
  name: 'agv ',
  ipAddress: '10.245.204.29:7000',
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.U_AGV,
  interfaceType: VehicleInterfaceType.Ros,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.DSType5,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const dsForkliftVehicle: VehicleDto = {
  id: vehicleId,
  fleetName: '',
  fleetId: null,
  internalIdentifier: 'agv1',
  name: 'agv 1',
  ipAddress: ipAddress1,
  initializationDateTime: defaultDate,
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.Idle,
  softwareDetails: {
    softwareVersion: '1.2.2',
    iotHubSdkVersion: '4.1',
    atsInterfaceVersion: '0.3',
  },
  softwareVersionChangedDateUtc: softwareUpdateDate.toISOString(),
  softwareUpdateStatus: SoftwareUpdateStatus.NoUpdate,
  softwareDownloadPercentage: 0,
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  pose2D: { x: 45, y: 12, orientation: 4 },
  map: {
    id: mapId1,
    navigationLayerId: '',
  },
  maintenanceModeEnabled: true,
  isErrorForwardingEnabled: false,
  velocity: {
    vx: 0,
    vy: 0,
    omega: 0,
  },
  path: [],
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.Forklift,
  interfaceType: VehicleInterfaceType.Vda5050,
  forkLength: 0,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  vehicleKey: EMPTY_GUID.toString(),
  hardwareVersion: HardwareVersion.DsAmadeus,
  supportedLoadTypes: [LoadType.GltStrStandard],
  loadType: LoadType.GltStrStandard,
  loadOrientation: 0,
};

const fleet1: Fleet = {
  id: fleetId1,
  name: fleetName1,
  comments: 'This is the comment section for fleet 11',
  mapId: mapId1,
  mapName: 'Map 1',
  loadType: LoadType.Unknown,
  vehicles: [vehicleDtoInFleet1, vehicleDtoInFleet2],
};

const fleet2: Fleet = {
  id: fleetId2,
  name: fleetName2,
  comments: 'This is the comment section for fleet 2',
  mapId: mapId2,
  mapName: 'Map 2',
  loadType: LoadType.Unknown,
  vehicles: [vehicleDtoInFleet3],
};

const fleetDto1: FleetDto = {
  id: fleetId1,
  name: fleetName1,
  comments: 'This is the comment section for fleet 1',
  mapId: mapId1,
  loadType: LoadType.Unknown,
  vehicles: [vehicleDtoInFleet1],
};

const fleetDto2: FleetDto = {
  id: fleetId2,
  name: fleetName2,
  comments: 'This is the comment section for fleet 2',
  mapId: mapId2,
  loadType: LoadType.Unknown,
  vehicles: [vehicleDtoInFleet1, vehicleDtoInFleet2],
};

const vehicleSignalrDto1: VehicleListSignalrDto = {
  id: vehicleId,
  flags: [false, false, false, false, false, false, false, false, false, false],
  mode: VehicleMode.Autonomous,
  name: '',
  timestamp: '',
  wa: 'waId',
  bat: 0, // batteryLevel
  batStatus: 0, // batteryLevelStatus
  fleetId: null,
  fleetName: 'newFleetName',
  ip: '', // ipAddress
  lsmp: new Date(), // lastStateMessageProcessedUtc
  iid: '', // internalIdentifier
  mapId: 'mapId',
  zsId: 'zonesetId', // zoneSetId
  dzsId: 'zonesetId', // desiredZoneSetId
  sd: [null, '4.1', '0.3'], // softwareDetails
  swChg: softwareUpdateDate, // softwareVersionChangedDateUtc
  sus: SoftwareUpdateStatus.NoUpdate, // softwareUpdateStatus
  sdpc: 0, // softwareDownloadPercentage
  type: VehicleType.UnitLoad, // vehicleType
  avl: VehicleAvailability.Available, // availability
  status: VehicleStatus.Idle,
  tCnt: 0, // trailerCount
  metrics: [new Date(), new Date(), new Date(), new Date(), null],
  slt: [], // supportedLoadTypes
  os: null, // orderState
};

const vehicleDetailsDto: VehicleDetailsSignalRDto = {
  id: vehicleId,
  timestamp: '638041910526508984',
  internalIdentifier: 'Vehicle1',
  name: 'Vehicle 1',
  ipAddress: '10.245.204.29:7000',
  availability: VehicleAvailability.NotAvailable,
  isRetired: false,
  lastStateMessageProcessedUtc: defaultDate,
  lastStateMessageEnqueuedUtc: defaultDate,
  mode: VehicleMode.Autonomous,
  isLoaded: false,
  hasError: false,
  isPaused: false,
  isCharging: false,
  isBusy: false,
  brakeTestRequired: false,
  status: VehicleStatus.NotConnected,
  softwareVersion: 'STR_release_2.5.1',
  batteryLevel: 90,
  batteryLevelStatus: BatteryLevelStatus.Green,
  mapId: mapId1,
  maintenanceModeEnabled: true,
  workAreaId: EMPTY_GUID,
  isConnected: false,
  isSwitchedOff: false,
  vehicleType: VehicleType.UnitLoad,
  trailerCount: 0,
  zoneSetId: EMPTY_GUID,
  desiredZoneSetId: EMPTY_GUID,
  isErrorForwardingEnabled: false,
};

const vehicleError: ErrorAggregate = {
  description: 'Fleet Error',
  fleetId: null,
  fleetName: '',
  isDeleted: false,
  isVehicleMaintenanceModeEnabled: false,
  level: 'fatal',
  location: '',
  mapId: mapId1,
  mapName: mapName1,
  missionId: null,
  missionName: '',
  missionProvisioningDateTimeUtc: null,
  missionStepDetails: '',
  occurredDateTimeUtc: defaultDate,
  sendNotification: true,
  type: 'ATS_JOB_10',
  vehicleId: vehicleId,
  vehicleName: 'STR001-01-01',
  workAreaId: 'aacfe202-6852-4522-865b-76415b58bfdd',
};

export class VehiclesTestHelper {
  static getFleetError(): ErrorAggregate {
    return { ...vehicleError };
  }

  static getVehicleDetailsDto(): VehicleDetailsSignalRDto {
    return objectHelper.cloneDeep(vehicleDetailsDto);
  }

  static getFleet(): Fleet {
    return { ...fleet1 };
  }

  static getFleet2(): Fleet {
    return { ...fleet2 };
  }

  static getFleets(): Fleet[] {
    return [{ ...fleet1 }, { ...fleet2 }];
  }

  static getFleetDto(): FleetDto {
    return { ...fleetDto1 };
  }

  static getFleetDto2(): FleetDto {
    return { ...fleetDto2 };
  }

  static getFleetDtos(): FleetDto[] {
    return [{ ...fleetDto1 }, { ...fleetDto2 }];
  }

  static getTwoReducedVehicles(): ReducedVehicle[] {
    const v1 = { ...this.getReducedVehicle1() };
    const v2 = { ...this.getReducedVehicle2() };
    return [v1, v2];
  }

  static getAllReducedVehicles(): ReducedVehicle[] {
    const v1 = { ...this.getReducedVehicle1() };
    const v2 = { ...this.getReducedVehicle2() };
    const v3 = { ...this.getReducedVehicle3InFleet() };
    const v4 = { ...this.getReducedVehicle4WithoutFleet() };
    const v5 = { ...this.getReducedTuggerTrainVehicle5() };
    return [v1, v2, v3, v4, v5];
  }

  static getUnitLoadReducedVehicles(): ReducedVehicle[] {
    const v1 = { ...this.getReducedVehicle1() };
    const v2 = { ...this.getReducedVehicle2() };
    const v3 = { ...this.getReducedVehicle3InFleet() };
    const v4 = { ...this.getReducedVehicle4WithoutFleet() };
    return [v1, v2, v3, v4];
  }

  static getZoneEnabledReducedVehicles(): ReducedVehicle[] {
    const v1 = { ...this.getReducedVehicle1() };
    const v2 = { ...this.getReducedTuggerTrainVehicle6() };
    const v3 = { ...this.getReducedVehicle3InFleet() };
    const v4 = { ...this.getReducedVehicle4WithoutFleet() };
    const v5 = { ...this.getReducedUAGV1() };
    const v6 = { ...this.getReducedUAGV2() };
    const v7 = { ...this.getReducedUAGV3() };
    const v8 = { ...this.getReducedUAGV4() };
    const v9 = { ...this.getReducedUAGV5() };
    return [v1, v2, v3, v4, v5, v6, v7, v8, v9];
  }

  static getBaseVehicleDto(): BaseVehicleDto {
    return { ...baseVehicleDto1 };
  }

  static getVehicleDto(): VehicleDto {
    return { ...vehicleDtoInFleet1 };
  }

  static getVehicleListSignalrDto(): VehicleListSignalrDto[] {
    return [vehicleSignalrDto1];
  }

  static getVehicle2Dto(): VehicleDto {
    return { ...vehicleDtoInFleet2 };
  }

  static getVehicle3Dto(): VehicleDto {
    return { ...vehicleDtoInFleet3 };
  }

  static getVehicleDtoWithoutMap(): VehicleDto {
    return { ...vehicleDtoInFleetWithoutMap };
  }

  static getVehicleUAGV(): VehicleDto {
    return {
      ...vehicleDtoInFleet1,
      vehicleType: VehicleType.U_AGV,
      hardwareVersion: HardwareVersion.DSType1,
    };
  }

  static getVehicleDtos(): VehicleDto[] {
    return [{ ...vehicleDtoInFleet1 }, { ...vehicleDtoInFleet2 }];
  }

  static getAllVehicleDtos(): VehicleDto[] {
    return [
      { ...vehicleDtoInFleet1 },
      { ...vehicleDtoInFleet2 },
      { ...vehicleDtoInFleet3 },
      { ...vehicleDtoWithoutFleet4 },
      { ...tuggerTrainVehicleDto5 },
      { ...forkliftVehicleDto1 },
    ];
  }

  static getAllVehicleDtosTypes(): VehicleDto[] {
    return [
      { ...vehicleDtoInFleet3 },
      { ...vehicleDtoWithoutFleet4 },
      { ...tuggerTrainVehicleDto5 },
      { ...tuggerTrainVehicleDto6 },
      { ...tuggerTrainVehicleDto7 },
      { ...forkliftVehicleDto1 },
      { ...tuggerTrainVehicleDtoWithPath },
      { ...uagv1Vehicle },
      { ...dsForkliftVehicle },
    ];
  }

  static getVehicleDtosWithTuggerTrain(): VehicleDto[] {
    return [{ ...vehicleDtoInFleet1 }, { ...vehicleDtoInFleet2 }, { ...tuggerTrainVehicleDto5 }];
  }

  static getVehicleDtosWithOutStr(): VehicleDto[] {
    return [{ ...tuggerTrainVehicleDto5 }, { ...forkliftVehicleDto1 }];
  }

  static getVehicleDtosWithOutTuggerTrain(): VehicleDto[] {
    return [{ ...vehicleDtoInFleet1 }, { ...vehicleDtoInFleet2 }];
  }

  static getVehicleTugger(): VehicleDto {
    return { ...tuggerTrainVehicleDto5 };
  }

  static getTuggerTrainDtoWithPath(): VehicleDto {
    return { ...tuggerTrainVehicleDtoWithPath };
  }

  static getVehicleDtoWithoutFleet(): VehicleDto {
    return { ...vehicleDtoWithoutFleet4 };
  }

  static getVehicleDtoWithoutFleet2(): VehicleDto {
    return {
      ...vehicleDtoWithoutFleet4,
      map: {
        id: mapId1,
        navigationLayerId: '',
      },
    };
  }

  static getReducedVehicle1(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...vehicleDtoInFleet1,
      mapId: vehicleDtoInFleet1.map?.id,
      lastStateMessageProcessedUtc: vehicleDtoInFleet1.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: vehicleDtoInFleet1.workAreaId,
      isActiveZoneSet: true,
      zoneSetName: 'active',
      hardwareVersion: HardwareVersion.StrVersion2,
      supportedLoadTypes: [LoadType.Uglt],
    };
  }

  static getReducedVehicle2(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...vehicleDtoInFleet2,
      mapId: vehicleDtoInFleet2.map?.id,
      lastStateMessageProcessedUtc: vehicleDtoInFleet2.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: vehicleDtoInFleet2.workAreaId,
      isActiveZoneSet: false,
      /* eslint-disable @typescript-eslint/no-magic-numbers */
      supportedLoadTypes: [LoadType.GltStrStandard],
    };
  }

  static getReducedVehicle3InFleet(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...vehicleDtoInFleet3,
      mapId: vehicleDtoInFleet3.map?.id,
      lastStateMessageProcessedUtc: vehicleDtoInFleet3.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: vehicleDtoInFleet3.workAreaId,
      isActiveZoneSet: true,
      hardwareVersion: HardwareVersion.StrVersion3,
    };
  }

  static getReducedVehicle4WithoutFleet(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...vehicleDtoWithoutFleet4,
      mapId: vehicleDtoWithoutFleet4.map?.id,
      lastStateMessageProcessedUtc: vehicleDtoWithoutFleet4.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: vehicleDtoWithoutFleet4.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedUAGV1(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...uagv1Vehicle,
      mapId: mapId1,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto5.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto5.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedUAGV2(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...uagv2Vehicle,
      mapId: mapId1,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto5.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto5.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedUAGV3(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...uagv3Vehicle,
      mapId: mapId1,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto5.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto5.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedUAGV4(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...uagv4Vehicle,
      mapId: mapId1,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto5.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto5.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedUAGV5(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...uagv5Vehicle,
      mapId: mapId1,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto5.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto5.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedTuggerTrainVehicle5(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...tuggerTrainVehicleDto5,
      mapId: tuggerTrainVehicleDto5.map?.id,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto5.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto5.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedTuggerTrainVehicle6(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...tuggerTrainVehicleDto6,
      mapId: tuggerTrainVehicleDto6.map?.id,
      lastStateMessageProcessedUtc: tuggerTrainVehicleDto6.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: tuggerTrainVehicleDto6.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedForkliftVehicle6(): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...forkliftVehicleDto1,
      mapId: forkliftVehicleDto1.map?.id,
      lastStateMessageProcessedUtc: forkliftVehicleDto1.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: forkliftVehicleDto1.workAreaId,
      isActiveZoneSet: true,
    };
  }

  static getReducedManualForkliftVehicle(hardwareVersion: HardwareVersion): ReducedVehicle {
    return {
      trailers: null,
      trailerCount: 0,
      ...forkliftVehicleDto1,
      mapId: forkliftVehicleDto1.map?.id,
      lastStateMessageProcessedUtc: forkliftVehicleDto1.lastStateMessageProcessedUtc,
      status: VehicleStatus.Idle,
      workingAreaId: forkliftVehicleDto1.workAreaId,
      isActiveZoneSet: true,
      hardwareVersion: hardwareVersion,
    };
  }

  static getTableFleet(): TreeTableVehicle[] {
    const fleets = { ...this.getFleetDtos() };
    const reducedVehicles = { ...this.getAllReducedVehicles() };
    const treeTableVehicle: TreeTableVehicle[] = [
      {
        fleetName: fleets[0].name, // Fleet 1
        id: reducedVehicles[0].id,
        name: reducedVehicles[0].name,
        availability: reducedVehicles[0].availability,
        batteryLevel: reducedVehicles[0].batteryLevel,
        batteryLevelStatus: reducedVehicles[0].batteryLevelStatus,
        mapId: reducedVehicles[0].mapId,
        mapName: mapName1,
        maintenanceModeEnabled: reducedVehicles[0].maintenanceModeEnabled,
        mode: reducedVehicles[0].mode,
        status: reducedVehicles[0].status,
        fleetId: reducedVehicles[0].fleetId,
        isConnected: reducedVehicles[0].isConnected,
        isSwitchedOff: reducedVehicles[0].isSwitchedOff,
        vehicleType: reducedVehicles[0].vehicleType,
        isCharging: reducedVehicles[0].isCharging,
        isPaused: reducedVehicles[0].isPaused,
        isLoaded: reducedVehicles[0].isLoaded,
        brakeTestRequired: reducedVehicles[0].brakeTestRequired,
        trafficLightStatus: getTrafficLightStatus(reducedVehicles[0]),
        isActiveZoneSet: true,
        zoneSetId: reducedVehicles[0].zoneSetId,
        softwareDetails: {
          softwareVersion: reducedVehicles[0].softwareDetails?.softwareVersion,
          iotHubSdkVersion: reducedVehicles[0].softwareDetails?.iotHubSdkVersion,
          atsInterfaceVersion: reducedVehicles[0].softwareDetails?.atsInterfaceVersion,
        },
        softwareVersionChangedDateUtc: reducedVehicles[0].softwareVersionChangedDateUtc,
        softwareUpdateStatus: reducedVehicles[0].softwareUpdateStatus,
        softwareDownloadPercentage: reducedVehicles[0].softwareDownloadPercentage,
        supportedLoadTypes: reducedVehicles[0].supportedLoadTypes,
        hardwareVersion: reducedVehicles[0].hardwareVersion,
        metrics: undefined,
      },
      {
        id: reducedVehicles[1].id,
        fleetName: fleets[0].name, //Fleet 1
        name: reducedVehicles[1].name,
        availability: reducedVehicles[1].availability,
        batteryLevel: reducedVehicles[1].batteryLevel,
        batteryLevelStatus: reducedVehicles[1].batteryLevelStatus,
        mapId: reducedVehicles[1].mapId,
        mapName: mapName1,
        maintenanceModeEnabled: reducedVehicles[1].maintenanceModeEnabled,
        mode: reducedVehicles[1].mode,
        status: reducedVehicles[1].status,
        fleetId: reducedVehicles[1].fleetId,
        isConnected: reducedVehicles[1].isConnected,
        isSwitchedOff: reducedVehicles[1].isSwitchedOff,
        vehicleType: reducedVehicles[1].vehicleType,
        isCharging: reducedVehicles[1].isCharging,
        isPaused: reducedVehicles[1].isPaused,
        isLoaded: reducedVehicles[1].isLoaded,
        brakeTestRequired: reducedVehicles[0].brakeTestRequired,
        trafficLightStatus: getTrafficLightStatus(reducedVehicles[1]),
        isActiveZoneSet: false,
        zoneSetId: reducedVehicles[1].zoneSetId,
        softwareDetails: {
          softwareVersion: reducedVehicles[1].softwareDetails?.softwareVersion,
          iotHubSdkVersion: reducedVehicles[1].softwareDetails?.iotHubSdkVersion,
          atsInterfaceVersion: reducedVehicles[1].softwareDetails?.atsInterfaceVersion,
        },
        softwareVersionChangedDateUtc: reducedVehicles[1].softwareVersionChangedDateUtc,
        softwareUpdateStatus: reducedVehicles[1].softwareUpdateStatus,
        softwareDownloadPercentage: reducedVehicles[1].softwareDownloadPercentage,
        supportedLoadTypes: reducedVehicles[1].supportedLoadTypes,
        hardwareVersion: reducedVehicles[1].hardwareVersion,
        metrics: undefined,
      },
      {
        id: reducedVehicles[2].id,
        fleetName: fleets[1].name, // Fleet 2
        name: reducedVehicles[2].name,
        availability: reducedVehicles[2].availability,
        batteryLevel: reducedVehicles[2].batteryLevel,
        batteryLevelStatus: reducedVehicles[2].batteryLevelStatus,
        mapId: reducedVehicles[2].mapId,
        mapName: mapName2,
        maintenanceModeEnabled: reducedVehicles[2].maintenanceModeEnabled,
        mode: reducedVehicles[2].mode,
        status: reducedVehicles[2].status,
        fleetId: reducedVehicles[2].fleetId,
        isConnected: reducedVehicles[2].isConnected,
        isSwitchedOff: reducedVehicles[2].isSwitchedOff,
        vehicleType: reducedVehicles[2].vehicleType,
        isCharging: reducedVehicles[2].isCharging,
        isPaused: reducedVehicles[2].isPaused,
        isLoaded: reducedVehicles[2].isLoaded,
        brakeTestRequired: reducedVehicles[0].brakeTestRequired,
        trafficLightStatus: getTrafficLightStatus(reducedVehicles[2]),
        isActiveZoneSet: true,
        zoneSetId: reducedVehicles[2].zoneSetId,
        softwareDetails: {
          softwareVersion: reducedVehicles[2].softwareDetails?.softwareVersion,
          iotHubSdkVersion: reducedVehicles[2].softwareDetails?.iotHubSdkVersion,
          atsInterfaceVersion: reducedVehicles[2].softwareDetails?.atsInterfaceVersion,
        },
        softwareVersionChangedDateUtc: reducedVehicles[2].softwareVersionChangedDateUtc,
        softwareUpdateStatus: reducedVehicles[2].softwareUpdateStatus,
        softwareDownloadPercentage: reducedVehicles[2].softwareDownloadPercentage,
        supportedLoadTypes: reducedVehicles[2].supportedLoadTypes,
        hardwareVersion: reducedVehicles[2].hardwareVersion,
        metrics: undefined,
      },
      {
        id: reducedVehicles[3].id,
        fleetName: 'shared.treeTable.unitLoadNoFleetAssigned',
        name: reducedVehicles[3].name,
        availability: reducedVehicles[3].availability,
        batteryLevel: reducedVehicles[3].batteryLevel,
        batteryLevelStatus: reducedVehicles[3].batteryLevelStatus,
        mapId: reducedVehicles[3].mapId,
        mapName: mapName2,
        maintenanceModeEnabled: reducedVehicles[3].maintenanceModeEnabled,
        mode: reducedVehicles[3].mode,
        status: reducedVehicles[3].status,
        fleetId: reducedVehicles[3].fleetId,
        isConnected: reducedVehicles[3].isConnected,
        isSwitchedOff: reducedVehicles[3].isSwitchedOff,
        vehicleType: reducedVehicles[3].vehicleType,
        isCharging: reducedVehicles[3].isCharging,
        isPaused: reducedVehicles[3].isPaused,
        isLoaded: reducedVehicles[3].isLoaded,
        brakeTestRequired: reducedVehicles[0].brakeTestRequired,
        trafficLightStatus: getTrafficLightStatus(reducedVehicles[3]),
        isActiveZoneSet: true,
        zoneSetId: reducedVehicles[3].zoneSetId,
        softwareDetails: {
          softwareVersion: reducedVehicles[3].softwareDetails?.softwareVersion,
          iotHubSdkVersion: reducedVehicles[3].softwareDetails?.iotHubSdkVersion,
          atsInterfaceVersion: reducedVehicles[3].softwareDetails?.atsInterfaceVersion,
        },
        softwareVersionChangedDateUtc: reducedVehicles[3].softwareVersionChangedDateUtc,
        softwareUpdateStatus: reducedVehicles[3].softwareUpdateStatus,
        softwareDownloadPercentage: reducedVehicles[3].softwareDownloadPercentage,
        supportedLoadTypes: reducedVehicles[3].supportedLoadTypes,
        hardwareVersion: reducedVehicles[3].hardwareVersion,
        metrics: undefined,
      },
      {
        id: reducedVehicles[4].id,
        fleetName: 'shared.treeTable.tuggerTrainNoFleetAssigned',
        name: reducedVehicles[4].name,
        availability: reducedVehicles[4].availability,
        batteryLevel: reducedVehicles[4].batteryLevel,
        batteryLevelStatus: reducedVehicles[4].batteryLevelStatus,
        mapId: reducedVehicles[4].mapId,
        mapName: mapName2,
        maintenanceModeEnabled: reducedVehicles[4].maintenanceModeEnabled,
        mode: reducedVehicles[4].mode,
        status: reducedVehicles[4].status,
        fleetId: null,
        isConnected: reducedVehicles[4].isConnected,
        isSwitchedOff: reducedVehicles[4].isSwitchedOff,
        vehicleType: reducedVehicles[4].vehicleType,
        isCharging: reducedVehicles[4].isCharging,
        isPaused: reducedVehicles[4].isPaused,
        isLoaded: reducedVehicles[4].isLoaded,
        brakeTestRequired: reducedVehicles[0].brakeTestRequired,
        trafficLightStatus: getTrafficLightStatus(reducedVehicles[4]),
        isActiveZoneSet: true,
        zoneSetId: reducedVehicles[4].zoneSetId,
        softwareDetails: {
          softwareVersion: reducedVehicles[4].softwareDetails?.softwareVersion,
          iotHubSdkVersion: reducedVehicles[4].softwareDetails?.iotHubSdkVersion,
          atsInterfaceVersion: reducedVehicles[4].softwareDetails?.atsInterfaceVersion,
        },
        softwareVersionChangedDateUtc: reducedVehicles[4].softwareVersionChangedDateUtc,
        softwareUpdateStatus: reducedVehicles[4].softwareUpdateStatus,
        softwareDownloadPercentage: reducedVehicles[4].softwareDownloadPercentage,
        supportedLoadTypes: reducedVehicles[4].supportedLoadTypes,
        hardwareVersion: reducedVehicles[4].hardwareVersion,
        metrics: undefined,
      },
    ];

    return treeTableVehicle;
  }

  static getSendVehicleSendStepInputModel(mapId: GuidString): VehicleSendStepInputModel {
    return {
      mapId: mapId,
      steps: [],
    } as VehicleSendStepInputModel;
  }

  static getSendStepForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      steps: new UntypedFormArray([
        new UntypedFormGroup({
          stepType: new UntypedFormControl(),
          pointOfInterestGroup: new UntypedFormControl(),
          waypoints: new UntypedFormArray([]),
          timeSpan: new UntypedFormControl(),
        }),
      ]),
    });
  }

  static getStepMenuItems(): BasicMenuItem[] {
    return this.createStepMenuItems();
  }

  static createStepMenuItems(): BasicMenuItem[] {
    return this.createMenuItems(
      () => {},
      () => {}
    );
  }

  static createMenuItems(addMethod: Function, deleteMethod: Function): BasicMenuItem[] {
    return [
      this.createMenuItem('shared.vehicles.sendStep.addWaypoint', addMethod.bind(this), true),
      this.createMenuItem('shared.treeTable.actionMenu.delete', deleteMethod.bind(this), true),
    ];
  }

  static createMenuItem(key: string, command: Function, visible: boolean): BasicMenuItem {
    return {
      label: key,
      key,
      command,
      visible,
      disabled: false,
    };
  }

  static mapVehicleMapNavigation(mapId: GuidString): MapNavigationDto {
    return {
      id: mapId,
      navigationLayerId: '',
    };
  }
}
